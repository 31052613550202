
import Styles from "./Menu.module.css";
import React from "react";
import UserMenu from "./UserMenu";
// import LangDropDown from "../LangDropDown";
import Logo from "../../../assets/javolin_logo.png" 
import LangDropDown from "../LangDropDown";
import {CaretDown, MoneyExchange, NotificationIcon, UserTag2} from "../JavIcons";
import {Avatar, Dropdown} from "antd";
import styles from "./Menu.module.css";
import {useDispatch, useSelector} from "react-redux";
import {busLogin, switchAccount} from "../../../Modules/Auth/Login/duck/action";
import {useHistory} from "react-router-dom";



const DefaultMenu = () => {

    const userDetails = useSelector((state) => state?.user);
    const dispatch = useDispatch();
    const history = useHistory();

    const items = userDetails?.user_companies?.map(item => {
        return {
            label: `${item["business"]["full_name"]} - ${item["business"]["business_name"]}`,
            key: '1',
            onClick: ()=> {
                dispatch(switchAccount({
                    "user_company_id": item.id
                }, history));
            }
        }
    });

    return (
        <>
            <div >
                <div className={Styles.head}>

                    <div className={Styles.container2}>



                        <div className={Styles.sideHeader2}>

                            {
                                items?.length >  0 &&
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                        trigger={['click']}
                                    >
                                        <div className={styles.userName}>
                                            Switch Account
                                            <div style={{marginLeft: "0.5em"}}>
                                                <CaretDown width={"1em"} color="#727986" />
                                            </div>
                                        </div>
                                    </Dropdown>

                                </div>
                            }


                            <div className={Styles.mobileLogoHeafer}>
                                <img src={Logo} alt="logo" />
                            </div>

                            <div className={Styles.langMenu}>
                                <LangDropDown />
                            </div>



                            <div>
                                <div className={Styles.circle}>
                                    <NotificationIcon width="1.2em"  height="1.2em" className="javIcon" />
                                </div>
                            </div>

                            <div className={Styles.userMenu}>
                                <UserMenu />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DefaultMenu;
