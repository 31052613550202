export const ACCEPT_MORTGAGE_REQUEST = "ACCEPT_MORTGAGE_REQUEST";
export const ACCEPT_MORTGAGE_SUCCESS = "ACCEPT_MORTGAGE_SUCCESS";
export const ACCEPT_MORTGAGE_ERROR = "ACCEPT_MORTGAGE_ERROR";
export const DECLINE_MORTGAGE_REQUEST = "DECLINE_MORTGAGE_REQUEST";
export const DECLINE_MORTGAGE_SUCCESS = "DECLINE_MORTGAGE_SUCCESS";
export const DECLINE_MORTGAGE_ERROR = "DECLINE_MORTGAGE_ERROR";
export const GET_APPLICATION_PROGRESS_REQUEST = "GET_APPLICATION_PROGRESS_REQUEST";
export const GET_APPLICATION_PROGRESS_SUCCESS = "GET_APPLICATION_PROGRESS_SUCCESS";
export const GET_APPLICATION_PROGRESS_ERROR = "GET_APPLICATION_PROGRESS_ERROR";
export const APPLY_FOR_APPROVAL_REQUEST = "APPLY_FOR_APPROVAL_REQUEST";
export const APPLY_FOR_APPROVAL_ERROR = "APPLY_FOR_APPROVAL_ERROR";
export const APPLY_FOR_APPROVAL_SUCCESS = "APPLY_FOR_APPROVAL_SUCCESS";
