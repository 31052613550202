import { useState } from "react";

const NewMenuDropdownItem = ({icon: Icon,title,isActive, items}) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };


  return (
    <div>
      <div
        onClick={handleCollapse}
        className={`tw-flex tw-cursor-pointer tw-items-center tw-px-6 ${
          isActive && "tw-bg-jav-primary-900"
        } `}
      >
        <Icon className={"tw-fill-white"} />

        <span className={"tw-font-satoshiMedium tw-mx-2 tw-py-5  tw-text-md"}>
          {title}
        </span>
      </div>
    

      {collapsed ? (
        <div className={"tw-ml-6"}>
          {items.map((item) => {
            return <div key={item.id}>{item.menu}</div>;
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NewMenuDropdownItem;
