import React, { useEffect } from "react";
import { currencyFormat } from "../../helpers/utils";
import Styles from "../../Shared/Components/ReviewPopUp/ReviewPopUp.module.css";
import JavContentTitle from "../../Shared/Components/JavContentTitle";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchTransactionDetails } from "./duck/action";
import { LeftArrowLinear } from "../../Shared/Components/JavIcons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function TransactionHistoryDetails(props) {
  const text = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const history = useHistory();

  const userMgt = useSelector((state) => state.userMgt);

  useEffect(() => {
    const loanReference = window.location.href.split("/")?.[5];
    dispatch(fetchTransactionDetails(loanReference));
  }, []);

  var details = userMgt.fetch_tranx_details_success;

  return (
    <div>
      <div>
        <div
          className={
            "tw-flex tw-items-center tw-font-satoshiBold tw-mb-3  tw-ml-5 tw-cursor-pointer"
          }
          onClick={() => {
            history.goBack();
          }}
        >
          <LeftArrowLinear
            className={"tw-h-5 tw-stroke-current tw-stroke-1 "}
          />
          <span className={"tw-mx-2"}> Back </span>
        </div>
        <div className={Styles.contentArea}>
          <JavContentTitle title="Transaction Made by" />
          <div className={Styles.rowGrid2}>
            <div>
              <div className={"tw-font-satoshiMedium tw-text-xl tw-pt-5"}>
                {text["User name"]}
              </div>
              <div className={Styles.ans}>
                {details?.data?.customer?.first_name}{" "}
                {details?.data?.customer?.last_name}
              </div>
            </div>
            <div>
              <div className={"tw-font-satoshiMedium tw-text-xl tw-pt-5"}>
                {text["Role"]}
              </div>
              <div className={Styles.ans}>
                {
                  details?.business?.account_role ?? "Super Admin"
                  // : details?.business?.role?.role?.name
                }
              </div>
            </div>
          </div>
          <JavContentTitle title={text["Beneficiary account"]} />

          <div className={Styles.rowGrid}>
            <div>
              <div className={"tw-font-satoshiMedium tw-text-xl tw-pt-5"}>
                {text["Account Name"]}
              </div>
              <div className={Styles.ans}>
                {details?.data?.corepayOrder?.beneficiary?.name ??
                  details?.data?.loan?.beneficiary?.name}
              </div>
            </div>
            <div>
              <div className={"tw-font-satoshiMedium tw-text-xl tw-pt-5"}>
                {text["Account Number"]}
              </div>
              <div className={Styles.ans}>
                {details?.data?.corepayOrder?.beneficiary?.account_number ??
                  details?.data?.loan?.beneficiary?.account_number}
              </div>
            </div>
            <div>
              <div className={"tw-font-satoshiMedium tw-text-xl tw-pt-5"}>
                {text["Bank name"]}
              </div>
              <div className={Styles.ans}>
                {details?.data?.corepayOrder?.beneficiary?.bank_name ??
                  details?.data?.loan?.beneficiary?.bank_name}
              </div>
            </div>
          </div>
          {details?.data?.module === "FOREX" && (
            <>
              <JavContentTitle title={text["Source account"]} />
              <div>
                <div className={Styles.detailsTitle}>
                  {text["Source account"]}
                </div>

                <div className={Styles.receiverContent}>
                  <div className={Styles.contentRow}>
                    <div className={Styles.recTitle}>
                      {details?.data?.account_number}
                    </div>
                    <div
                      style={{ marginTop: "0.2em" }}
                      className={Styles.recSubTitle}
                    >
                      {/* {details?.wallet[0] ? (
                            <>
                              {`${
                                currencies[details?.transaction?.currency_id]
                                  ?.ISO
                              } ${details?.wallet[0]?.current_balance}`}
                            </>
                          ) : (
                            <div
                              onClick={()=>{}}
                              className={Styles.wireSub}
                            >
                              view wiring instruction
                            </div>
                          )} */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {
            <>
              <JavContentTitle
                title={`${
                  details?.data?.module !== "FOREX"
                    ? "Bags & Trucks Info"
                    : "Info"
                }`}
              />
              {details?.data?.module === "FOREX" ? (
                <>
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "space-between",
                      gridTemplateColumns: "repeat(4, 1fr)",
                    }}
                  >
                    <div>
                      <div className={Styles.detailsTitle}>
                        {text["Initial Trans. Amount"]}
                      </div>
                      <div className={Styles.ans}>
                        {`${
                          details?.data?.currency.ISO || "GHS"
                        } ${currencyFormat(
                          Number(details?.data?.amount).toFixed(2)
                        )}`}
                      </div>
                    </div>
                    <div>
                      <div className={Styles.detailsTitle}>
                        {text["Trans. Fee"]}
                        Trans. Fee
                      </div>
                      <div className={Styles.ans}>
                        {`${
                         details.data.currency.ISO
                             || "GHS"
                        } ${currencyFormat(
                          Number(details?.data?.fee).toFixed(2)
                        )}`}
                      </div>
                    </div>
                    <div>
                      <div className={Styles.detailsTitle}>
                        {text["Selling Amount"]}
                      </div>
                      <div className={Styles.ans}>
                        {`${
                          JSON.parse(details.data.corepayOrder.sender)
                            .currency || "GHS"
                        } ${currencyFormat(
                          Number(
                            JSON.parse(details.data.corepayOrder.sender).amount
                          ).toFixed(2)
                        )}`}
                        {/* {`${currencies[details?.transaction?.currency_id]?.ISO} ${currencyFormat(Number(details?.fee?.amount_payable).toFixed(2))}`} */}
                      </div>
                    </div>
                    <div>
                      <div className={Styles.detailsTitle}>Amount Purchase</div>
                      <div className={Styles.ansRed}>
                        {`${
                          JSON.parse(details.data.corepayOrder.recipient)
                            .currency || "GHS"
                        } ${currencyFormat(
                          Number(
                            JSON.parse(details.data.corepayOrder.recipient)
                              .amount
                          ).toFixed(2)
                        )}`}
                      </div>
                    </div>
                    {/* <div>
                            <div className={Styles.detailsTitle}>
                                Old Rate
                            </div>
                            <div className={Styles.ans}>
                                {`${currencyFormat(details?.old_rate?.[0], 4)}/${currencyFormat(details?.old_rate?.[1],4)}`}
                            </div>
                        </div>
                        <div>
                            <div className={Styles.detailsTitle}>
                                New Rate
                            </div>
                            <div className={Styles.ans}>
                            {`${currencyFormat(details?.new_rate?.[0],4)}/${currencyFormat(details?.new_rate?.[1],4)}`}
                            </div>
                        </div> */}
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "grid",
                    justifyContent: "space-between",
                    gridTemplateColumns: "repeat(4, 1fr)",
                  }}
                >
                  <div>
                    <div className={Styles.detailsTitle}>
                      {"Weight per bag"}
                    </div>
                    <div className={Styles.ans}>
                      {`${details?.data?.pending_transaction[0]?.params?.weight_per_bag}`}
                    </div>
                  </div>
                  <div>
                    <div className={Styles.detailsTitle}>
                      {"Profit Per Bag"}
                    </div>
                    <div className={Styles.ans}>
                      {`${
                        details?.data?.pending_transaction[0]?.params
                          ?.currency || "GHS"
                      } ${currencyFormat(
                        Number(
                          details?.data?.pending_transaction[0]?.params
                            ?.profit_per_bag
                        ).toFixed(2)
                      )}`}
                    </div>
                  </div>
                  <div>
                    <div className={Styles.detailsTitle}>
                      {`${
                        details?.data?.pending_transaction[0]?.params
                          ?.total_weight
                          ? "Total Weight(kg)"
                          : "Exp No. of bags per truck"
                      }`}
                    </div>
                    <div className={Styles.ans}>
                      {details?.data?.pending_transaction[0]?.params
                        ?.total_weight ??
                        details?.data?.pending_transaction[0]?.params
                          .expected_number_of_bags_per_truck}

                      {/* {`${currencies[details?.transaction?.currency_id]?.ISO} ${currencyFormat(Number(details?.fee?.amount_payable).toFixed(2))}`} */}
                    </div>
                  </div>
                  <div>
                    <div className={Styles.detailsTitle}>{"Cost per bag"}</div>
                    <div className={Styles.ans}>
                      {`${
                        details?.data?.pending_transaction[0]?.params
                          ?.currency || "GHS"
                      } ${currencyFormat(
                        Number(
                          details?.data?.pending_transaction[0]?.params
                            ?.cost_price_per_bag
                        ).toFixed(2)
                      )}`}
                    </div>
                  </div>
                  {/* <div>
                            <div className={Styles.detailsTitle}>
                                Old Rate
                            </div>
                            <div className={Styles.ans}>
                                {`${currencyFormat(details?.old_rate?.[0], 4)}/${currencyFormat(details?.old_rate?.[1],4)}`}
                            </div>
                        </div>
                        <div>
                            <div className={Styles.detailsTitle}>
                                New Rate
                            </div>
                            <div className={Styles.ans}>
                            {`${currencyFormat(details?.new_rate?.[0],4)}/${currencyFormat(details?.new_rate?.[1],4)}`}
                            </div>
                        </div> */}
                </div>
              )}
            </>
          }

          {details?.data?.module !== "FOREX" && (
            <div
              style={{
                display: "grid",
                justifyContent: "space-between",
                gridTemplateColumns: "repeat(4, 1fr)",
              }}
            >
              <div>
                <div className={Styles.detailsTitle}>
                  {"Total expected profit"}
                </div>
                <div className={Styles.ans}>
                  {`${
                    details?.data?.pending_transaction[0]?.params?.currency
                  } ${currencyFormat(
                    details?.data?.pending_transaction[0]?.params
                      ?.expected_total_profit ?? 0.0
                  )}`}
                </div>
              </div>
              <div>
                <div className={Styles.detailsTitle}>{"Interest Fee"}</div>
                <div className={Styles.ans}>
                  {`${
                    details?.data?.pending_transaction[0]?.params?.currency
                  } ${currencyFormat(
                    details?.data?.pending_transaction[0]?.params?.interest ??
                      0.0
                  )}`}
                </div>
              </div>
              <div>
                <div className={Styles.detailsTitle}>{`${
                  details?.data?.pending_transaction[0]?.params
                    ?.expected_total_javolin_profit
                    ? "Expected Javolin Profit"
                    : "Amount"
                }`}</div>
                <div className={Styles.ans}>
                  {`${
                    details?.data?.pending_transaction[0]?.params?.currency
                  } ${currencyFormat(
                    details?.data?.pending_transaction[0]?.params
                      ?.expected_total_javolin_profit ??
                      details?.data?.pending_transaction[0]?.params?.amount
                  )}`}
                </div>
              </div>
              <div>
                <div className={Styles.detailsTitle}>Current Status</div>
                <div className={Styles.ansRed}>
                  {`${details?.data?.pending_transaction[0]?.params?.status} `}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
