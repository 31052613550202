import React, { useEffect, useState } from "react";
import JavButton from "../../../Shared/Components/Forms/JavButton";
import { makeJavolinRequest } from "../../../helpers/utils";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

const MarketPlaceProductDetail = () => {
    const [dashboard, setDashboard] = useState({
        loading: false,
        product: null,
      });
  const product = {
    image: "/cassava.png",
    title: "Cassava 11,000 tons",
    status: "On Ship",
    totalBags: 12,
    totalWeight: "310 kg",
    amount: "GH₵113,000.00",
    location: "Javolin main warehouse, Adenta",
    notes: "N/A",
    mapImage: "/map_.png",
    vesselImage: "/ship.png",
    blNumber: "12 bags",
    containers: "310 kg",
    vesselName: "MSC Daniela",
    voyagerNo: "FY409R",
    shippedFrom: "Tema, GH",
    shippedTo: "Tuticorin, IN",
    portLoading: "Tema, GH",
    portDischarge: "Tuticorin, IN",
    transhipments: "Colombo, LK",
    blDocumentLink: "#",
  };

  const SummaryHeading = ["Total bags", "Total weight", "Status"];

//   const history = useHistory();

const loanReference = window.location.href.split("/")?.[5];




  const loadDashboard = () => {
    setDashboard({ ...dashboard, loading: true });
    makeJavolinRequest(
      {
        path: `/business/agric/inventories/${loanReference}`,
        method: "GET",
      },
      null,
      null,
      (data) => {
        setDashboard({ ...dashboard, loading: false, product: data });
        console.log("data", data);
      },
      (error) => {
        setDashboard({ ...dashboard, loading: false });
        console.log("error", error);
      }
    );
  };
  useEffect(() => {
    loadDashboard();
    return () => {

    };
  }, []);


  console.log('Product ID:', dashboard?.product?.id);

  const tile = (color, title, description) => {
    return (
      <div>
        <span className="tw-text-jav-msc-color-100 tw-font-satoshi">
          {title}
        </span>

        <h4 className={`${color} tw-text-xl tw-font-satoshiBold`}>
          {description}
        </h4>
      </div>
    );
  };
//   let imaage = JSON.parse(dashboard?.product?.commodity?.code_option);
  return (
    <div className="xl:tw-mx-20 lg:tw-mx-10 md:tw-mx-5 sm:tw-mx-5 tw-px-6 tw-py-5 tw-overflow-hidden  tw-overflow-y-auto tw-bg-white tw-rounded-lg tw-shadow-md">
      <div className="tw-flex tw-justify-between">
        <h2 className="tw-text-2xl tw-font-satoshiBold tw-text-black">
          {product.title}
        </h2>
        <div className="tw-bg-jav-active-800 tw-text-jav-msc-color-300 tw-px-3 tw-rounded-full tw-h-8 tw-flex tw-items-center tw-font-satoshiBold tw-text-xs">
          On Ship
        </div>
      </div>
      <div className="tw-flex tw-gap-6">
        <div className="tw-w-full md:tw-w-1/2">
          <img
            src={product.image}
            alt={product.title}
            className="tw-w-full tw-h-64 tw-object-cover tw-rounded-xl tw-mb-4"
          />
          <div className="tw-space-y-2">
            <div className="tw-grid tw-grid-cols-4 tw-gap-4">
              {SummaryHeading.map((val, index) => {
                return (
                  <div key={index}>
                    {tile(
                      "tw-text-black",
                      val,
                      index === 0
                        ? dashboard?.product?.package
                        : index === 1
                        ? dashboard?.product?.weight
                        : "In Stock"
                    )}
                  </div>
                );
              })}
            </div>

            {tile("tw-text-jav-card-500", "Amount", dashboard?.product?.unit_price_ncy)}

            <div className="tw-mt-4">
              {tile("tw-text-black", "Location", dashboard?.product?.warehouse?.name)}
            </div>

            <div className="tw-mt-4">
              {tile("tw-text-black", "Notes / description", product["notes"])}
            </div>

            <JavButton
              bgColor={""}
              textColor="tw-text-white"
              className={
                "tw-text-white tw-text-sm tw-h-9 tw-cursor-pointer tw-mt-5 tw-py-5 tw-px-7 sm:tw-text-xl tw-rounded-full "
              }
              isLoading={false}
              onClick={() => {
              }}
            >
              <span className="">Purchase</span>
            </JavButton>
          </div>
        </div>
        <div className="tw-w-1 tw-bg-gray-100 tw-rounded-full">

        </div>
        <div className="tw-w-full md:tw-w-1/2 tw-space-y-4">
          <div className="tw-flex tw-gap-4">
            <img
              src={product.mapImage}
              alt={product.title}
              className="tw-w-full tw-h-64 tw-object-cover tw-rounded-xl tw-mb-4"
            />
            <img
              src={product.vesselImage}
              alt={product.title}
              className="tw-w-3/5 tw-h-64 tw-object-cover tw-rounded-xl tw-mb-4"
            />
          </div>
          <div className="tw-space-y-2">
            <div className="tw-grid tw-grid-cols-3 tw-gap-4">
              {tile("tw-text-black", "BL Number", product["blNumber"])}

              {tile(
                "tw-text-black",
                "Number of Containers",
                product["containers"]
              )}

              {tile("tw-text-black", "Vessel name", product["vesselName"])}
              {tile("tw-text-black", "Voyager No", product["voyagerNo"])}
              {tile("tw-text-black", "Shipped From", product["shippedFrom"])}
              {tile("tw-text-black", "Shipped to", product["shippedTo"])}
              {tile("tw-text-black", "Port of loading", product["portLoading"])}
              {tile("tw-text-black", "Port of Discharge", product["portDischarge"])}
              {tile("tw-text-black", "Transhipments", product["transhipments"])}
            </div>
            <p>
              <a
                href={product.blDocumentLink}
                className="tw-text-blue-600 tw-font-bold tw-cursor-pointer"
              >
             {tile("tw-text-blue-600", "BL Document", "Download now")}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketPlaceProductDetail;
