import {Route} from "react-router-dom";
import React from "react";




export default function ApplicationAuthLayout({ component: Component, title, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => (
                <Component {...props} />
            )}
        />
    );
}