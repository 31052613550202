import JavDropdown from "../JavDropdown";
import {NotificationIcon, Settings} from "../JavIcons"; // Add MenuIcon
import React from "react"; // Add useState
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {
    userLogout,
} from "../../../Modules/Auth/Login/duck/action";
import SearchIcon from "../Icons/SearchIcon";

export default function NewApplicationToolbar(props) {
    const userDetails = useSelector((state) => state?.user);
    const history = useHistory();
    const dispatch = useDispatch();

    const applicationRunningHeader = () => {
        return (
            <div
                className={
                    "tw-h-28 text-white tw-flex tw-items-center " +
                    "tw-w-full overflow-hidden tw-bg-jav-primary-300 tw-bg-bg-pattern xl:tw-px-20 lg:tw-px-10 md:tw-px-5 sm:tw-px-5 tw-px-4"
                }
            >
                <div className="tw-flex tw-items-center tw-justify-center">
                    <div
                        className="tw-flex tw-h-16 tw-bg-jav-active-400 tw-w-16 tw-rounded-full tw-items-center tw-justify-center tw-text-3xl tw-font-satoshiBold">
                        {userDetails?.full_name[0]}
                    </div>
                    <div className="tw-flex-col tw-pl-5">
                        <div className="md:tw-text-3xl tw-text-xl sm:tw-text-lg tw-font-satoshiBold">
                            Welcome,{" "}
                            {userDetails?.type === "business"
                                ? userDetails?.full_name
                                : userDetails?.first_name}{" "}

                        </div>
                        {/* <div className="sm:tw-text-xl">Start building your inventory list today.</div> */}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            className="tw-flex-1 tw-overflow-hidden  tw-overflow-y-auto tw-z-10 tw-sticky tw-top-0 tw-left-0 tw-right-0">
            <div
                className="tw-h-24 xl:tw-px-20 lg:tw-px-10 md:tw-px-5 sm:tw-px-5 tw-px-4 tw-flex tw-items-center tw-justify-between lg:tw-justify-between tw-shadow-sm  tw-border-b tw-bg-jav-active-300">
                <div>
                    <img src="/inv_logo.png" className="tw-h-24" alt="JavCommotidyLogo"/>
                </div>

                <div className="tw-flex ">
                    <div className="tw-flex tw-h-8 tw-w-8 tw-rounded-full tw-border tw-items-center tw-justify-center">
                        <NotificationIcon height={20} width={20}/>
                    </div>

                    <JavDropdown
                        topSpace={9}
                        profile={true}
                        actions={[
                            {
                                title: "Profile",
                                onClick: () => {
                                    history.push("/business/profile");
                                    // props.setIsSidebarVisible(false);
                                },
                            },
                            {
                                title: "Logout Session",
                                onClick: () => {
                                    dispatch(userLogout(history, "business"));
                                    // props.setIsSidebarVisible(false);
                                },
                            },
                        ]}
                    >
                        <div
                            className="tw-flex tw-h-8 tw-w-8 tw-rounded-full tw-border tw-items-center tw-justify-center tw-mr-3">
                            <Settings height={20} width={20}/>
                        </div>
                    </JavDropdown>

                </div>
            </div>
            {applicationRunningHeader()}

        </div>
    );
}
