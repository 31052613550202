import React, { useEffect, useState } from "react";
import SearchIcon from "../../../Shared/Components/Icons/SearchIcon";
import JavButton from "../../../Shared/Components/Forms/JavButton";
import { currencyFormat, makeJavolinRequest } from "../../../helpers/utils";
import AgricWarehouse from "../Warehouse/AgricWarehouse";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import AddWarehouseReview from "../Warehouse/AddWarehouse";
import { ArrowLeftIcon, BackwardIcon } from "@heroicons/react/24/outline";

const MarketPlaceDashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const [dashboard, setDashboard] = useState({
    loading: false,
    data: null,
  });
  const loanReference = location.pathname.split("/")?.[3];

  const loadDashboard = () => {
    setDashboard({ ...dashboard, loading: true });
    makeJavolinRequest(
      {
        path: `/business/agric/dashboard?commodity_id=${loanReference}`,
        method: "GET",
      },
      null,
      null,
      (data) => {
        setDashboard({ ...dashboard, loading: false, data: data });
        console.log("data", data);
      },
      (error) => {
        setDashboard({ ...dashboard, loading: false });
        console.log("error", error);
      }
    );
  };
  useEffect(() => {
    loadDashboard();
    return () => {};
  }, []);

  const onInventoryItemClicked = (inventory) => {
    history.push(`/agric/market/${inventory?.id}`);
  };

  const productName = location.state;

  return (
    <>
      <div className="xl:tw-px-20 lg:tw-px-10 md:tw-px-5 sm:tw-px-5 tw-px-4 tw-py-3 tw-overflow-hidden  tw-overflow-y-auto">
        <div className="tw-flex tw-justify-between tw-items-center">
          <div
            className={`${"tw-border-b-4"} tw-border-jav-primary-200 tw-text-2xl tw-rounded-b-sm ${"tw-font-satoshiBold"} ${"tw-text-jav-primary-200"} tw-cursor-pointer`}
          >
            Marketplace
          </div>
        </div>

        <div className="tw-border tw-border-jav-active-200 tw-rounded-xl tw-w-full tw-h-full tw-mt-6 tw-shadow-lg">
          <div className="tw-flex tw-justify-between tw-p-6">
            <div className="tw-flex tw-space-x-3">
              <ArrowLeftIcon
                height={20}
                className="tw-cursor-pointer"
                onClick={() => {
                  history.goBack();
                }}
              />
              <h4>Available {productName} Products</h4>
            </div>
            <div className="tw-flex tw-space-x-4 tw-items-center tw-place-content-center tw-cursor-pointer">
              <div className="tw-text-jav-active-600 tw-font-satoshiLight">
                Filter by:
              </div>
              <div className="tw-flex tw-text-lg tw-font-satoshiBold tw-text-black tw-bg-jav-active-700 tw-py-1 tw-px-4 tw-rounded-full tw-space-x-1">
                <span>All products</span>
                <img src="/CaretDown.svg" height={20} width={20} />
              </div>
            </div>
          </div>

          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-2">
            {dashboard?.data?.inventory?.map((products, index) => {
              let imaage = JSON.parse(products?.commodity?.code_option);
              let product = products?.commodity;
              return (
                <div
                  onClick={() => {
                    onInventoryItemClicked(products);
                  }}
                  key={index}
                  className="tw-bg-white tw-rounded-2xl tw-shadow-lg tw-overflow-hidden tw-border tw-border-jav-active-200 tw-m-6 tw-p-4"
                >
                  <img
                    src={imaage.image_url}
                    alt={product.code_name}
                    className="tw-w-full tw-h-48 tw-object-cover tw-rounded-xl"
                  />
                  <div className="tw-p-0">
                    <h3 className="tw-text-lg tw-font-satoshiBold tw-text-black tw-pt-2">
                      {product?.code_name} {products?.package}{" "}
                      {products?.package_unit}
                    </h3>
                    <p className="tw-text-jav-msc-color-100 tw-font-satoshiLight">
                      {products?.warehouse?.name}
                    </p>
                    <p className="tw-text-jav-card-500 tw-text-xl tw-font-satoshiBold tw-pb-0">
                      {products.unit_price_currency} {products.unit_price_ncy}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketPlaceDashboard;
