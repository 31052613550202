export const ADD_DEPOSIT_REQUEST = "ADD_DEPOSIT_REQUEST";
export const ADD_DEPOSIT_SUCCESS = "ADD_DEPOSIT_SUCCESS";
export const ADD_DEPOSIT_ERROR = "ADD_DEPOSIT_ERROR";
export const GET_SAVINGS_SUCCESS = "GET_SAVINGS_SUCCESS"
export const GET_SAVINGS_ERROR = "GET_SAVINGS_ERROR";
export const GET_SAVINGS_REQUEST = "GET_SAVINGS_REQUEST";
export const MAKE_DEPOSIT_REQUEST = "MAKE_DEPOSIT_REQUEST";
export const MAKE_DEPOSIT_SUCCESS = "MAKE_DEPOSIT_SUCCESS";
export const MAKE_DEPOSIT_ERROR = "MAKE_DEPOSIT_ERROR";
export const GET_FILTERED_TRANX_SUCCESS = "GET_FILTERED_TRANX_SUCCESS"
export const ADD_TRANSACTION = "ADD_TRANSACTION"