import JavFormFileInput from "../../../Shared/Components/Forms/JavFormFileInput";
import {PlusCircleIcon} from "@heroicons/react/24/outline";
import JavFormSelect from "../../../Shared/Components/Forms/JavFormSelect";
import JavInput from "../../../Shared/Components/Forms/JavInput";
import JavDatePicker from "../../../Shared/Components/Forms/JavDatePicker";
import JavButton from "../../../Shared/Components/Forms/JavButton";
import {useEffect, useRef, useState} from "react";
import {downloadFile, makeJavolinRequest} from "../../../helpers/utils";
import {fetchLoanContractDetails} from "../../TradingHub/duck/action";
import moment from "moment";
import Table from "../../../Shared/Components/Table";
import DownloadIcon from "../../../Shared/Components/Icons/DownladIcon";

export default function AddInventoryItem(props) {

    const fileInputRef = useRef(null);
    const tableRef = useRef(null);
    const [selectFile, setSelectFile] = useState({});
    const [inventory,setInventory] = useState({});
    const inventoryId = window.location.href.split("/")?.[5];
    const [createNewItem,setCreateNewItem] = useState({
        data: null,
        loading: false
    });

    const [form,setForm] = useState({
        commodity_id: null,
        warehouse_id: null,
        weight: null,
        unit_price_ncy: null,
        expected_stock_in_at: null,
        title: null
    });


    const resetForm = () => {
        setForm(Object.fromEntries(Object.keys(form).map(key => [key, ""])));
    };

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onUploadImageClicked = () => {
        fileInputRef?.current?.click();
    }

    function getBase64(file,onSuccess,onError) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>  onSuccess(reader.result);
        reader.onerror = (error) => onError(error);
    }

    const onImageSelected = (event) => {
        console.log("onImageSelected", event.target.files[0]);

        getBase64(event.target.files[0],(fileContent) => {
            setSelectFile({
                ...selectFile,
                file: fileContent,
                meta: event.target.files[0],
                image: URL.createObjectURL(event.target.files[0])
            })
        },(error) => {
            alert("Error: "+error)
        })
    }


    const onCreateNewItemClicked = () => {
        setCreateNewItem({...createNewItem,loading: true})
        makeJavolinRequest({
            path: `/business/agric/inventories`,
            method: "POST",
            data: {
                ...form,
                file: selectFile?.file,
                warehouse_id: 1,
                weight: parseFloat(form?.weight)?.toFixed(2),
                weight_unit: "KG"
            }
        },null,null,(data) => {
            setCreateNewItem({...createNewItem,loading: false,data: data})
            resetForm()
            alert("Item Uploaded Successfully")
        },(error) => {
            setCreateNewItem({...createNewItem,loading: false})
            console.log("error",error)
        })

        console.log("onCreateNewItemClicked",form)
    }


    const fetchInventoryDetails = () => {
        setInventory({...inventory,loading: true})
        makeJavolinRequest({
            path: `/business/agric/inventories/${inventoryId}`,
            method: "GET",
        },null,null,(data) => {
            setInventory({...inventory,loading: false,data: data})
            console.log("date",moment(data?.expected_stock_in_at)?.format("YYYY-MM-DD"))
            console.log("date",data?.expected_stock_in_at)
            setForm({
                ...form,
                title: data?.title,
                weight: parseFloat(data?.weight)?.toFixed(2),
                unit_price_ncy: parseFloat(data?.unit_price_ncy)?.toFixed(2),
                expected_stock_in_at: moment(data?.expected_stock_in_at)?.format("YYYY-MM-DD"),
            })
            console.log("file",{file: data?.concerns?.[0]?.meta?.url})
            setSelectFile({image: data?.concerns?.[0]?.meta?.url})
        },(error) => {
            setInventory({...inventory,loading: false})
            console.log("error",error)
        })
    }


    useEffect(() => {

        fetchInventoryDetails();

        return () => {

        }

    },[])

    return (

        <div>

            <div className={"tw-mx-20 tw-flex tw-flex-col tw-px-2 tw-border tw-rounded tw-py-3 tw-bg-white"}>


                <span className={"tw-font-firmaBold"}>Item Details</span>
                <span
                    className={"tw-text-xs tw-text-gray-600"}>Provide detailed information for creating a new item</span>


                <div className={"tw-grid tw-grid-cols-2 tw-gap-5"}>

                    <div className={"tw-col-span-2"}>
                        <div className={"tw-flex tw-flex-col tw-mt-2"}>

                            <span className={"tw-font-firmaBold tw-my-1"}>Photos</span>

                            <div className={"tw-grid tw-my-1 tw-gap-5 tw-grid-cols-3"}>


                                <div onClick={onUploadImageClicked}
                                     className={"tw-relative tw-overflow-hidden tw-border tw-rounded tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-h-20 tw-border-blue-600"}>


                                    {
                                        selectFile?.image &&
                                        <img
                                            alt={"selectImage"}
                                            src={selectFile?.image}
                                            className={"tw-absolute tw-object-cover  tw-top-0 tw-bottom-0 tw-left-0 tw-right-0"}
                                        />
                                    }


                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept="image/*"
                                        capture={"environment"}
                                        onChange={onImageSelected}
                                        className={"tw-hidden"}
                                    />
                                    <div className={"tw-flex tw-items-center tw-flex-col tw-text-blue-600"}>
                                        <PlusCircleIcon className={"tw-h-5 tw-w-5"}/>
                                        <span className={"tw-font-proxima tw-text-sm"}>Add Photos</span>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                    <div className={"tw-col-span-2 tw-w-full tw-gap-4 tw-grid tw-grid-cols-2"}>

                        <img
                            src={"https://myserenitykids.com/cdn/shop/articles/loren-biser-vP5guwApg0g-unsplash_1.jpg?v=1687897032&width=2048"}
                            alt={"casava"} className={"tw-h-96 tw-cursor-pointer tw-rounded tw-w-full tw-object-cover"}></img>
                        <img
                            src={"https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcR2Uqpp2M4_dEe49kseDwMkLjmQwbT78Dl3QdmK1D8ibmnQWF6J0ZvpKcVzCqn4obKBkWbCqtCcEN0Xda4"}
                            alt={"cashew"} className={"tw-h-96 tw-cursor-pointer tw-rounded tw-w-full tw-object-cover"}></img>

                    </div>


                    {/*<div className={"tw-col-span-2"}>*/}
                    {/*    <JavInput*/}
                    {/*        title={"Product Description"}*/}
                    {/*        type={"text"}*/}
                    {/*        name={"title"}*/}
                    {/*        value={form?.title}*/}
                    {/*        onChange={handleChanges}*/}
                    {/*        placeholder={"Product description"}*/}
                    {/*    />*/}
                    {/*</div>*/}


                    <div className={"tw-col-span-2"}>
                        <JavFormSelect
                            title={`Select Product`}
                            placeholder={"Select Product "}
                            common_code={"CT001"}
                            code={"CT001"}
                            codeField={"code_name"}
                            position={"bottom"}
                            onChange={(item) => {
                                setForm({...form, commodity_id: item?.id});
                            }}
                        />
                    </div>

                    <div className={"tw-col-span-2"}>
                        <JavFormSelect
                            title={`Select Warehouse`}
                            placeholder={"Select Commodity "}
                            items={[
                                {
                                    title: "Javolin Main Warehouse",
                                },
                                {
                                    title: "Jav Test Warehouse",
                                }
                            ]}
                            position={"bottom"}
                            onChange={(item) => {
                                // setForm({...form, commodity_id: item?.id});
                            }}
                        />
                    </div>


                    <div className={"tw-col-span-2"}>
                        <JavInput
                            title={"Quantity (KG)"}
                            type={"number"}
                            name={"weight"}
                            value={form?.weight}
                            onChange={handleChanges}
                            placeholder={"Quantity eg 15Kg"}
                        />
                    </div>

                    <div className={"tw-col-span-2"}>
                        <JavInput
                            title={"Bags"}
                            type={"number"}
                            name={"bags"}
                            value={form?.package}
                            onChange={handleChanges}
                            placeholder={"Bags eg 15 bags"}
                        />
                    </div>

                    <JavInput
                        title={"Unit Price"}
                        type={"number"}
                        name={"unit_price_ncy"}
                        value={form?.unit_price_ncy}
                        onChange={handleChanges}
                        placeholder={"Unit Price eg $12"}
                    />

                    <JavInput
                        disabled={true}
                        title={"Total Price"}
                        type={"number"}
                        onChange={handleChanges}
                        value={form?.unit_price_ncy * form?.weight}
                        name={"total_price"}
                    />


                </div>

                {/* <div className={"tw-col-span-2 mt-5"}>
                    <JavDatePicker
                        title={"Expected Date of Arrival"}
                        range={false}
                        date={form?.expected_stock_in_at}
                        onChange={(date) => {
                            console.log("date", date);
                            setForm({
                                ...form,
                                expected_stock_in_at: date?.startDate
                            })
                        }}
                    />
                </div> */}

                {
                    inventoryId === "add-item" &&
                    <div className={"tw-col-span-2 tw-mt-5 tw-flex tw-items-center tw-justify-end"}>
                        <JavButton
                            title={"Add to inventory"}
                            textColor={"tw-text-white"}
                            onClick={onCreateNewItemClicked}
                        />
                    </div>
                }

            </div>

            {
                inventoryId !== "add-item" &&
                <div className={"tw-mx-20 tw-flex tw-flex-col tw-px-2 tw-my-2 tw-border tw-rounded tw-py-3 tw-bg-white"}>

                    <span className={"tw-font-firmaBold"}>Inventory History</span>
                    <span className={"tw-text-xs tw-text-gray-600"}>history of movement in/outs</span>

                    <Table
                        title={"Inventory"}
                        ref={tableRef}
                        columns={["No", "Weight", "Remarks", "Uploaded By", "Timestamp", "Action"]}
                        link={`/business/agric/inventories/${inventoryId}/carry-in-outs`}
                        tag={"inventory-carry-in-outs.table"}
                        fields={[{
                            render: (content, index) => {
                                return (
                                    <td className={"text-center"}>
                                        {index + 1}
                                    </td>
                                )
                            }
                        }, {
                            render: (content) => {
                                return (
                                    <div className={"tw-flex tw-h-10 tw-items-center tw-justify-center"}>
                                        {content?.weight_unit}{content?.weight}
                                    </div>
                                )
                            }
                        }, "meta.remarks", "creator.name", "created_at", {
                            render: (content) => {
                                return (
                                    <div className={"tw-flex tw-items-center tw-justify-center"}>
                                        <JavButton onClick={() => {
                                            downloadFile(`admin-portal/documents/${content?.id}/download`, null, content?.name).then(r => {
                                                // setExportDownloading(null);
                                            })
                                        }} className={"tw-p-1"} bgColor={"tw-bg-gray-200 "}>
                                            <DownloadIcon/>
                                        </JavButton>
                                    </div>
                                )

                            }
                        }]}
                    />


                </div>
            }


        </div>

    )
}