import React, {useEffect} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar, Line} from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxis: {
            display: true,
            grid: {
                display: true
            },
            ticks: {
                font: {
                    size: 10,
                    family: "firma-bold"
                },
                callback: function(value) {
                    if (value >= 100000) {
                        return (value / 1000000) + 'M'; // Convert to 'M' format for millions
                    } else if (value >= 1000) {
                        return (value / 1000) + 'k'; // Convert to 'k' format for thousands
                    }
                    return value;
                }
            }
        },
        xAxis: {
            grid: {
                display: false
            }
        }
    },
    plugins: {
        legend: {
            position: 'top',
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
        tooltip: {
            titleFont: {
                family: 'firma-bold'
            },
            bodyFont: {
                family: 'firma-bold'
            },
            footerFont: {
                family: 'firma-bold'
            }
        },
        datalabels: {
            color: '#000000',
            anchor: 'end',
            align: 'start',
            clamp: true,
            labels: {
                title: {
                    font: {
                        weight: 'bold',
                        family: "firma-bold"
                    }
                }
            },
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderRadius: 4,
            formatter: function (value, context) {
                if (value >= 100000) {
                    return (value / 1000000).toFixed(2) + 'M'; // Convert to 'M' format for millions
                } else if (value >= 1000) {
                    return (value / 1000).toFixed(2) + 'k'; // Convert to 'k' format for thousands
                } else if (value < 1) {
                    return null;
                }
                return value;
            },
            display: function(context) {
                return true;
            },
            padding: 6
        }
    },
};



export function BarChart(props) {

    let labels = props?.labels;

    useEffect(() => {
        console.log("BarChart:props",props);
    },[props])

    let data = {
        labels,
        datasets: props?.datasets,
    };


    return <Line options={options} data={data} />;
}
