import Styles from "../../Menu/Menu.module.css";

const SideMenuItem = ({ icon, text, style, isActive, ...rest }) => {
  console.log("---------------", style, isActive);

  return (
    <nav>
      <div
        {...rest}
        className={`tw-flex tw-py-4 tw-bg-${
          isActive ? "jav-active-100" : ""
        } tw-text-white tw-px-4`}
      >
        {icon}
        <span className={Styles.javMenuText}>{text}</span>
      </div>
    </nav>
  );
};

export default SideMenuItem;
