import JavLabel from "../../../../../Shared/Components/Forms/JavLabel";
import JavButton from "../../../../../Shared/Components/Forms/JavButton";
import EditIcon from "../../../../../Shared/Components/Icons/EditIcon";
import EditLoanWayBill from "./EditLoanWayBill";
import {useEffect, useState} from "react";
import {currencyFormat} from "../../../../../helpers/utils";

export default function LoanDetailWaybill(props) {

    const [editWayBillDialogOpened,setEditWaybillDialogOpened] = useState(false);
    const [wayBillInfo,setWaybillInfo] = useState(null);
    const [loanRequestInfo,setLoanRequestInfo] = useState(null);

    useEffect(() => {
        let wayBillFromStageInfo = props?.data?.loan_stage_infos?.filter(info => {
            return info?.stage === "waybill";
        })
        let loanRequestInfos = props?.data?.loan_stage_infos?.filter(info => {
            return info?.stage === "loan_request";
        })

        if (wayBillFromStageInfo?.length > 0) {
            setWaybillInfo(wayBillFromStageInfo[0])
        }
        if (loanRequestInfos?.length > 0) {
            setLoanRequestInfo(loanRequestInfos[0])
        }
    }, [props?.data?.loan_stage_infos]);

    return (
        <div>

            <EditLoanWayBill
                data={props?.data}
                open={editWayBillDialogOpened}
                wayBillInfo={wayBillInfo}
                loanRequestInfo={loanRequestInfo}
                onCloseClicked={() => setEditWaybillDialogOpened(false)}/>

            <div className={"tw-flex tw-justify-end tw-px-5 tw-pt-3"}>
                <JavButton
                    textColor={"tw-text-white"}
                    title={"Upload Waybill"}
                    icon={EditIcon}
                    onClick={() => {
                        setEditWaybillDialogOpened(true)
                    }}
                />
            </div>

            <div className={"tw-grid tw-my-5 tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-8 tw-pb-4 tw-px-4 tw-rounded"}>

                <div className={"tw-grid-cols-1 md:tw-col-span-3"}>
                    <span
                        className={"tw-uppercase tw-font-satoshiBold tw-text-sm tw-text-blue-800"}>Actual Loan Details</span>
                </div>

                <JavLabel
                    title={"Total actual weight"}
                    value={`${currencyFormat(wayBillInfo?.total_weight)} Kg`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Total Bags"}
                    value={`${wayBillInfo?.total_bags ?? '-'} Bags`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Number Of Bags per Truck"}
                    value={`${wayBillInfo?.bags_per_truck ?? '-'} Bags`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Second Reimbursement"}
                    value={`${props?.data?.currency} ${wayBillInfo?.balance ? Math.abs(wayBillInfo?.balance) : '-'}`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Total Payable Amount"}
                    value={`${props?.data?.currency} ${currencyFormat(wayBillInfo?.total_payable_amount)}`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />

                <JavLabel
                    title={"Total Profit"}
                    value={`${props?.data?.currency} ${currencyFormat(wayBillInfo?.total_profit)}`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />

                <JavLabel
                    title={"Total Javolin Profit"}
                    value={`${props?.data?.currency} ${currencyFormat(wayBillInfo?.total_javolin_profit)}`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />


                <JavLabel
                    title={"Upload Waybill Document"}
                    render={() => {
                        return (
                            <div className={"tw-underline tw-text-blue-600"}>
                                Download Now
                            </div>
                        )
                    }}
                />


            </div>


            <div className={"tw-flex tw-justify-end tw-px-5 tw-pt-3"}>
                <JavButton
                    textColor={"tw-text-white"}
                    title={"Upload Warehouse"}
                    icon={EditIcon}
                    onClick={() => {
                        setEditWaybillDialogOpened(true)
                    }}
                />
            </div>

            <div className={"tw-grid tw-my-5 tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-8 tw-pb-4 tw-px-4 tw-rounded"}>

                <div className={"tw-grid-cols-1 md:tw-col-span-3"}>
                    <span
                        className={"tw-uppercase tw-font-satoshiBold tw-text-sm tw-text-blue-800"}>Warehouse Details</span>
                </div>

                <JavLabel
                    title={"Total actual weight"}
                    value={`${currencyFormat(wayBillInfo?.total_weight)} Kg`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Total Bags"}
                    value={`${wayBillInfo?.total_bags ?? '-'} Bags`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Number Of Bags per Truck"}
                    value={`${wayBillInfo?.bags_per_truck ?? '-'} Bags`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Second Reimbursement"}
                    value={`${props?.data?.currency} ${wayBillInfo?.balance ? Math.abs(wayBillInfo?.balance) : '-'}`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Total Payable Amount"}
                    value={`${props?.data?.currency} ${currencyFormat(wayBillInfo?.total_payable_amount)}`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />

                <JavLabel
                    title={"Total Profit"}
                    value={`${props?.data?.currency} ${currencyFormat(wayBillInfo?.total_profit)}`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />

                <JavLabel
                    title={"Total Javolin Profit"}
                    value={`${props?.data?.currency} ${currencyFormat(wayBillInfo?.total_javolin_profit)}`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />


                <JavLabel
                    title={"Upload Waybill Document"}
                    render={() => {
                        return (
                            <div className={"tw-underline tw-text-blue-600"}>
                                Download Now
                            </div>
                        )
                    }}
                />


            </div>


        </div>
    )
}