import ApplicationToolbar from "./ApplicationToolbar";
import ApplicationNavigationBar from "./ApplicationNavigationBar";
import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
// import Logo from "../../../assets/button_logo.png";
import Logo from "../../../assets/javolin_logo_rev.svg";
import MainStyles from "../Menu/Menu.module.css";
import HeaderBgPatter from "../Icons/HeaderBgPatter";
import Alert from "../../../Shared/Components/Alert/Alert";
import { useAlert } from "../../Context/AlertContext";
import { Bars } from "../JavIcons";
import MobileSidebar from "../Drawer/MobileSidebar";
import JavFormSelect from "../Forms/JavFormSelect";
import JavSelect from "../Forms/JavSelect";
import {useDispatch, useSelector} from "react-redux";
import {getRunningHeader} from "../duck/action";

export default function ApplicationRouteLayout({
  component: Component,
  path,
  title, shouldNotHaveHeader,
}) {
  const [theme, setTheme] = useState(localStorage.theme || "white");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false); // State for sidebar visibility
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const base_currency = useSelector((state) => state?.resources?.base_currency)
  const [baseCurrency, setBaseCurrency] = useState(base_currency ? base_currency : "USD")
  const runningCurrencies = useSelector((state) => state?.resources?.running_header)
  const defaultCurrencies = useSelector((state) => state?.resources?.defaultCurrencies)
  const dispatch = useDispatch()
  const alert = useAlert();
  const items = []


  const changeColorTheme = () => {
    console.log("changeColorTheme....");
  };

  const handleCurrencyChange = (currency) => {
      console.log("handleCurrencyChange.location",currency)
      dispatch(getRunningHeader(currency))
      setBaseCurrency(currency)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  Object.values(defaultCurrencies).forEach((currency, index) => {
      const item = currency.ISO ? currency?.ISO : currency?.symbol
      items.push({
          key: index,
          title: item,
      })
  })

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const applicationNormalHeader = () => {
    return (
        <div className={
            "tw-h-16 text-white tw-flex tw-items-center tw-justify-between " +
            "tw-w-full overflow-hidden tw-bg-jav-primary-300 tw-bg-bg-pattern"
        }>
              <span className={"tw-text-lg tw-font-satoshiMedium tw-px-4"}>
                {" "}
                {title}{" "}
              </span>
          <span
              className={
                "tw-px-4 tw-font-satoshi tw-text-gray-100 tw-text-sm"}
          >
                {" "}
            {path}{" "}
              </span>
        </div>
    )
  }

  const applicationRunningHeader = () => {
    return (
        <div className={
            "tw-h-16 text-white tw-flex tw-items-center " +
            "tw-w-full overflow-hidden tw-bg-jav-primary-300 tw-bg-bg-pattern"
        }>

          <div className={"tw-pl-5 tw-pr-2 "}>
            <JavSelect
                hideBorder={true}
                holderClassName={"tw-rounded-md tw-bg-white tw-outline-none tw-h-8 tw-w-20"}
                items={items}
                position={"bottom"}
                onChange={(item) => {
                    handleCurrencyChange(item?.title)
                }}
            />
          </div>
          <marquee>
            <div className={"tw-flex"}>
              {
                runningCurrencies?.map(item => {
                  return (
                      <div className={"tw-flex tw-mx-10 tw-text-white"}>
                        <span className={"tw-font-proxima"}>{item?.currency}</span>
                        <span className={"tw-font-proximaBold tw-mx-1"}>{item?.amount}</span>
                      </div>
                  )
                })
              }
            </div>

          </marquee>

        </div>
    )
  }

  return (
      <Route
          render={(props) => (
              <div
                  className={
                    "tw-absolute top-0 tw-bg-[#000000] tw-flex tw-w-screen tw-h-screen"
                  }
              >
                {alert.show && (
                    <div
                        className="tw-fixed tw-top-4 tw-right-4 tw-z-50"
                        style={{maxWidth: "calc(100vw - 1rem)"}}
                    >
                      <Alert {...alert} />
                    </div>
                )}

                {/* Sidebar */}
                {!isMobile ? (
                    <div
                        className={`tw-hidden lg:tw-block tw-w-72
                    dark:tw-bg-jav-primary-900 tw-bg-jav-primary-300 tw-border-blue-800 dark:tw-border-[#181E2C]
                    tw-overflow-hidden tw-overflow-y-auto`}
            >
                    <div
                        onClick={changeColorTheme}
                        className="tw-h-20 tw-flex tw-pl-6 tw-mt-4"
                    >
                        <img src={Logo} className="tw-h-10 p-1" alt={"Javolin Logo"}/>
                    </div>
                    <div className="tw-mt-2">
                        <ApplicationNavigationBar />
              </div>
            </div>
          ) : (
            <MobileSidebar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} />
          )}

          {/* Mobile Bottom
          {/* {/* Main Content */}
          <div className="tw-static tw-flex-1 tw-overflow-hidden tw-overflow-y-auto">
            <ApplicationToolbar
              className={
                "tw-bg-gray-50 tw-dark:bg-[#141925] tw-dark:border-[#141925]"
              }
              isSidebarVisible={isSidebarVisible}
              setIsSidebarVisible={setIsSidebarVisible} // Pass the state to ApplicationToolbar
            />

            {
                !shouldNotHaveHeader &&
                (
                    window?.location?.pathname === "/"
                        ? applicationRunningHeader()
                        : applicationNormalHeader()
                )

            }

            <div className="tw-mt-3 md:tw-mt-6 tw-mx-1 md:tw-mx-4">
              <Component {...props} />
            </div>
          </div>
        </div>
      )}
    />
  );
}
