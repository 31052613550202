import { normalize, schema } from "normalizr";
import { Select } from "antd";
import moment from "moment";
import axios from "axios";
import Cookies from "js-cookie";
import {REACT_APP_BASE_API_URL} from "./contants";

const {Option} = Select

export const normalizeApplicationsData = (originalData) => {
    const application = new schema.Entity("applications");

    const applications = new schema.Array(application)

    const normalizedData = normalize(originalData, applications);

    return normalizedData;
};


export const unCamelCase = (s) =>{
    s = s.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    return s.split(/(?=[A-Z])/).join(' ');
}


export const createRules = (val)=>{
    val = filterValueSet(val)
    let rules = []
    rules.push({
        required: val?.isRequired,
        message: val?.errorMessage
    })
    if (val?.validationRules) {
        val?.validationRules.map((rule => (
            rules.push(
                {
                    pattern: rule?.regEx,
                    message: rule?.errorMessage
                }
            )
        )))
    }
    return rules
}

export const ShowOptions = (val)=>{
    val = filterValueSet(val)
    let newValueSet = val?.valueSet

    Object.values(newValueSet).map((option) => {
        const fields = Object.entries(option)

        return (
            <Option key={fields[0][1]} value={fields[0][1]}>{fields[1][1]}</Option>
        )
    })
}

export const ShowAPIOption = ({val})=>{
    
    console.log('value: ', val)
    // const [items, setItems] = useState('')
    // const dispatch = useDispatch()

    // try {
    //     dispatch(getDropdownListFromAPI(val.links[0]?.javolinRoute)).then((res) => {
    //         console.log('res: ', val.links[0])
    //         setItems(res)
    //     })

    // } catch (err) {

    // }
    // const _items = items ? items : []

    // _items?.map((option) => {
    //     const fields = Object.entries(option)

    //     return (
    //         <Option value={fields[0][1]}>{fields[1][1]}</Option>
    //     )
    // })
    return(
        < Option  > New </Option >
    )
        
}

export const statusColor = (status) => {
    switch (status) {
        case "INCOMPLETE":
            return "red"
        case "APPLY_FOR_PRE_APPROVAL":
            return "yellow"
        case "PASSED":
            return "blue"
        case "APPROVE":
            return "lime"
        case "PRE_APPROVE":
            return "volcano"
        case "DECLINE_PRE_APPROVAL":
            return "red"
        case "APPLY_FOR_APPROVAL":
            return "green"
        default:
            return "magenta";
        // DECLINE_PRE_APPROVAL	APPLY_FOR_APPROVAL APPLY_FOR_APPROVAL 

    }
}

export const filterValueSet = (data)=>{
    let newValueSet = {}
    let valueSet = data?.valueSet
    
    // eslint-disable-next-line array-callback-return
    valueSet.map((value)=>{
        let entries = Object.entries(value)
        const newLocal = [entries[0][1]];
        newValueSet[newLocal] = {
            ...value
        }
    })

    return {
        ...data,
        valueSet: newValueSet
    }
}

export const getRulesDefaultValues = (data) => {
   const newData = Object.values(data)
   let defaultValues = {}
   // eslint-disable-next-line array-callback-return
   newData.map((item)=>{
       if (item?.defaultValue)
        defaultValues[item.id] = item?.defaultValue
   })
    return defaultValues
}

export const fileterRules = (data, filt)=>{
    const newDate  = Object.values(data)
    const result = newDate.filter(d => !filt.includes(d.id));
    return result
}


export const customDatFormat = (date)=>{
    let time = new Date(date).toLocaleTimeString()
    let date_ = new Date(date).toLocaleDateString()

    return `${date_} ${time}`
}
// creates an array id for sub json object
export const formatRegulatoryQuestions = (data)=>{
    let res = []
    
    data?.map((item)=>{
        return(
            res.push(
                {
                    ...item,
                    id: ['regulatory', item.id]
                }
            ) 
            
        )
    })

    return res
}

export const formatRegResults = (data)=>{
    const res = []
    Object.entries(data).map((d)=>{
        return(
            res.push(
                {
                    "key": d[0],
                    "value": d[1]
                }
            )
        )
    })

    return res
}

export function* chunks(l) {
    for (let i = 0; i < l?.length; i = i + 5) {
        yield l.slice(i, i + 5)
    }
}

export const tagColor = (msg) => {
    switch (msg) {
        case "new!":
            return "lime"
        case "coming soon!":
            return "volcano"
        case "Bientôt disponible":
            return "volcano"
        case "nouvelle":
            return "lime"
        default:
            return "magenta";
    }
}

export const statusTagColor = (msg) => {
    switch (msg) {
        case "SUCCESS":
            return "lime"
        case "PENDING":
            return "yellow"
        case "FAILED":
            return "volcano"
        case "approved":
            return "lime"
        default:
            return "magenta";
    }
}

export const  removeByKey = (myObj, deleteKey) =>{
    delete myObj[deleteKey]

    return myObj
  }

export  const getTimeUserZone = ()=>{
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone
}

export const getAbbrUserZone = ()=>{
   return new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]
}

export const spiltErrors = (sample) => {
    let message = "";
    if (sample) {
        Object.values(sample).map((e) => {
            return e.forEach((element) => {
                message += `${element}\n,`;
            });
        });
    } else {
        message = "Something went wrong";
    }

    return message;
};

export const sortListByDate = (data) => {
    const sorted = data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    return sorted
}

export const currencyFormat = (val, decimal = 2)=>{
    if (val === undefined || val === null) {
        return "";
    }
    return Intl.NumberFormat('en-us', { minimumFractionDigits: decimal,
        maximumFractionDigits: decimal, }).format(val)
}
export const generateID = () => {
    return Math.floor(Math.random() * 1000);
};

export const buildQueryParamFromForm = (keys) => {
    keys["instance_time"] = new Date().getTime();
    return Object.keys(keys)
        .filter((item) => {
            return keys[item] != null && keys[item] !== "";
        })
        .map((item, key) => {
            return item.trim() + "=" + keys[item];
        }).join("&");
}

export const makeLinkReloadable = (link) => {
    const separator = link.includes('?') ? '&' : '?';
    return link + separator + buildQueryParamFromForm({});
}

export const isEmpty = (obj) => {
    if (!obj) return true
    return Object.keys(obj).length === 0 && obj.constructor === Object
}


export const normalizeIdData = (data)=>{
    let formattedData = {}

    Object.values(data).forEach(item => {
        formattedData[item.id] = {
            ...item
        }
    });
   


    return formattedData
}
export const normalizeOneIdData = (data)=>{

    return {
        [data.id]:{
            ...data
        }
    }
}
export const normalizeIdArrayData = (data) => {
    let formattedData = {}

    data?.forEach((item) => {
        formattedData[item.id] = {
            ...item
        }
    })


    return formattedData
}

export const arrayToObjectByID= (data) => {
    let formattedData = {}

    data.map((item) => {

        return(
            formattedData[item.id] = {
                ...item
            }
        )
        
    })


    return formattedData
}

export const capitalizeString=(mySentence)=>{
const words = mySentence.split(" ");

for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
}

return words.join(" ");
}


export const _getFee = () => {
    return new Promise((res, rej) => {
        setTimeout(() => res({fee:0.2}), 250)
    })
}
export const modules = {
    JAVOLIN_TO_JAVOLIN_TRANSFER: 'JAVOLIN_TO_JAVOLIN_TRANSFER',
    PURCHASE_AIRTIME:'PURCHASE_AIRTIME',
    JAVOLIN_TOP_UP:'JAVOLIN_TOP_UP',
    SEND_MOMO: 'SEND_MOMO'
}

export const makeJavolinRequest =  (request, dispatchVariables , dispatch, onSuccess = null,onError =  null,tag = null) => {
    let options = {
        method: request.method,
        url: REACT_APP_BASE_API_URL + request.path,
        data: request.data,
        headers: {
            Authorization: "Bearer "+ Cookies.get("javAccessToken")
        },
        onDownloadProgress: process => {
            let percentCompleted = Math.round(
                (process.loaded * 100) / process.total
            );
            // console.log("download completed : ",percentCompleted)
        },
        onUploadProgress: progress => {
            console.log("onUploadProgress -> ",progress);
        }
    }
    if (request.query) {
        options = {
            ...options,
            params: request.query
        }
    }
    axios.request(options).then((response) => {

        // console.log(response);

        if (onSuccess != null) {
            onSuccess(response.data);
        }

        dispatch && dispatch({
            type: dispatchVariables?.SUCCESS,
            tag: tag,
            payload: response.data,
        })


    }).catch(error => {
        let errorMessage = error.message;
        if (error.response) {
            console.log("error_response",error.response);
            errorMessage = error?.response?.data?.message;
        }
        console.log("error",errorMessage);
        dispatch && dispatch({
            type: dispatchVariables?.ERROR,
            payload: errorMessage,
            tag: tag,
        })
        if (onError != null) {
            onError(errorMessage);
        }

    })
}

export const makeJavolinPostHttpRequest = (path,data,onSuccess,onError) => {
    return makeJavolinRequest({
        method: "POST",
        path: path,
        data: data,
    },null,null,onSuccess,onError)
}

export const makeJavolinPutHttpRequest = (path,data,onSuccess,onError) => {
    return makeJavolinRequest({
        method: "PUT",
        path: path,
        data: data,
    },null,null,onSuccess,onError)
}

export const makeJavolinGetHttpRequest = (path,query,onSuccess,onError) => {
    return makeJavolinRequest({
        method: "GET",
        path: path,
        query: query,
    },null,null,onSuccess,onError)
}

export const getTableData = (data,fields) => {
    return data && data.map((item) =>
    {
        return {
            fields: fields.map( (field,index) => {

                if (field instanceof Object) {
                    return {
                        "id": field.id,
                        "render": field.render,
                        "content": item
                    }
                }else {
                    let str = item[field]
                    if (field.indexOf(".") > -1) {
                        let ii = item;
                        field.split(".").forEach((it) => {
                            if (ii !== undefined && ii !== null) {
                                str = ii[it] && ii[it]
                                ii = str;
                            }
                        })
                    }
                    return {
                        "id": field,
                        "title": str
                    }
                }

            })
        }
    })

}

export const getParameterByName = (name, url)  => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function appendQueryParam(baseUrl, queryString) {
    const separator = baseUrl.includes('?') ? '&' : '?';
    return `${baseUrl}${separator}${queryString}`;
}

export const ucFirstWord = (str) => {
    return str
        .toLowerCase()
        .replaceAll("_"," ")
        .split(" ")
        .map(item => item.charAt(0).toUpperCase() + item.substring(1,item.length))
        .join(" ");
}

export const isValidURL = (url) => {
    // Regular expression for a valid URL
    var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    return urlRegex.test(url);
}


export const downloadFile = async (path,fileType,exportFileName) => {

    let endpointUrl = appendQueryParam(REACT_APP_BASE_API_URL + path,`export_option=${fileType ?? 'excel'}`);
    if (path.includes("http") || path.includes("https")) {
        endpointUrl = path;
    }

    try {

        const response = await axios.get(endpointUrl, {
            responseType: 'blob',
            headers: {
                Authorization: "Bearer "+ Cookies.get("javAccessToken")
            },
        })
        const contentDisposition = response.headers['content-disposition'];
        let suggestedFilename = new Date().getTime();
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="(.*)"/);
            if (match && match[1]) {
                suggestedFilename = match[1];
            }
        }
        console.log("suggestedFilename",suggestedFilename);
        suggestedFilename = suggestedFilename?.toString();
        if (exportFileName) {
            suggestedFilename = exportFileName;
        }
        suggestedFilename = suggestedFilename?.replace("vnd.openxmlformats-officedocument.spreadsheetml.sheet","xlsx")

        console.log("suggestedFilename.final",suggestedFilename);
        console.log("suggestedFilename.final",exportFileName);


        console.log(response.data)
        // const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        //TODO: replace with the file extentsion
        link.setAttribute('download', suggestedFilename);
        document.body.appendChild(link);
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }
    catch (e) {
        throw e
    }
}


