import Dialog from "../../../Shared/Components/Dialog/Dialog";
import React, {useContext, useEffect, useState} from "react";
import {LoanTabsContext} from "./Tabs/LoanTabsContext";
import CheckIcon from "../../../Shared/Components/Icons/CheckIcon";

const  LoanRequest = (props) => {
    const isMobile = window.innerWidth <= 768;
    const dialogWidth = isMobile ? "100%" : "40%";
    const {tabs} = useContext(LoanTabsContext);

    useEffect(() => {
        console.log("context",tabs);
    },[tabs])

    const indicatorStyles = (item,index) => {
        return item?.completed ? 'tw-bg-jav-card-500' : (index === tabs?.selectedIndex ? 'tw-bg-blue-50 tw-text-blue-700' : '');
    }

    return (
        <Dialog position={"top"} style={{width: dialogWidth}} open={props?.open} onCloseClicked={props?.onCloseClicked} title={"Loan Request"}>

            <div className={"tw-py-0 tw-relative tw-flex tw-justify-between"}>

                <div className={"tw-grid tw-grid-flow-col tw-justify-stretch tw-w-full tw-z-20"}>

                    {
                        tabs?.tabs?.map((item, index) => {
                            return (
                                <div onClick={() => {
                                    // setSelectedIndex(index)
                                }} className={"tw-flex tw-cursor-pointer tw-items-center tw-justify-content-end tw-flex-col"}>

                                    <div className={"tw-flex tw-items-center tw-w-full tw-justify-between"}>
                                        <div
                                            style={{backgroundImage: `url("https://staging.api.javolin.com/line.svg")`}}
                                            className={`tw-h-0.5 tw-flex-1 ${index === 0 && 'tw-invisible'} `}/>

                                        <span
                                            className={`tw-border tw-font-satoshiMedium tw-text-xs z-30 
                                            tw-h-8 tw-rounded-full tw-w-8 tw-flex tw-items-center tw-justify-center 
                                            ${indicatorStyles(item,index)}
                                            `}
                                        >
                                            {item?.completed ?
                                                <CheckIcon classname />
                                                : index + 1 }
                                        </span>

                                        <div
                                            style={{backgroundImage: `url("https://staging.api.javolin.com/line.svg")`}}
                                            className={`tw-h-0.5 tw-flex-1 ${index === tabs?.length - 1 && 'tw-invisible'}`}/>
                                    </div>

                                    <span className={"tw-text-xs tw-uppercase"}>{item?.title}</span>
                                </div>
                            )
                        })
                    }

                </div>

            </div>

            <div className={"mt-5 tw-px-8"}>
                {
                    props?.children?.map((item, index) => {
                        return tabs?.selectedIndex === index && (
                            props.children[index]
                        )
                    })
                }
            </div>



        </Dialog>
    )
}

export default function LoanRequestDialog(props) {
    const [tabs, setTabs] = useState({
        tabs: props?.children?.map(item => {
            return {
                title: item?.props?.title,
                index: item?.props?.title,
                completed: false
            }
        }),
        selectedIndex: 0
    });

    return (
        <LoanTabsContext.Provider value={{tabs ,setTabs}}>
            <LoanRequest {...props}/>
        </LoanTabsContext.Provider>
    )
}