import { Modal, Spin, Tag } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector, useStore } from "react-redux"
import { getTransactions } from "../Savings/duck/action"
import { approveTransaction, declineTransaction, getPendingTransactions } from "./duck/action"
import Styles from "./UserMgt.module.css"



const PendingActionTag = ({action, details, closeModal, styles,title,handler})=>{
    const id = details?.id
    const { confirm } = Modal
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const text = useSelector((state) =>state.language)


    function showConfirm() {
        confirm({
          title: 'Are you sure want to approve this transaction?',
        //   content: 'Some descriptions',
          onOk() {
            setLoading(true)
            dispatch(approveTransaction(id)).then(()=>{
                setLoading(false)
                if(closeModal){
                    closeModal(false)
                }
            }).catch((e)=>{
                setLoading(false)
            })
          },
          onCancel() {

          },
          okButtonProps: {
            // loading: apply.submittingApplication
          },
        });
      }

    useEffect(()=>{
        dispatch(getPendingTransactions())
        dispatch(getTransactions())
    }, [dispatch])
    const handleClick=(id)=>{
        if(action === "approve"){
            // if(details.old_rate[0] === details.new_rate[0])
            //     return showConfirm()

            setLoading(true)
            dispatch(approveTransaction(id)).then(()=>{
                setLoading(false)
                if(closeModal){
                    closeModal(false)
                }
            }).catch((e)=>{
                setLoading(false)
            })
        }else if (action === "handler") {
            handler();
        } else{
            setLoading(true)
            dispatch(declineTransaction(id)).then(()=>{
                if(closeModal){
                    closeModal(false)
                }
                setLoading(false)
            }).catch((e)=>{
                setLoading(false)
            })
        }
    }
    return(
        <>
        
        <Tag onClick={()=>handleClick(id)}  className={`${Styles.javTag} ${action === "action"? "actionBtn": "declineBtn"}`} 
        
            style={{
            padding: "10px",
            color: action==="approve"? '#008000': "#FF0000",
            cursor:"pointer",
            ...styles
            
            
            }} 
            color={action==="approve"? "#E0FFE0" : "#FFE0E0"}
            >

            <Spin spinning={loading}/>
            {title ? title : text[action]}
        </Tag>
        </>
    )
}

export default PendingActionTag