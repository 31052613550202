import {useState} from "react";

export default function JavDropdown(props) {

    const [menuOpened,setMenuOpened] = useState(false);

    return (
        <div onClick={() => {
            setMenuOpened(!menuOpened)
        }} className={`tw-relative tw-flex tw-items-center tw-justify-center dark:tw-border-white tw-rounded-full tw-cursor-pointer ${props.profile ? "tw-w-20":""}`}>

                    <span className={"tw-text-sm tw-dark:text-white tw-font-medium"}>
                        {props?.children}
                    </span>


            <div className={`tw-mt-4 tw-absolute tw-z-100 tw-border 
                            tw-overflow-hidden tw-overflow-y-auto
                            tw-max-h-44 tw-bg-white 
                            tw-mb-2 tw-rounded tw-top-${props?.topSpace || '2'} tw-left-0 tw-right-0
                         ${menuOpened ? 'tw-block' : 'tw-hidden'}
                         `}
            >
                {
                    props?.actions?.map((item, index) => {
                        return (
                            <div key={index} data-class={"allowed"} onClick={item.onClick}
                                 className="tw-h-10 tw-px-6 tw-border-t tw-flex tw-text-gray-600
                            hover:tw-bg-gray-50 tw-text-xs tw-cursor-pointer
                             tw-items-center">
                                {
                                    item.title
                                }
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}