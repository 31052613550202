import { Button, Form, Input, Row, Col, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { addingUser, getRoles, getUsers, updateBusUser } from "./duck/action";
import JavButton from "../../Shared/Components/Forms/JavButton";
import JavInput from "../../Shared/Components/Forms/JavInput";
import Dialog from "../../Shared/Components/Dialog/Dialog";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";
import { makeJavolinRequest } from "../../helpers/utils";
// import { refreshPage, showToast } from "../../Shared/Components/Toast/duck/action";
import CheckIcon from "../../Shared/Components/Icons/CheckIcon";
import { useAlert } from "../../Shared/Context/AlertContext";
import XIcon from "../../Shared/Components/Icons/XIcon";

const EditUser = ({ isVisible, setIsModalVisible, editUser, setEditUser }) => {
  console.log(editUser);
  const tableRef = useRef();
const { showAlert } = useAlert();
const isMobile = window.innerWidth <= 768;
const dialogWidth = isMobile ? "100%" : "40%";

  const [isLoading, setRequestLoading] = useState(false);
  const [form, setForm] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    role_id: "",
  });

//   const { Option } = Select;
  const roles = useSelector((state) => state?.userMgt?.roles);
  const text = useSelector((state) => state?.language);
  const [disableBtn, setButtonDisable] = useState(true);

  let _roles = roles ? roles : {};

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const handleChanges = (event) => {
    const value = event.target.value;
    setButtonDisable(false)
    setForm({
      ...form,
      [event.target.name]: value,
    // full_name: editUser.full_name,
      email:editUser.email,
      phone_number:editUser.phone_number,
      role_id:editUser.role.id
    });
  };

  const updateUserRequest = (data, id) => {
    setRequestLoading(true);
    makeJavolinRequest(
      {
        path: `/business/accounts/${id}`,
        method: "PUT",
        data: data,
      },
      null,
      null,
      (data) => {
        handleCancel()
        setRequestLoading(false);
        showAlert("User Update Successfully","SUCCESS", (<CheckIcon />))
        tableRef?.current?.reloadTable()
        console.log("data", data);
        // dispatch(refreshPage())
      },
      (error) => {
        setRequestLoading(false);
        handleCancel()
        showAlert("Error: "  + error,"FAILED", (<XIcon />))
        console.log("error", error);
      }
    );
  };

  return (
    <>
      <Dialog
        position={"top"}
        style={{ width: dialogWidth }}
        open={isVisible}
        onCloseClicked={handleCancel}
        title={"Edit user"}
        footer={false}
      >
        <Form
          layout="vertical"
          name="profile_form"
          style={{ width: "100%" }}
          //   form={form}
          //   onFinish={onFinish}
          //   onChange={handleformchange}
          //   initialValues={userDetails}
        >
          <div
            style={{
              padding: "20px 70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {/* <Form.Item
              name="full_name"
              label={text["Full Name"]}
              rules={[
                {
                  required: true,
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input
                style={{ minWidth: "490px" }}
                size="large"
                placeholder="Eg. Evans"
              />
             
            </Form.Item> */}
            <JavInput
              title={text["Full Name"]}
              name={"full_name"}
              value={form.full_name}
              className="tw-w-full tw-mb-4"
              placeholder={editUser.full_name}
              onChange={handleChanges}
            />
            <JavInput
              title={text["Email"]}
              name={"email"}
              value={editUser.email}
              className="tw-w-full tw-mb-4"
              placeholder="Eg. bc@zenithbank.com"
              readOnly={true}
              onChange={handleChanges}
            />

            {/* <Form.Item
              label={text["Email"]}
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input
                disabled
                style={{ minWidth: "490px" }}
                size="large"
                placeholder="Eg. abc@zenithbank.com"
              />
            </Form.Item> */}

            {/* <Form.Item
              label={text["Phone Number"]}
              name="phone_number"
              rules={[
                {
                  required: true,
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input
                disabled
                style={{ minWidth: "490px" }}
                size="large"
                placeholder="Eg. +1223449399304"
              />
            </Form.Item> */}

            <JavInput
              title={text["Phone Number"]}
              name={"phone_number"}
              value={editUser.phone_number}
              className="tw-w-full tw-mb-4"
              placeholder="Eg. 0541544404"
              readOnly={true}
                onChange={handleChanges}
            />

            {/* <Form.Item
              label={text["Role"]}
              name="role_id"
              rules={[
                {
                  required: true,
                },
              ]}
              style={{ width: "100%" }}
            >
              <Select
                onChange={handleformchange}
                style={{ width: "100%" }}
                placeholder={text["Select role"]}
                size="large"
              >
                {Object.values(_roles)?.map((role) => {
                  return <Option key={role.id}> {role.name}</Option>;
                })}
              </Select>
            </Form.Item> */}

            <JavFormSelect
              title={text["Role"]}
              position={"bottom"}
              className="tw-w-full tw-mb-4"
              placeholder={text["Select role"]}
              items={Object.values(_roles)?.map((role) => ({
                ...role,
                title: role.name,
              }))}
              onChange={(role) => {
                setForm({
                  ...form,
                  role_id: role.id,
                });
              }}
            />

            <JavButton
              bgColor={""}
              textColor="tw-text-white"
              disabled={disableBtn}
              className={
                "tw-text-white tw-py-4 tw-text-lg tw-h-11 tw-w-full tw-cursor-pointer tw-mt-5"
              }
              isLoading={isLoading}
              onClick={() => {
                console.log("form===================", form);
                updateUserRequest(form, editUser.id)
              }}
            >
              <span className="tw-pl-2"> {text["Edit user"]}</span>
            </JavButton>
          
          </div>
        </Form>
      </Dialog>
    </>
  );
};

export default EditUser;
