import {ActionTypes} from "./type";
import {makeJavolinRequest} from "../../../../helpers/utils";


export const fetchCodes = (group) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.FETCH_CODES_REQUEST,tag: group});
        makeJavolinRequest({
            path: `/business/common-codes`,
            method: "GET",
            query: {
                group
            },
            tag: group
        },{
            SUCCESS: ActionTypes.FETCH_CODES_SUCCESS,
            ERROR: ActionTypes.FETCH_CODES_ERROR
        },dispatch,null,null,group);
    }
}


