import JavLabel from "../../../../../Shared/Components/Forms/JavLabel";
import {currencyFormat} from "../../../../../helpers/utils";
import JavButton from "../../../../../Shared/Components/Forms/JavButton";
import EditIcon from "../../../../../Shared/Components/Icons/EditIcon";
import {useState} from "react";
import BLDialog from "./components/BLDialog";
import {useSelector} from "react-redux";

export default function LoanContractDetailBlInformation(props) {

    const wayBillInfo = {};
    const [openBlDialog,setOpenBlDialog] = useState(false);
    const tradingHub = useSelector((state) => state.tradingHub)

    return (
        <div className={"tw-flex tw-flex-col"}>

            <div className={"tw-flex tw-mx-2 tw-justify-end"}>
                <JavButton
                    title={"Upload BL"}
                    textColor={"tw-text-white"}
                    className={"tw-px-5"}
                    icon={EditIcon}
                    onClick={() => {
                        setOpenBlDialog(true);
                    }}
                />
            </div>

            <div className={"tw-flex tw-flex-col md:tw-flex-row"}>
                <div className={"tw-w-full md:tw-w-3/5 tw-bg-white tw-border tw-m-2 tw-rounded-lg"}>
                    <div className={"tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-8  tw-pb-4 tw-px-4 tw-rounded"}>

                        <div className={"tw-col-span-1 md:tw-col-span-3 tw-mt-2"}>
                            <span className={"tw-uppercase tw-font-satoshiBold tw-text-sm tw-text-blue-800"}>Bill Of Laden Information</span>
                        </div>

                        <JavLabel
                            title={"BL Number"}
                            value={tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.blNumber}
                            valueSize={"tw-text-lg"}
                            valueFont={"tw-font-satoshiBold"}
                        />

                        <JavLabel
                            title={"Number of Containers"}
                            value={tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.NumberOfContainers}
                            valueSize={"tw-text-lg"}
                            valueFont={"tw-font-satoshiBold"}
                        />

                        <JavLabel
                            title={"Vessel Name"}
                            value={tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.VesselInfo?.VesselNm}
                            valueSize={"tw-text-lg"}
                            valueFont={"tw-font-satoshiBold"}
                        />

                        <JavLabel
                            title={"Voyage No"}
                            value={tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.VesselInfo?.Voyage}
                            valueSize={"tw-text-lg"}
                            valueFont={"tw-font-satoshiBold"}
                        />

                        <JavLabel
                            title={"Shipped From"}
                            value={tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.GeneralTrackingInfo?.ShippedFrom}
                            valueFont={"tw-font-satoshiBold"}
                            valueSize={"tw-text-lg"}
                        />

                        <JavLabel
                            title={"Shipped To"}
                            value={tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.GeneralTrackingInfo?.ShippedTo}
                            valueFont={"tw-font-satoshiBold"}
                            valueSize={"tw-text-lg"}
                        />

                        <JavLabel
                            title={"Port Of Loading"}
                            value={tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.GeneralTrackingInfo?.PortOfLoad}
                            valueFont={"tw-font-satoshiBold"}
                            valueSize={"tw-text-lg"}
                        />

                        <JavLabel
                            title={"Port Of Discharge"}
                            value={tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.GeneralTrackingInfo?.PortOfDischarge}
                            valueFont={"tw-font-satoshiBold"}
                            valueSize={"tw-text-lg"}
                        />

                        <JavLabel
                            title={"Transhipments"}
                            value={tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.GeneralTrackingInfo?.Transshipments}
                            valueFont={"tw-font-satoshiBold"}
                            valueSize={"tw-text-lg"}
                        />


                        <JavLabel
                            title={"BL Document"}
                            render={() => {
                                return (
                                    <div className={"tw-underline tw-text-blue-600"}>
                                        Download Now
                                    </div>
                                )
                            }}
                        />


                    </div>
                </div>

                <div className={"tw-w-full md:tw-w-2/5 tw-m-2 "}>

                    <div className={"tw-relative tw-h-48 tw-border tw-bg-white tw-outline-none tw-overflow-hidden tw-rounded-lg"}>

                        {
                            tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.VesselInfo?.VesselImage &&
                            <img
                                className={"tw-object-cover tw-h-full tw-w-full tw-outline-none tw-overflow-hidden"}
                                src={tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.VesselInfo?.VesselImage}
                                alt={""}
                            />
                        }


                        {
                            !tradingHub?.fetch_loan_contract_details?.data?.meta?.response?.VesselInfo?.VesselImage &&
                            <div
                                className={"tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-flex tw-items-center tw-justify-center"}>
                                <span>
                                    No Content
                                </span>
                            </div>
                        }

                    </div>


                    <div className={"tw-relative tw-mt-2 tw-h-64 tw-border tw-bg-white tw-rounded-lg"}>
                        <div
                            className={"tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-flex tw-items-center tw-justify-center"}>
                            <span className={"tw-font-satoshiBold tw-text-black"}>
                                No Map Content
                            </span>
                        </div>
                    </div>


                </div>
            </div>




            <BLDialog
                open={openBlDialog}
                contract={tradingHub?.fetch_loan_contract_details?.data}
                onCloseClicked={() => {
                    setOpenBlDialog(false);
                }}
            />

        </div>
    )
}