import {HorizontalChart} from "../../../../../Shared/Components/Charts/HorizontalChart";
import {LineChart} from "../../../../../Shared/Components/Charts/LineChart";
import ProgressChart from "../../../../../Shared/Components/Charts/ProgressChart";
import {useEffect, useState} from "react";
import {currencyFormat} from "../../../../../helpers/utils";

export default function LoanDetailAnalytics(props) {

    const [loanStages,setLoanStage] = useState({
        expected: null,
        actual:null
    });

    useEffect(() => {

        setLoanStage({...loanStages,
            expected: props?.data?.loan_stage_infos?.filter(item => item?.stage === "loan_request")[0],
            actual: props?.data?.loan_stage_infos?.filter(item => item?.stage === "waybill")[0]
        })
    }, [props?.data?.loan_stage_infos]);


    return (
        <div className={"tw-flex tw-flex-col"}>

            <div className={"tw-grid tw-px-4 tw-mt-5 tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-4"}>

                <div className={"tw-py-2 tw-px-5 tw-border tw-items-center tw-rounded  tw-flex tw-justify-between"}>

                    <div className={"tw-flex tw-flex-col"}>
                        <span
                            className={"tw-font-satoshiBlack tw-w-2/3 tw-text-wrap tw-text-md"}>{currencyFormat(loanStages?.actual?.total_weight)}/{currencyFormat(loanStages?.expected?.total_weight)} kg</span>
                        <span
                            className={"tw-text-xs tw-text-gray-400 tw-font-satoshiMedium"}>Performance of Weight</span>
                    </div>

                    <div>
                        <ProgressChart
                            percentage={(loanStages?.actual?.total_weight / loanStages?.expected?.total_weight) * 100}
                            className={"tw-h-20 tw-w-20"}/>
                    </div>

                </div>

                <div className={"tw-py-2 tw-px-5 tw-border tw-items-center tw-rounded  tw-flex tw-justify-between"}>

                    <div className={"tw-flex tw-flex-col"}>
                        <span className={"tw-font-satoshiBlack tw-text-2xl"}>{loanStages?.actual?.total_bags}/{loanStages?.expected?.total_bags} Bags</span>
                        <span
                            className={"tw-text-xs tw-text-gray-400 tw-font-satoshiMedium"}>Performance of Bags</span>
                    </div>

                    <div>
                        <ProgressChart
                            percentage={(loanStages?.actual?.total_bags / loanStages?.expected?.total_bags) * 100}
                            className={"tw-h-20 tw-w-20"}/>
                    </div>

                </div>

                <div className={"tw-py-2 tw-px-5 tw-border tw-items-center tw-rounded  tw-flex tw-justify-between"}>

                    <div className={"tw-flex tw-flex-col"}>
                        <span
                            className={"tw-font-satoshiBlack tw-text-md"}>{currencyFormat(loanStages?.actual?.total_profit)}/{currencyFormat(loanStages?.expected?.total_profit)} GHS</span>
                        <span
                            className={"tw-text-xs tw-text-gray-400 tw-font-satoshiMedium"}>Performance of Profit</span>
                    </div>

                    <div>
                        <ProgressChart
                            percentage={(loanStages?.actual?.total_profit / loanStages?.expected?.total_profit) * 100}
                            className={"tw-h-20 tw-w-20"}/>
                    </div>

                </div>


                <div className={"tw-py-2 tw-px-5 tw-border tw-items-center tw-rounded  tw-flex tw-justify-between"}>

                    <div className={"tw-flex tw-flex-col"}>
                        <span className={"tw-font-satoshiBlack tw-text-2xl"}> { props?.data?.total_repaid_amount } / {(currencyFormat(loanStages?.actual?.total_profit + props?.data?.amount))} GHS</span>
                        <span
                            className={"tw-text-xs tw-text-gray-400 tw-font-satoshiMedium"}>Performance of Repayment</span>
                    </div>
                    <div>
                        <ProgressChart
                            percentage={(loanStages?.actual?.total_repaid_amount / loanStages?.expected?.total_profit) * 100}
                            className={"tw-h-20 tw-w-20"}/>
                    </div>


                </div>


            </div>

            <div className={"tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-5 tw-p-5"}>

                <div className={"tw-rounded tw-p-2 tw-font-satoshiMedium tw-text-sm tw-border"}>
                    <div className={"tw-py-2 tw-flex tw-flex-col tw-border-b"}>
                        <span className={"tw-font-satoshiBold"}>💰Loan Amount</span>
                        <span className={"tw-mt-1 tw-text-gray-500"}>This loan is lower than your average loan amount you've been requested</span>
                    </div>
                    <div className={"tw-h-44"}>
                    <HorizontalChart/>
                    </div>
                </div>

                <div className={"tw-rounded tw-p-2 tw-row-span-2 tw-font-satoshiMedium tw-text-sm tw-border"}>
                    <div className={"tw-py-2 tw-flex tw-flex-col tw-border-b"}>
                        <span className={"tw-font-satoshiBold"}>💰Loan Amount</span>
                        <span className={"tw-mt-1 tw-text-gray-500"}>This loan is lower than your average loan amount you've been requested</span>
                    </div>

                    <div className={"tw-my-2 tw-grid tw-grid-cols-2"}>

                        <div>
                            <div className={"tw-text-xs tw-flex tw-items-center"}>
                                <div className={"tw-h-2 tw-w-2 tw-rounded-full tw-bg-jav-card-500 "}></div>
                                <span className={"tw-mx-1"}>Today</span>
                            </div>
                            <span className={"tw-text-2xl tw-font-satoshiBold"}>503Kg</span>
                        </div>

                        <div>
                            <div className={"tw-text-xs tw-flex tw-items-center"}>
                                <div className={"tw-h-2 tw-w-2 tw-rounded-full tw-bg-jav-card-500 "}></div>
                                <span className={"tw-mx-1"}>Average</span>
                            </div>
                            <span className={"tw-text-2xl tw-font-satoshiBold"}>503Kg</span>
                        </div>
                    </div>


                    <div className={"tw-h-86"}>
                        <LineChart/>
                    </div>

                </div>

                <div className={"tw-rounded tw-p-2 tw-font-satoshiMedium tw-text-sm tw-border"}>
                    <div className={"tw-py-2 tw-flex tw-flex-col tw-border-b"}>
                        <span className={"tw-font-satoshiBold"}>💰Loan Amount</span>
                        <span className={"tw-mt-1 tw-text-gray-500"}>This loan is lower than your average loan amount you've been requested</span>
                    </div>

                    <div className={"tw-h-44"}>
                        <HorizontalChart/>
                    </div>

                </div>


            </div>

        </div>
    )
}