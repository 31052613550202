import React, { useEffect, useState } from "react";
import SearchIcon from "../../../Shared/Components/Icons/SearchIcon";
import JavButton from "../../../Shared/Components/Forms/JavButton";
import { currencyFormat, makeJavolinRequest } from "../../../helpers/utils";
import AgricWarehouse from "../Warehouse/AgricWarehouse";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddWarehouseReview from "../Warehouse/AddWarehouse";

const MarketPlaceProductDisplay = () => {
  const [activeTab, setActiveTab] = useState("home");
  const history = useHistory();
  const [dashboard, setDashboard] = useState({
    loading: false,
    data: null,
  });

  const loadDashboard = () => {
    setDashboard({ ...dashboard, loading: true });
    makeJavolinRequest(
      {
        path: `/business/agric/inventories`,
        method: "GET",
      },
      null,
      null,
      (data) => {
        setDashboard({ ...dashboard, loading: false, data: data });
        console.log("data", data);
      },
      (error) => {
        setDashboard({ ...dashboard, loading: false });
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    loadDashboard();
  }, []);

  const onInventoryItemClicked = (inventory) => {
    history.push({pathname:`/agric/product/${inventory?.commodity?.id}`, state:inventory?.commodity?.code_name});
  };

  const groupedByCommodityCode = dashboard.data?.reduce((acc, item) => {
    const code = item.commodity.code;
    if (!acc[code]) {
      acc[code] = [];
    }
    acc[code].push(item);
    return acc;
  }, {});

  console.log("-------------", groupedByCommodityCode);

  return (
    <>
      <div className="xl:tw-px-20 lg:tw-px-10 md:tw-px-5 sm:tw-px-5 tw-px-4 tw-py-3 tw-overflow-hidden tw-overflow-y-auto">
        <div className="tw-flex tw-justify-between tw-items-center">
          <div
            className={`${"tw-border-b-4"} tw-border-jav-primary-200 tw-text-2xl tw-rounded-b-sm ${"tw-font-satoshiBold"} ${"tw-text-jav-primary-200"} tw-cursor-pointer`}
          >
            Marketplace
          </div>
        </div>

        <div className="tw-border tw-border-jav-active-200 tw-rounded-xl tw-w-full tw-h-full tw-mt-6 tw-shadow-lg">
          <div className="tw-pl-5 tw-pt-5 tw-pb-5 tw-text-xl tw-font-satoshiBold tw-text-black">Products</div>
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-2">
            {groupedByCommodityCode &&
              Object.entries(groupedByCommodityCode).map(([code, products]) => {
                const product = products[0]; // Get the first product in each group
                let image = {};
                try {
                  image = JSON.parse(product?.commodity?.code_option || "{}");
                } catch (e) {
                  console.error(
                    "Invalid JSON:",
                    product?.commodity?.code_option
                  );
                }
                return (
                  <div
                    onClick={() => {
                      onInventoryItemClicked(product);
                    }}
                    key={code}
                    className="tw-bg-white tw-rounded-2xl tw-overflow-hidden  tw-m-6 tw-p-4"
                  >
                    {image.image_url && (
                      <img
                        src={image.image_url}
                        alt={product.commodity.code_name}
                        className="tw-w-full tw-h-56 tw-object-cover tw-rounded-xl"
                      />
                    )}
                    <div className="tw-p-0">
                      <h3 className="tw-text-lg tw-font-satoshiBold tw-text-black tw-pt-2">
                        {product.commodity?.code_name}
                      </h3>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketPlaceProductDisplay;
