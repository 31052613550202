import { useEffect, useRef, useState } from "react";
import Dialog from "../../../Shared/Components/Dialog/Dialog";
import JavButton from "../../../Shared/Components/Forms/JavButton";
import JavFormSelect from "../../../Shared/Components/Forms/JavFormSelect";
import JavInput from "../../../Shared/Components/Forms/JavInput";
import JavLabel from "../../../Shared/Components/Forms/JavLabel";
import { makeJavolinRequest } from "../../../helpers/utils";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function AddNewInventoryDialog(props) {
  const isMobile = window.innerWidth <= 768;
  const dialogWidth = isMobile ? "100%" : "40%";
  const [selectedProduct, setSelectedProduct] = useState("Coffee");
  const fileInputRef = useRef(null);
  const [selectFile, setSelectFile] = useState({});
  const [createNewItem, setCreateNewItem] = useState({
    data: null,
    loading: false,
  });
  const [commodities, setCommodities] = useState(null);

  const [form, setForm] = useState({
    commodity_id: null,
    warehouse_id: null,
    weight: null,
    unit_price_ncy: null,
    expected_stock_in_at: null,
    title: null,
  });

  const resetForm = () => {
    setForm(Object.fromEntries(Object.keys(form).map((key) => [key, ""])));
  };

  const handleChanges = (event) => {
    const value = event.target.value;
    setForm({
      ...form,
      [event.target.name]: value,
    });
  };

  function getBase64(file, onSuccess, onError) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => onSuccess(reader.result);
    reader.onerror = (error) => onError(error);
  }

  const onUploadImageClicked = () => {
    fileInputRef?.current?.click();
  };

  const onImageSelected = (event) => {
    console.log("onImageSelected", event.target.files[0]);

    getBase64(
      event.target.files[0],
      (fileContent) => {
        setSelectFile({
          ...selectFile,
          file: fileContent,
          meta: event.target.files[0],
          image: URL.createObjectURL(event.target.files[0]),
        });
      },
      (error) => {
        alert("Error: " + error);
      }
    );
  };

  const onCreateNewItemClicked = () => {
    setCreateNewItem({ ...createNewItem, loading: true });
    makeJavolinRequest(
      {
        path: `/business/agric/inventories`,
        method: "POST",
        data: {
          ...form,
          file: selectFile?.file,
          warehouse_id: 1,
          weight: parseFloat(form?.weight)?.toFixed(2),
          weight_unit: "KG",
        },
      },
      null,
      null,
      (data) => {
        setCreateNewItem({ ...createNewItem, loading: false, data: data });
        resetForm();
        props?.onCloseClicked();
        alert("Item Uploaded Successfully");
      },
      (error) => {
        setCreateNewItem({ ...createNewItem, loading: false });
        console.log("error", error);
      }
    );

    console.log("onCreateNewItemClicked", form);
  };

  const fetchCommonCodes = () => {
    makeJavolinRequest(
      {
        path: "/business/common-codes?group=CT001",
        method: "GET",
      },
      null,
      null,
      (data) => {
        setCommodities(data);
        console.log(data);
      },
      (error) => {
        console.log("error is ", error);
      }
    );
  };

  useEffect(() => {
    fetchCommonCodes();
  }, [props?.code]);

  return (
    <Dialog
      position={"center"}
      style={{ width: dialogWidth }}
      open={props?.open}
      onCloseClicked={props?.onCloseClicked}
      review={false}
      title={
        <>
          <p className="tw-mb-1 tw-text-3xl tw-py-3 tw-px-3">Add new inventory</p>
          {/* <p className="tw-mb-0 tw-text-white tw-opacity-70 tw-font-satoshiLight tw-text-sm">
            Provide detailed information for creating a new item
          </p> */}
        </>
      }
    >
      <div className={"tw-flex tw-flex-col tw-px-5"}>
        <div>
          <h3
            className={
              "tw-font-satoshiBold tw-text-2xl sm:w-text-sm tw-text-black"
            }
            >
            Select product
           
          </h3>
          <div className="tw-flex tw-gap-4 ">
            {commodities?.map((product, index) => {
              let codeOption = JSON.parse(product?.code_option ?? "{}");
              return (
                <div key={index}>
                  <div
                    className={`tw-border-4 tw-rounded-lg ${
                      selectedProduct === product?.code_name
                        ? "tw-border-jav-active-500"
                        : "tw-border-white"
                    } tw-cursor-pointer`}
                  >
                    {selectedProduct === product?.code_name ? (
                      <div
                        className={`tw-absolute tw-mt-2 tw-ml-2 tw-h-4 tw-w-4 tw-place-content-center tw-rounded-full tw-bg-white`}
                      >
                        <CheckIcon height={15} className="tw-items-center" />
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <img
                      onClick={() => {
                        setForm({ ...form, commodity_id: product?.id });
                        // console.log(product?.id )
                        setSelectedProduct(product.code_name);
                      }}
                      src={codeOption?.image_url}
                      alt={product?.code_name}
                      className={`tw-h-36 tw-object-cover tw-rounded-lg `}
                    />
                  </div>

                  <p
                    className={`tw-text-left tw-text-xl tw-mt-2 ${
                      selectedProduct === product?.code_name
                        ? "tw-font-satoshiBlack tw-text-black"
                        : ""
                    }`}
                  >
                    {product?.code_name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="tw-mb-6 tw-grid tw-grid-cols-2 tw-gap-4">
          <JavInput
            title={"Quantity (KG)"}
            type={"number"}
            name={"weight"}
            value={form?.weight}
            onChange={handleChanges}
            placeholder={"eg 15Kg"}
            className="tw-text-xs"
          />
          <JavInput
            title={"Bags"}
            type={"number"}
            name={"bags"}
            value={form?.package}
            onChange={handleChanges}
            placeholder={"eg 15 bags"}
          />
        </div>

        <div className="tw-mb-6 tw-grid tw-grid-cols-2 tw-gap-4">
          <JavInput
            title={"Unit Price"}
            type={"number"}
            name={"unit_price_ncy"}
            value={form?.unit_price_ncy}
            onChange={handleChanges}
            placeholder={"eg $12"}
          />

          <JavInput
            disabled={true}
            title={"Total Price"}
            type={"number"}
            onChange={handleChanges}
            value={form?.unit_price_ncy * form?.weight}
            name={"total_price"}
          />
        </div>

        <div className="tw-mb-6 tw-grid tw-grid-cols-2 tw-gap-4">
          <div className="tw-mb-6 tw-mt-1">
            <div className={"tw-col-span-2 tw-mt-2"}>
              <JavFormSelect
                title={`Warehouse`}
                placeholder={"Select warehouse "}
                items={[
                  {
                    title: "Javolin Main Warehouse",
                  },
                  {
                    title: "Jav Test Warehouse",
                  },
                ]}
                position={"bottom"}
                onChange={(item) => {}}
              />
            </div>
          </div>

          <div className="tw-mb-4 tw-mt-3">
            <h3
              htmlFor="photos"
              className="tw-block tw-font-satoshi tw-text-2xl tw-text-black tw-mb-2"
            >
              Photos
            </h3>
            <input
              type="file"
              id="photos"
              className="tw-w-full tw-p-2 tw-border tw-rounded-sm tw-h-16 tw-text-lg tw-items-center"
              multiple
              onClick={onUploadImageClicked}
              accept="image/*"
              capture={"environment"}
              onChange={onImageSelected}
            />
          </div>
        </div>

        <JavButton
          title={"Add inventory"}
          textColor={"tw-text-white"}
          // bgColor={"tw-bg-green-600"}
          className="tw-h-14 tw-text-xl tw-rounded-full"
          isLoading={createNewItem.loading}
          onClick={onCreateNewItemClicked}
        />
      </div>
    </Dialog>
  );
}
