import JavButton from "../../Shared/Components/Forms/JavButton";
import JavolinWalletCard from "../../Shared/Components/WalletCard/JavolinWalletCard";
import {PlusCircleOutlined} from "@ant-design/icons";
import LoanTransaction from "../Transaction/LoanTransaction";
import {useSelector} from "react-redux";
import {useEffect} from "react";

export default function ApplicationWallet() {

    const wallets = useSelector((state) => state?.resources?.wallets)

    useEffect(() => {
        console.log("wallets",wallets)
    }, [wallets]);
    return (
        <div>


            <div className={"tw-flex tw-items-center tw-justify-between"}>

                <h4 className={"tw-font-satoshiBold tw-text-lg tw-text-gray-800"}>Active Wallets</h4>

                <JavButton
                    title={"Add new Wallet"}
                    textColor={"tw-text-white"}
                />

            </div>

            <div className={"tw-my-3 tw-grid tw-gap-5 tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3"}>

                {
                    Object.values(wallets).map(wallet => {
                        return (
                            <div className={"tw-bg-white tw-rounded-lg  tw-px-5 tw-pb-3 tw-pt-8"}>
                                <JavolinWalletCard
                                    height={"tw-h-48"}
                                    balance={wallet?.current_balance}
                                    currency={wallet?.currency?.ISO}
                                    accountName={wallet?.name}
                                    accountNumber={wallet?.account_number}
                                />
                            </div>
                        )
                    })
                }

                <div className={"tw-bg-white tw-rounded-lg  tw-px-5 tw-pb-3 tw-pt-8"}>
                    <div
                        className={"tw-bg-green-50  tw-h-48 tw-rounded-lg tw-border-2 tw-border-green-400 tw-border-dashed tw-flex tw-items-center tw-justify-center"}>

                        <div className={"tw-flex tw-items-center"}>
                            <PlusCircleOutlined style={{color: "#16a34a", fontSize: "1.2em"}}/>
                            <span className={"tw-font-satoshiBold tw-text-sm tw-text-green-600 tw-mx-2"}>Add New</span>
                        </div>
                    </div>
                </div>

            </div>


            <LoanTransaction title={"Recent Transactions"} pageNumbers={[5]}/>


        </div>
    )
}