import { Layout, ConfigProvider, Table, Row, Tag, Dropdown, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Edit, Edit2Icon, Trash, TrashIcon } from "../../Shared/Components/JavIcons";
import Styles from "./UserMgt.module.css"
import { MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import AddUser from "./AddUser";
import AccessControl from "../../Shared/Components/AccessControl/AccessControl";
import { approveUser, declineUser, deleteUser, getRoles, getUsers } from "./duck/action";
import EditUser from "./EditUser";
import UserList from "./UserList";



const UserManagement = () => {
    const dispatch = useDispatch()
    const rLoading = useSelector((state) => state?.userMgt?.addingUser) 
    const approavingLoading = useSelector((state) => state?.userMgt?.approvingUser)
    const declineLoading = useSelector((state) => state?.userMgt?.decliningUser)


    useEffect(() => {
        dispatch(getUsers())
        dispatch(getRoles())
    }, [dispatch, rLoading, declineLoading, approavingLoading])

   


    const text = useSelector((state) => state?.language)
    const { Content } = Layout;
    const [editUser, setEditUser] = useState(false)
    const users = useSelector((state) => state?.userMgt?.users)



    const handleEdit = (user) => {
        setEditUser(user)
        setIsEditModalVisible(true)
    }

    const handleApproveUser = (user)=>{
        dispatch(approveUser(user.id))
    }

 

    const handleDelete = (user) => {
        dispatch(deleteUser(user.id) )
    }





    const [isVisible, setIsModalVisible] = useState(false)
    const [isEditVisible, setIsEditModalVisible] = useState(false)



    return (
        <>
            <Content
                style={{
                    minHeight: 300,
                    padding: "",
                    margin: "0",
                }}
            >
                <div>
                    
                    <AccessControl
                        allowedPermissions={["CREATE_BUSINESS_USER"]}
                        renderNoAccess={''}
                    >
                        <AddUser editUser={editUser} isVisible={isVisible} setEditUser={setEditUser} setIsModalVisible={setIsModalVisible} />
                        <EditUser editUser={editUser} isVisible={isEditVisible} setEditUser={setEditUser} setIsModalVisible={setIsEditModalVisible} />
                    </AccessControl>
                </div>
                <div className="tw-mb-2"></div>
                <UserList />
               
            </Content>
        </>
    );
};

export default UserManagement;
