/* eslint-disable array-callback-return */
import { Layout, Col, Input, InputNumber, Select, Form, Button, Row, Alert, } from 'antd';
import React, { useEffect, useState } from 'react';
import Styles from "./../TransferMoney/TransferMoney.module.css"
import { useDispatch, useSelector } from 'react-redux';
import PassCode from "../../Shared/Components/PassCode"
import { showErrorNotification } from '../../Shared/actions/alert.actions';

import {
    PlusCircleOutlined
} from '@ant-design/icons';

import "../../Shared/Components/Accounts/JavolinAccounts.css"
import JavContentTitle from '../../Shared/Components/JavContentTitle';
import { useHistory } from 'react-router';
import { getWallets } from '../../Shared/Components/duck/action';
import {getBeneficiaries} from "../TransferMoney/duck/action";
import {requestLoan} from "./duck/action";
import BankWireMemoPopUp from "../TransferMoney/BankWireMemoPopUp";
import AddBenefeciaryPopUp from "../TransferMoney/AddBenefeciaryPopUp";
import LoanReviewPopUp from "./LoanReviewPopup";

const { Option } = Select;



const RequestLoan = (props) => {

    const state = props?.location?.state
    console.log("state: ", state )
    const [form] = Form.useForm()
    const [passcode, setPasscode] = useState(false)
    const [details, setDetails] = useState("")
    const [submitDetails, setSubDetails] = useState("")
    const [disableContinue, setDisCount] = useState(true)
    const bookingRate = useSelector((state) => state?.transfer?.bookingRate)
    const checkingRate = useSelector((state) => state?.transfer?.gettingRate)
    const instructingPayment = useSelector((state) => state?.transfer?.instructingPayment)
    const defaultValues = useSelector((state) => state?.transfer?.defaultValues)
    let defaultWallet = useSelector((state) => state?.user?.default_savings_wallet)
    let wallets = useSelector((state) => state?.resources?.wallets)
    const [review, setReview] = useState(false)
    const dispatch = useDispatch()
    const [checkDisabled, setCheckDisabled] = useState(true)
    const [showTimer, setShowTimer] = useState(false)
    const [sourceWallet, setsourcewallet] = useState(Object.keys(wallets).length === 1 ? wallets[defaultWallet?.id] : '')
    const [settlementDetails, setSettlementDetails] = useState()
    let currencies = useSelector((state) => state?.resources?.defaultCurrencies)
    const feeLoading = useSelector((state) => state?.transfer?.gettingFee)
    const text = useSelector((state) => state.language)

    const bene = useSelector((state) => state?.transfer?.beneficiaries)
    const beneficiaries = bene ? bene : {}
    const trucks = [
        {
            id: 1,
            title: "1 Truck",
            amount: 138000,
            interest: 0
        },
        {
            id: 2,
            title: "2 Truck",
            amount: 138000 * 2,
            interest: 0
        },
        {
            id: 3,
            title: "3 Truck",
            amount: 138000 * 3,
            interest: 0
        },
        {
            id: 4,
            title: "4 Truck",
            amount: 138000 * 4,
            interest: 0
        },
        {
            id: 5,
            title: "5 Truck",
            amount: 138000 * 5,
            interest: 0
        }
    ]
    const history = useHistory()
    const [bene_detatils, set_beneDetails] = useState(state?.currency)
    const [disableAmount, setDisableAmount] = useState(true)
    const [disableBeneAmount, setDisableBeneAmount] = useState(!!state)

    const [visibleBankMemo, setVisibleBankMemo] = useState(false)
    const [selectedLoanType,setSelectedLoanType] = useState(null);
    const [isVisible, setVisible] = useState()


    useEffect(()=>{
        dispatch(getBeneficiaries())
        dispatch(getWallets())
        // dispatch(getPendingTransactions())
    },[dispatch])

    const onFinish = (values) => {

        let selectedTruck = trucks.filter(item => item.id === values.trucks)[0];
        values = {
            ...values,
            ...defaultValues,
            beneficiary: beneficiaries[values.beneficiary_account],
            truck:  selectedTruck,
            currency: "GHS"
        }
        console.log("values",values);
        setDetails(values);
        setSubDetails({
            loan_type: values?.loan_type,
            beneficiary_id: values.beneficiary_account,
            amount: values?.loan_type === "1" ? selectedTruck?.amount : values.amount,
            meta: {
                truck: selectedTruck
            },
            truck_id: selectedTruck?.id,
            currency: "GHS"
        })
        setReview(true);


    }
    const sel = { title: "selectedCard", lineHeight: "cardLeftHemSelected" }
    const handleAmountChange = (e) => {
        let values = {
            ...e,
            beneficiary: beneficiaries[e.beneficiary_account]
        }
        console.log(beneficiaries);
        console.log(values);
    }

    const handleFormChange = () => {
        setSelectedLoanType(form.getFieldValue("loan_type"))
        const fieldVals = form.getFieldValue('amount')
        const beneField = form.getFieldValue('beneficiary')
        let disabled = (fieldVals === undefined || fieldVals === '') && (beneField === undefined || beneField === '')
        setCheckDisabled(disabled)
    }

    console.log("getBeneValue: ", form.getFieldValue("beneficiary_account"))

    const handleChangeBene = (e) => {
        if (e === "new") {
            form.setFieldsValue({"beneficiary_account":""})
            setVisible(true)
        }
        else {
            set_beneDetails(beneficiaries[e])
            setDisableBeneAmount(false)
        }
    }

    const handleLoanAmount = (e) => {
        console.log("handleLoanAmount",e);
    }

    const handleAccountChange = () => {
        setDisableAmount(false)
    }

    const reset = () => {
        form.setFieldsValue({
            beneficiary: '',
            amount: '',
            rate: ''
        })
        setDisCount(true)
        setCheckDisabled(true)
        setShowTimer(false)
    }

    const expireRate = () => {
        reset()
        dispatch(showErrorNotification('Rate has expired'))
    }

    const { Content } = Layout;
    return (
        <Content>
            <div className={Styles.card}>
                <div className={Styles.cardTitle}>
                    <div>
                        <span className={Styles.titleCard}>{text["JAVOLIN_LOAN_INSTRUCTION"]}</span>
                    </div>
                </div>
                <div className={Styles.cardContainer}>
                    <div style={{ width: "100%" }} className={Styles.cardContent}>
                        <div style={{ padding: "0 3em", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                            <>
                                <LoanReviewPopUp openWireInfo={setVisibleBankMemo} setReview={setReview} details={details} setPasscode={setPasscode} showReview={review} />
                                <BankWireMemoPopUp currencyCode={"GHS"} isVisible={visibleBankMemo} setVisible= {setVisibleBankMemo } />
                                <AddBenefeciaryPopUp isVisible={isVisible} setVisible={setVisible} />
                                <PassCode
                                    isPassCodeVisible={passcode}
                                    setPassCodeVisible={setPasscode}
                                    details={submitDetails}
                                    form={form}
                                    action={requestLoan}
                                    buttonloading={instructingPayment}
                                />
                                <Form
                                    form={form}
                                    layout="vertical"
                                    name="form_in_modal"
                                    style={{ width: "100%" }}
                                    onFinish={onFinish}
                                    onFieldsChange={handleFormChange}
                                    initialValues={{
                                        accountNumber: state?.accountNumber,
                                        beneficiary_account: state?.id,
                                        // from_account: Object?.keys(wallets)?.length === 1 ? defaultWallet?.id : '',
                                        amount: 0,
                                        beneficiary: Number(state?.rate?.recipient.amount).toFixed(2),
                                        rate: `${Number(state?.rate?.javolin_rate[1]).toFixed(4)} / ${Number(state?.rate?.javolin_rate[0]).toFixed(4)}`
                                    }}
                                >

                                    <JavContentTitle title={text["Loan Details"]} />

                                    <Row style={{ marginTop: "1em" }} gutter={[32, 16]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item label={text["Select Loan Type"]}>
                                                <Form.Item name={"loan_type"}
                                                    noStyle
                                                >
                                                    <Select style={{ width: "100%" }} size="large" placeholder={"Select Loan Type"}>
                                                        <Option value="1">{"Local Agricultural Loan"}</Option>
                                                        <Option value="2">{"International Agricultural Loan"}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>



                                    <JavContentTitle title={text["Beneficiary Details"]} />

                                    <Row style={{ marginTop: "1em" }} gutter={[32, 16]}>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <Form.Item
                                                label={text["Send to"]}>
                                                <Form.Item name={"bank"} noStyle
                                                >
                                                    <Select style={{ width: "100%" }} size="large" defaultValue={'bank'}>
                                                        <Option value="bank">{text["Bank"]}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <Form.Item label={text["Beneficiary"]}>
                                                <Form.Item
                                                    name={"beneficiary_account"}
                                                    noStyle
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Select onChange={handleChangeBene} size="large" className="c_select" style={{ width: "100%" }}
                                                            placeholder={`${text["JAVOLIN_LOAN_BENEFICIARY_SELECTION_PLACEHOLDER"]} (${state?.currency})`}
                                                    >

                                                        <Option  value='new'   > <PlusCircleOutlined /> {text['Add New Beneficiary']}</Option>
                                                        {Object.values(beneficiaries)?.map((bene) => {
                                                            return (
                                                                <Option disabled={bene?.currency !== state?.currency}  value={bene?.id} key={bene?.id}>
                                                                    <div className={`cardTile ${sel.title}`}>
                                                                        <div className="cardLeftHem">
                                                                            <div className={`cardName ${sel.lineHeight}`} >{bene?.name}</div>
                                                                            <div className={`currencyName ${sel.lineHeight}`}  >{`${text["currency"]}: ${bene?.currency}`}</div>
                                                                        </div>
                                                                        <div className="cardRightHem">
                                                                            <div className={`accountNumber ${sel.lineHeight}`}  >
                                                                                {bene?.account_number ? bene?.account_number : ""}
                                                                            </div>
                                                                            <div className={`bankName ${sel.lineHeight}`}  >{bene?.bank_name}</div>
                                                                        </div>
                                                                    </div>
                                                                </Option>
                                                            )
                                                            // }
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>

                                        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <Form.Item label="Transaction Type">
                                                <Form.Item
                                                    noStyle
                                                >
                                                    <Select style={{ width: "100%" }} size="large" defaultValue={'international'} placeholder="Select type">
                                                        <Option value="international">International</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Form.Item>

                                        </Col> */}

                                    </Row>

                                    {/*<Alert*/}
                                    {/*    message={`${text["You are seeking to sell"]} ${state?.rate?.sender.currency} ${text["hence the your source account should be a"]} ${state?.rate?.sender.currency} ${text["account"]}` }*/}
                                    {/*    type="warning"*/}
                                    {/*    closable*/}
                                    {/*    style={{fontSize: "15px", margin:"1em 0"}}*/}
                                    {/*/>*/}
                                    <JavContentTitle title="Loan amount details" />

                                    {
                                        selectedLoanType === "1" &&
                                        <Row style={{ marginTop: "1em" }} gutter={[32, 16]}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Form.Item label={"Loan Amount"}>
                                                    <Form.Item
                                                        name={"trucks"}
                                                        noStyle
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Select onChange={handleLoanAmount} size="large" className="c_select" style={{ width: "100%" }}
                                                                placeholder={`Choose Trucks (Amount) (${state?.currency})`}
                                                        >
                                                            { trucks?.map((bene) => {
                                                                return (
                                                                    <Option value={bene?.id} key={bene?.id}>
                                                                        <div className={`cardTile ${bene.title}`}>
                                                                            <div className="cardLeftHem">
                                                                                <div className={`cardName ${sel.lineHeight}`} >{ bene.title }</div>
                                                                                <div className={`currencyName ${sel.lineHeight}`} >{` Amount: GHS${Number(bene?.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `}</div>
                                                                            </div>
                                                                            <div className="cardRightHem">
                                                                                <div className={`accountNumber ${sel.lineHeight}`}  >
                                                                                    {bene?.account_number ? bene?.account_number : ""}
                                                                                </div>
                                                                                <div className={`bankName ${sel.lineHeight}`}  >{bene?.bank_name}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Option>
                                                                )
                                                                // }
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        selectedLoanType === "2" &&

                                        <Row style={{ marginTop: "1em" }} gutter={[32, 16]}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Form.Item label={"Enter Amount (GHS)"}>
                                                    <Form.Item
                                                        name={"amount"}
                                                        noStyle
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            size="large"
                                                            style={{width: "100%"}}
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        />

                                                    </Form.Item>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }


                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            type="primary"
                                            shape='round'
                                            style={{ width: "400px" }}
                                            block
                                            htmlType="submit"
                                            size="large"
                                            loading={feeLoading}
                                        >
                                            {text["Continue"]}
                                        </Button>
                                    </div>


                                    <Form.Item
                                        name="quote_id"
                                    >
                                        <Input hidden />
                                    </Form.Item>


                                </Form>

                            </>

                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
};

export default RequestLoan