import {LeftArrowLinear} from "../../../../Shared/Components/JavIcons";
import React, {useEffect, useRef} from "react";
import JavTab from "../../../../Shared/Components/Forms/Tabs";
import LoanDetailSummary from "./Tabs/LoanDetailSummary";
import LoanDetailWaybill from "./Tabs/LoanDetailWaybill";
import LoanDetailsActivityLog from "./Tabs/LoanDetailsActivityLog";
import {currencyFormat} from "../../../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {fetchLoanDetails} from "../../duck/action";
import LoanDetailAnalytics from "./Tabs/LoanDetailAnalytics";
import {useHistory} from "react-router-dom";

export default function LoanDetails(props) {

    const history = useHistory()
    const dispatch = useDispatch();
    const tabRef = useRef();
    const tradingHub = useSelector((state) => state.tradingHub)


    useEffect(() => {
        const loanReference = window.location.href.split("/")?.[5]
        dispatch(fetchLoanDetails(loanReference))
    }, []);


    return (
        <div className={""}>

            <div onClick={() => history.goBack()} className={"tw-flex tw-cursor-pointer tw-items-center tw-font-satoshiBold"}>
                <LeftArrowLinear className={"tw-h-5 tw-stroke-current tw-stroke-1 "}  />
                <span className={"tw-mx-2"}> Back </span>
            </div>


            <div className={"tw-flex tw-flex-col md:tw-flex-row tw-py-2 md:tw-py-6 tw-bg-white tw-border tw-my-3 md:tw-my-5 tw-px-5 tw-w-full" +
                " tw-items-start tw-justify-between tw-rounded-xl"}>

                <div className={"tw-flex tw-flex-col md:tw-flex-row"}>

                    <div className={"tw-flex tw-flex-col"}>
                        <span className={"tw-font-satoshi tw-text-sm"}>Loan amount request</span>
                        <span
                            className={"tw-font-satoshiBlack tw-text-xl tw-mt-2 tw-text-black"}>{tradingHub?.fetch_loan_details?.data?.currency} {currencyFormat(tradingHub?.fetch_loan_details?.data?.amount)}</span>
                    </div>

                    <div className={"tw-flex md:tw-mx-20 tw-flex-col"}>
                        <span className={"tw-font-satoshi tw-text-sm"}>Total Expected Revenue</span>
                        <span
                            className={"tw-font-satoshiBlack tw-text-xl tw-mt-2 tw-text-black"}>{tradingHub?.fetch_loan_details?.data?.currency} {currencyFormat(tradingHub?.fetch_loan_details?.data?.expected_total_javolin_profit)}</span>
                    </div>

                    <div className={"tw-flex tw-mt-5 md:tw-mt-0 tw-flex-col"}>
                        <span className={"tw-font-satoshi tw-text-sm"}>Loan request number</span>
                        <span
                            className={"tw-font-satoshiMedium tw-text-xl tw-mt-2 tw-text-black"}>{tradingHub?.fetch_loan_details?.data?.reference}</span>
                    </div>

                </div>


                <span
                    className={"tw-text-green-400 tw-mt-5 md:tw-mt-0 tw-block tw-bg-jav-card-100 tw-p-2 tw-rounded-xl"}>
                    {tradingHub?.fetch_loan_details?.data?.status}
                </span>

            </div>


            <div className={"tw-flex md:tw-py-6 tw-bg-white tw-border tw-my-5 md:tw-px-5 tw-w-full" +
                " tw-items-start tw-justify-between tw-rounded-xl "}>
                <JavTab ref={tabRef} className={"tw-w-full"}>
                    <LoanDetailSummary data={tradingHub?.fetch_loan_details?.data} title={"Summary"}/>
                    <LoanDetailWaybill data={tradingHub?.fetch_loan_details?.data} title={"WayBill + Warehouse"}/>
                    <LoanDetailsActivityLog data={tradingHub?.fetch_loan_details?.data} title={"Activity Log"}/>
                    <LoanDetailAnalytics data={tradingHub?.fetch_loan_details?.data} title={"Analytics"}/>
                </JavTab>

            </div>


        </div>
    )
}