import JavButton from "../../../../Shared/Components/Forms/JavButton";
import JavTab from "../../../../Shared/Components/Forms/Tabs";
import LoanContractDetailOverview from "./Tabs/LoanContractDetailOverview";
import LoanContractDisbursementDialog from "./LoanContractDisbursementDialog";
import LoanContractDisbursementConsignmentTab
    from "./LoanContractDisbursementDialog/Tabs/LoanContractDisbursementConsignmentTab";
import LoanContractDisbursementSuppliersList
    from "./LoanContractDisbursementDialog/Tabs/LoanContractDisbursementSuppliersList";
import LoanContractDisbursementBeneficiary
    from "./LoanContractDisbursementDialog/Tabs/LoanContractDisbursementBeneficiary";
import LoanContractDisbursementSummaryReview
    from "./LoanContractDisbursementDialog/Tabs/LoanContractDisbursementSummaryReview";
import React, {useEffect, useRef, useState} from "react";
import LoanContractDetailStockManagement from "./Tabs/LoanContractDetailStockManagement";
import {useDispatch, useSelector} from "react-redux";
import {fetchLoanContractDetails} from "../../duck/action";
import {currencyFormat, downloadFile, makeJavolinRequest} from "../../../../helpers/utils";
import JavConfirmationPin from "../../../../Shared/Components/Forms/JavConfirmationPin";
import XIcon from "../../../../Shared/Components/Icons/XIcon";
import {showErrorAlert, showSuccessNotification} from "../../../../Shared/actions/alert.actions";
import LoanContractDetailBlInformation from "./Tabs/LoanContractDetailBlInformation";
import LoanContractDetailActivityLog from "./Tabs/LoanContractDetailActivityLog";
import LoanContractDetailParticulars from "./Tabs/LoanContractDetailParticulars";
import LoanContractDetailDocument from "./Tabs/LoanContractDetailDocument";

export default function LoanContractDetails() {

    const [openContractDisbursement,setOpenContractDisbursement] = useState(false);
    const [form,setForm] = useState();
    const dispatch = useDispatch();
    const overviewRef = useRef();
    const tradingHub = useSelector((state) => state.tradingHub)
    const [openConfirmationPinDialog,setOpenConfirmationPinDialog] = useState(false);
    const [requestLoanLoading,setRequestLoanLoading] = useState(false);
    const [downloadReport,setDownloadReport] = useState(false);


    useEffect(() => {
        const contractReference = window.location.href.split("/")?.[6]
        dispatch(fetchLoanContractDetails(contractReference))
    }, []);

    const onLoanRequestFormFilled = (data) => {
        console.log("data",data);
        setOpenContractDisbursement(false)
        setOpenConfirmationPinDialog(true);
        setForm(data);
    }

    const requestLoan = (data) => {
        setRequestLoanLoading(true);
        makeJavolinRequest({
            path: '/business/loans',
            method: "POST",
            data: data
        },null,null,(data) => {
            setRequestLoanLoading(false);
            setOpenConfirmationPinDialog(false);
            showSuccessNotification("Created successfully")
            alert("Loan Created Successfully");
            overviewRef?.current?.reloadLoanTable();
            console.log("data",data)
        },(error) => {
            setRequestLoanLoading(false);
            alert("Error: "+error);
            showErrorAlert("Error: "  + error,"FAILED", (<XIcon />))
        })
    }

    const downloadContractReport = () => {
        setDownloadReport(true);
        downloadFile(`/business/loan-contracts/${tradingHub?.fetch_loan_contract_details?.data?.id}/report`).then(r => {
            setDownloadReport(false);
        })
    }

    return (
        <div>

            <div className={"tw-bg-white tw-flex tw-flex-col md:tw-flex-row tw-p-4 tw-justify-between tw-rounded-lg tw-border"}>

                <div className={"tw-flex tw-flex-col md:tw-flex-row"}>

                    <div className={"tw-flex tw-flex-col"}>
                        <span className={"tw-font-satoshi"}>Total Tons</span>
                        <h3 className={"tw-font-satoshiBold tw-text-black"}>{tradingHub?.fetch_loan_contract_details?.data?.total_weight}</h3>
                    </div>

                    <div className={"tw-flex tw-flex-col md:tw-mx-10"}>
                        <span className={"tw-font-satoshi"}>Total Expected Revenue</span>
                        <h3 className={"tw-font-satoshiBold tw-text-black"}>
                            {tradingHub?.fetch_loan_contract_details?.data?.currency}
                            {currencyFormat(tradingHub?.fetch_loan_contract_details?.data?.total_expected_revenue)}
                        </h3>
                    </div>

                    <div className={"tw-flex tw-flex-col"}>
                        <span className={"tw-font-satoshi"}>Delivery Date</span>
                        <h3 className={"tw-font-satoshiBold tw-text-black"}>{tradingHub?.fetch_loan_contract_details?.data?.end_date}</h3>
                    </div>

                    <div className={"tw-flex md:tw-mx-10 tw-flex-col"}>
                        <span className={"tw-font-satoshi"}>Contract Number</span>
                        <h3 className={"tw-font-satoshiBold tw-text-black"}>{tradingHub?.fetch_loan_contract_details?.data?.reference}</h3>
                    </div>

                </div>

                <div className={"tw-flex md:tw-flex-col tw-items-center tw-justify-start"}>

                    <JavButton
                        title={"Create Disbursement"}
                        textColor={"tw-text-white"}
                        onClick={() => {
                            setOpenContractDisbursement(true);
                        }}
                    />

                    <JavButton
                        className={"md:tw-mt-2 tw-mx-5 md:tw-mx-0 tw-border tw-border-blue-800"}
                        bgColor={"tw-bg-white"}
                        title={"Show Contract Report"}
                        textColor={"tw-text-black"}
                        onClick={() => {
                            downloadContractReport();
                        }}
                    />
                </div>


            </div>


            <div className={"tw-mt-4"}>
                <JavTab>
                    <LoanContractDetailOverview ref={overviewRef} data={tradingHub?.fetch_loan_contract_details?.data} title={"Overview"}/>
                    <LoanContractDetailParticulars contract={tradingHub?.fetch_loan_contract_details?.data} title={"Stock"}/>
                    <LoanContractDetailActivityLog contract={tradingHub?.fetch_loan_contract_details?.data} title={"Activity Log"} />
                    <LoanContractDetailBlInformation title={"BL Information"}/>
                    <LoanContractDetailDocument title={"Documents"}/>
                </JavTab>
            </div>

            <LoanContractDisbursementDialog
                open={openContractDisbursement}
                contract={tradingHub?.fetch_loan_contract_details?.data}
                onCloseClicked={() => {
                    setOpenContractDisbursement(false)
                }}>
                <LoanContractDisbursementConsignmentTab index={0} title={"Consignment Info"}/>
                <LoanContractDisbursementSuppliersList index={1} title={"Supplier Info"}/>
                <LoanContractDisbursementBeneficiary index={2} title={"Beneficiary"}/>
                <LoanContractDisbursementSummaryReview index={3} title={"Review & Confirm"} onFinish={onLoanRequestFormFilled}/>
            </LoanContractDisbursementDialog>


            <JavConfirmationPin
                open={openConfirmationPinDialog}
                confirmButtonLoading={requestLoanLoading}
                onConfirmClicked={(pin) => {
                    requestLoan({...form,pin: pin})
                }}
                onCloseClicked={() => {
                    setOpenConfirmationPinDialog(false)
                }}
            />


        </div>
    )
}