import {
    ERROR_REQUEST_LOAN,
    FETCH_LOANS,
    LOANS_ERROR,
    LOANS_SUCCESS,
    REQUEST_REQUEST_LOAN,
    SUCCESS_REQUEST_LOAN
} from "./types";


const INITIAL_STATE = {
    fetch: {
        success: false,
        loading: false,
        error: null,
        data: []
    },
    requestLoan: {
        success: false,
        loading: false,
        error: null,
        data: []
    }
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_LOANS:
            return {
                ...state,
                fetch: {
                    ...state.fetch,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case LOANS_SUCCESS:
            return {
                ...state,
                fetch: {
                    ...state.fetch,
                    success: true,
                    loading: false,
                    data: action?.payload
                }
            }
        case LOANS_ERROR:
            return {
                ...state,
                fetch: {
                    ...state.fetch,
                    success: false,
                    loading: false,
                    error: action?.payload
                }
            }

        case REQUEST_REQUEST_LOAN:
            return {
                ...state,
                requestLoan: {
                    ...state.requestLoan,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case SUCCESS_REQUEST_LOAN:
            return {
                ...state,
                requestLoan: {
                    ...state.requestLoan,
                    success: true,
                    loading: false,
                    data: action?.payload
                }
            }
        case ERROR_REQUEST_LOAN:
            return {
                ...state,
                requestLoan: {
                    ...state.requestLoan,
                    success: false,
                    loading: false,
                    error: action?.payload
                }
            }




        default:
            return state;

    }

}