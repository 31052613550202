import { Layout, Col, Table, Tag, DatePicker, Card } from 'antd';
import moment from 'moment';
import { MomentTimezone } from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { sortListByDate, isEmpty, statusTagColor, getAbbrUserZone, getTimeUserZone, currencyFormat } from "../../helpers/utils"
import Circle from '../../Shared/Components/Circle/Circle';
import { CreditArrow, DebitArrow } from '../../Shared/Components/JavIcons';
import { getTransactions, getTransactionsByDate } from './duck/action';





const Savings = () => {
    const text = useSelector((state) => state?.language)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getTransactions())
    }, [dispatch])
    const columns = [
        {
            title: text.TRANSACTION_TYPE,
            dataIndex: "type",
            key: "type",
            render: (type) => {
                return (

                    <span >
                        {type?.module}

                    </span>

                );
            },
        },


        {
            title: text.AMOUNT,
            dataIndex: "amount",
            key: "amount",

        },
        {
            title: text.STATUS,
            dataIndex: "status",
            render: (status) => {
                return (
                    <>
                        <Tag color={statusTagColor(status)}>{status}</Tag>
                    </>
                );
            },
        },

        {
            title: text.DATE,
            key: "date",
            dataIndex: "date",
            responsive: ['lg'],
        },
        {
            title: text.REFERENCE,
            dataIndex: "reference",
            key: "reference",
            responsive: ['lg'],
        }

    ];

    const [filteredTranx, setFilteredTranx] = useState()
    const loading = useSelector((state)=> state?.savings?.gettingTransactions)

    const handleDateChange = (value, dateString)=>{

        const details = {
            start_date : dateString[0],
            end_date : dateString[1]
        }


        if(dateString && dateString[0] && dateString[1]){
            dispatch(getTransactionsByDate(details)).then((res)=>{
                setFilteredTranx(res)
            })
        }

        if(dateString[0] === '' && dateString[1] === ''){
            setFilteredTranx(false)
        }
        
    }

    let appData = useSelector((state) => state?.savings?.transactions)

    appData = filteredTranx ? filteredTranx : appData

    
    let data = !isEmpty(appData)
        ? Object.values(appData).map((transaction) => {
            return {
                key: transaction.id,
                reference: transaction.reference,
                amount: `${transaction?.currency?.ISO ? transaction?.currency?.ISO : "GHS"} ${ currencyFormat(transaction?.amountAndFee)}`,
                date: new Date(transaction.created_at).toLocaleString('en-GB'),
                status: transaction.status,
                type: { title: transaction.transaction_type, module: transaction.module.replaceAll("_", " ") }

            };
        })
        : [];
    data = sortListByDate(data)
    // const data = []


    const { RangePicker } = DatePicker;
    const { Content } = Layout

    

    return (

        <>
            <Col
                xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card>
                    <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "1em" }}>
                        <RangePicker onCalendarChange={handleDateChange} />
                    </div>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={{ pageSize: 50 }}
                        scroll={{ y: 600 }}
                        loading={loading}
                    />
                </Card>
            </Col>
        </>
    )
};

export default Savings