import SearchIcon from "../Icons/SearchIcon";
import {forwardRef, useRef} from "react";
import {LoadingIcon} from "../JavIcons";

function JavInput(props,ref) {

    const text = useRef();

    return (
        <div className={`tw-flex ${props.isColumn ? 'tw-flex-row' : 'tw-flex-col'} ${props.className}`}>

            <h3 className={`tw-text-[30px] ${props.isColumn ? 'tw-w-2/5 tw-bg-gray-100 dark:tw-bg-[#242A38] tw-flex tw-items-center tw-px-2' : ''} tw-font-satoshi tw-text-black`}>{props.title}</h3>

            <div className={`tw-flex ${props.isColumn ? 'tw-w-3/5' : ''}`}>

                <input onChange={props.onChange}
                       onFocus={props.onFocus}
                       value={props.value}
                       className={`tw-outline-none tw-font-satoshi tw-text-black tw-flex-1 tw-h-14 ${props.height} ${props.hideBorder ? '' : 'tw-border tw-border-gray-300 focus-within:tw-border-blue-500'} 
                                    dark:tw-bg-[#242A38] tw-rounded-md tw-px-1 dark:tw-text-white tw-placeholder-gray-600 tw-pl-3 tw-text-xl`}
                       placeholder={props.placeholder}
                       type={props.type}
                       name={props.name}
                       ref={ref}
                       disabled={props.disabled}
                       readOnly={props.readOnly}
                />

                <div className={`tw-w-12 tw-flex tw-items-center tw-justify-center
                                tw-bg-white tw-border tw-cursor-pointer 
                                tw-rounded tw-mx-1 ${props.hasRightButton ? '' : 'tw-hidden'}`}
                    onClick={() => props.onRightButtonClicked(text.current)}
                >
                    {
                        props.isLoading ?
                            <LoadingIcon
                                className={`tw-animate-spin tw-h-5 tw-w-5 tw-mx-2
                                            tw-fill-gray-600 ${props.isLoading ? 'tw-block' : 'tw-hidden'}`}
                            /> :
                            <SearchIcon/>
                    }
                </div>

            </div>

        </div>
    )
}

export default forwardRef(JavInput);