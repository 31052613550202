import { Button, Form, Input, Row, Col, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useRef, useState } from "react";
import { CheckIcon, XIcon } from "../../Shared/Components/JavIcons";
import Styles from "./UserMgt.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addingUser } from "./duck/action";
import Dialog from "../../Shared/Components/Dialog/Dialog";
import JavButton from "../../Shared/Components/Forms/JavButton";
import JavInput from "../../Shared/Components/Forms/JavInput";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";
import { makeJavolinRequest } from "../../helpers/utils";
import ConfirmDialog from "../../Shared/Components/Dialog/ConfirmDialog";
import { useAlert } from "../../Shared/Context/AlertContext";

const AddUser = ({ isVisible, setIsModalVisible }) => {
  const tableRef = useRef();
const { showAlert } = useAlert();
  const roles = useSelector((state) => state?.userMgt?.roles);
  const text = useSelector((state) => state?.language);
  const [isLoading, setRequestLoading] = useState(false);

  const isMobile = window.innerWidth <= 768;
  const dialogWidth = isMobile ? "100%" : "40%";

  const [form,setForm] = useState({
   full_name:"",
   email:"",
   phone_number:"",
   role_id:""
});


  let _roles = roles ? roles : {};


  const handleChanges = (event) => {
    const value = event.target.value;
    setForm({
        ...form,
        [event.target.name]: value
    })
}

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const addUserRequest = (data) => {
    setRequestLoading(true);
    makeJavolinRequest(
      {
        path: `/business/accounts`,
        method: "POST",
        data: data,
      },
      null,
      null,
      (data) => {
        handleCancel()
        setRequestLoading(false);
        showAlert("User Created Successfully","SUCCESS", (<CheckIcon />))
        tableRef?.current?.reloadTable()
        console.log("data", data);
      },
      (error) => {
        setRequestLoading(false);
        handleCancel()
        showAlert("Error: "+error,"FAILED", (<XIcon />))
        console.log("error", error);
      }
    );
  };


  return (
    <>
      <JavButton
        onClick={showModal}
        className="tw-px-5"
        textColor="tw-text-white"
      >
        {text["Add new user"]}
      </JavButton>

      <Dialog
        position={"top"}
        style={{ width: dialogWidth }}
        open={isVisible}
        onCloseClicked={handleCancel}
        title={"Add new user"}
        footer={false}
        closeIcon={
          <div className="circle-close">
            <XIcon width="1em" height="1em" />
          </div>
        }
        bodyStyle={{
          padding: 0,
          border: "16px 16px 0 0",
        }}
      >
        <Form
          layout="vertical"
          name="profile_form"
          style={{ width: "100%" }}
        >
          <div
            style={{
              padding: "20px 70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <JavInput
              title={text["Full Name"]}
              name={"full_name"}
              value={form.full_name}
              className="tw-w-full tw-mb-4"
              placeholder="Eg. Bismark Amo"
              onChange={handleChanges}
            />

            <JavInput
              title={text["Email"]}
              name={"email"}
              value={form.email}
              className="tw-w-full tw-mb-4"
              placeholder="Eg. abc@zenithbank.com"
              onChange={handleChanges}
            />

            <JavInput
              title={text["Phone Number"]}
              name={"phone_number"}
              value={form.phone_number}
              className="tw-w-full tw-mb-4"
              placeholder="Eg. 0541544404"
              onChange={handleChanges}
            />

         
            <JavFormSelect
              title={text["Role"]}
              position={"bottom"}
              className="tw-w-full tw-mb-4"
              placeholder={text["Select role"]}
              items={Object.values(_roles)?.map((role) => ({
                ...role,
                title: role.name,
              }))}
              onChange={(role) => {
                setForm({
                    ...form,
                    role_id:role.id
                })
              }}
            />

            <JavButton
              bgColor={""}
              textColor="tw-text-white"
              className={
                "tw-text-white tw-py-4 tw-text-lg tw-h-11 tw-w-full tw-cursor-pointer tw-mt-5"
              }
              isLoading={isLoading}
              onClick={() => {
                console.log("form===================", form)
                addUserRequest(form)
              }}
            >
              <span className="tw-pl-2"> {text["Add user"]}</span>
            </JavButton>
          </div>
        </Form>
      </Dialog>

     
    </>
  );
};

export default AddUser;
