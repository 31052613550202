import ExcelIcon from "../Icons/ExcelIcon";
import CSVIcon from "../Icons/CSVIcon";
import PDFIcon from "../Icons/PDFIcon";
import JavSelect from "../Forms/JavSelect";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDataForTable} from "./duck/action";
import ListIcon from "../Icons/ListIcon";
import {LoadingIcon} from "../JavIcons";
import JavButton from "../Forms/JavButton";
import * as PropTypes from "prop-types";
import {downloadFile, getParameterByName, getTableData, makeLinkReloadable} from "../../../helpers/utils";
import JavFilter from "../JavFilter";


const renderOptions = [{ name: "List", icon: ListIcon }, { name: "Chart", icon: PDFIcon } ];

function JavTabSwitch(props) {
    return null;
}

JavTabSwitch.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.any
};

function Table(props,ref) {

    const dispatch = useDispatch();
    const tableState = useSelector( (state) => state.table)
    const [tableData,setTableData] = useState([]);
    const [exportDownloading,setExportDownloading] = useState(false);
    const [renderOption,setRenderOption] = useState(renderOptions?.[0]);
    const [links,setLinks] = useState([]);
    const [config,setConfig] = useState({
        pageSize: props.pageNumbers ? props.pageNumbers[0] : 10,
        page: 1,
        link: props.link,
        pageNumbers: props.pageNumbers ? props.pageNumbers : [10, 25, 50,100, "All"]
    })
    const [tableSummary,setTableSummary] = useState("...");


    useImperativeHandle(ref,() => {
        return {
            isLoading: () => {
                return tableState.loading
            },
            reloadTable: (reloadLink) => {
                console.log("am here _"+reloadLink)
                if (!reloadLink) {
                    console.log("am here")
                    setConfig({
                        ...config,
                        link: makeLinkReloadable(config?.link)
                    })
                }
                else if (reloadLink && config) {
                    setConfig({
                        ...config,
                        link: reloadLink
                    });
                }
            },
        }
    },[tableState])



    useEffect(() => {
        if (config.link) {
            dispatch(getDataForTable({...config},props.tag))
        }
    },[config,props.currentVersion])

    useEffect(() => {
        if (props.fields) {
            if (tableState[props.tag]?.data) {
                setTableData(getTableData(tableState[props.tag].data?.data,props.fields));
                setLinks(tableState[props.tag]?.data?.links)
                setTableSummary(`${tableState[props.tag]?.data?.from || '0'} - ${tableState[props.tag]?.data?.to || '0'} of ${tableState[props.tag]?.data?.total}`)
                if (props.onLoadingDone) {
                    props.onLoadingDone();
                }
            }
        }
    },[tableState,props.dependencies])

    useEffect(() => {

    },[props.dependencies]);


    const onExportClicked = (fileType) => {
        if (!props.link) {
            alert(`Issue downloading ${fileType} content`);
            return;
        }
        setExportDownloading(fileType);
        downloadFile(props?.link,fileType).then(r => {
            setExportDownloading(null);
        })
    }


    return (
        <div className={"tw-rounded-md tw-mx-2 tw-font-satoshiMedium tw-my-2 tw-overflow-scroll  tw-relative"}>

            {
                !props.clientSide &&
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">

                    <span className={"tw-font-satoshiBold tw-text-lg"}>
                        {props?.title}
                        {props?.renderTitle && props?.renderTitle()}
                    </span>

                    <div className={"tw-flex tw-items-center"}>

                        {
                            props?.showFilter &&
                            <JavFilter/>
                        }

                        <JavButton onClick={() => onExportClicked('excel')}
                                   bgColor={"dark:tw-bg-[#242A38]"}
                                   textColor={"tw-text-gray-600"}
                                   className={"tw-h-8 tw-border tw-border-gray-300 dark:tw-border-gray-500"}
                                   title={"excel"}>
                            <ExcelIcon className={"tw-fill-current tw-text-green-800"}/>
                            <LoadingIcon className={`tw-animate-spin tw-h-5 tw-w-5 tw-mx-2
                                            tw-fill-gray-600 ${exportDownloading === 'excel' ? 'tw-block' : 'tw-hidden'}`}/>
                        </JavButton>

                        <JavButton onClick={() => onExportClicked('csv')}
                                   bgColor={"dark:tw-bg-[#242A38]"}
                                   textColor={"tw-text-gray-600"}
                                   className={"tw-h-8 tw-border tw-mx-2 tw-border-gray-300 dark:tw-border-gray-500"}
                                   title={"csv"}>
                            <CSVIcon className={"tw-w-4 tw-mx-2 tw-h-4 tw-fill-current tw-text-blue-600"} />
                            <LoadingIcon className={`tw-animate-spin tw-h-5 tw-w-5 tw-mx-2
                                            tw-fill-gray-600 ${exportDownloading === 'csv' ? 'tw-block' : 'tw-hidden'}`}/>
                        </JavButton>

                        <JavButton onClick={() => onExportClicked('pdf')}
                                   bgColor={"dark:tw-bg-[#242A38]"}
                                   textColor={"tw-text-gray-600"}
                                   className={"tw-h-8 tw-border tw-border-gray-300 dark:tw-border-gray-500"}
                                   title={"pdf"}>
                            <PDFIcon className={"tw-w-4 tw-mx-2 tw-h-4 tw-fill-current tw-text-red-600"}/>
                            <LoadingIcon className={`tw-animate-spin  tw-h-5 tw-w-5 tw-mx-2
                                            fill-gray-600 ${exportDownloading === 'pdf' ? 'tw-block' : 'tw-hidden'}`}/>
                        </JavButton>

                            {
                                props?.rightActions &&
                                <div className={"tw-ml-3"}>
                                    <div className={"tw-flex-1 tw-ml-1 tw-flex tw-items-center tw-justify-end"}>
                                        {
                                            props?.rightActions?.map(item => (
                                                item?.show &&
                                                <JavButton {...item} />
                                            ))
                                        }
                                    </div>
                                </div>

                            }




                    </div>

                </div>

            }

            {
                renderOption?.name.toLowerCase() === "chart" &&
                props?.chartsContent
            }

            {
                renderOption?.name.toLowerCase() === "list" &&
                <table className={`tw-w-full tw-my-4 tw-text-xs tw-table-auto tw-overflow-scroll `}>
                    <thead>
                    <tr className="tw-border dark:tw-border-gray-600 dark:tw-bg-[#242A38]
                               tw-text-left dark:tw-text-white tw-h-12 tw-rounded ">
                        {props.columns && props.columns.map( (column,index) => {
                            return (
                                <th key={index} className={"tw-text-center tw-font-satoshi tw-uppercase"}>
                                    { column}
                                </th>
                            )
                        })}
                    </tr>
                    </thead>

                    <tbody className={"dark:tw-bg-[#242A38] tw-text-gray-700 dark:tw-text-gray-200"}>
                    {
                        props.data && props.data.map((data,index) => {
                            return (
                                <tr key={index} className="tw-border tw-py-2 dark:tw-border-gray-600 tw-text-left tw-w-full tw-rounded ">
                                    {
                                        data.fields && data.fields.map((field,fieldIndex) => {
                                            if (field.render) {
                                                return field.render(field.content);
                                            }
                                            return (
                                                <td key={fieldIndex} className={"tw-text-center tw-py-3.5"}>
                                                    {field.title}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    {
                        props.data && props.data.length === 0 &&
                        <tr className={"tw-border"}>
                            <td colSpan={props.columns.length} className={"tw-text-center tw-py-4"}>
                                No Data
                            </td>
                        </tr>
                    }

                    {
                        tableData && tableData.map((data,index) => {
                            return (
                                <tr key={`${props?.tag}__${index}`} className="tw-border tw-text-sm tw-font-satoshiMedium dark:tw-border-gray-600 tw-text-left tw-h-12 tw-rounded ">
                                    {
                                        data.fields && data.fields.map((field,index2) => {
                                            if (field.render) {
                                                return field.render(field.content,index);
                                            }
                                            return (
                                                <td key={index2} className={"tw-text-center"}>
                                                    {field.title}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }

                    {/*Show Loading indicator for Table*/}
                    {
                        tableState[props?.tag]?.loading &&
                        <tr className={"tw-h-12 tw-absolute tw-top-1/2 tw-left-1/2"}>
                            <td colSpan={props.columns.length} className={"tw-w-full"}>
                                <div className={"tw-w-full tw-flex tw-flex-col tw-my-6 tw-items-center tw-justify-center"}>
                                    <LoadingIcon
                                        className={`tw-animate-spin tw-h-8 tw-w-8 tw-mx-2
                                            tw-fill-gray-600`}
                                    />
                                    Loading...
                                </div>

                            </td>

                        </tr>
                    }

                    {/*No Content */}
                    {
                        tableState[props?.tag]?.data?.length === 0 &&
                        <tr className={"tw-border"}>
                            <td colSpan={props.columns.length} className={"tw-text-center tw-py-4"}>
                                No Data
                            </td>
                        </tr>
                    }


                    {
                        // tableData && tableData.length === 0 &&
                        // <tr key={-1} className="border border dark:border-gray-600 text-left h-12 rounded ">
                        //     <td colSpan={props.columns.length} className={""}>
                        //         <div className={"flex text-gray-500 items-center justify-center"}>
                        //
                        //         </div>
                        //     </td>
                        // </tr>
                    }
                    </tbody>
                </table>

            }

            {
                (!props?.hideBottomToolbar && !props.clientSide && renderOption?.name.toLowerCase() === "list" ) &&
                <div className={"tw-flex tw-pb-10 tw-items-center tw-justify-between"}>

                    <div className="tw-pl-1">
                        <JavSelect items={config.pageNumbers}
                                   title={"Rows per page"}
                                   position={"ada"}
                                   onChange={(item) => {
                                       setConfig({
                                           ...config,
                                           pageSize: item
                                       })
                                   }}
                        />
                    </div>

                    <div className={"tw-flex"}>
                        {
                            links && links.map((link,index) => {
                                return (
                                    <a key={index} onClick={() => {
                                        if (link.url) {
                                            let page = getParameterByName("page",link.url);
                                            setConfig({
                                                ...config,
                                                page: parseInt(page)
                                            })
                                        }
                                    }} className={`tw-h-8 tw-w-8 ${!link.active ? 'tw-text-blue-900 tw-border  tw-rounded-full': 'tw-text-blue-400 tw-rounded-full tw-border tw-bg-blue-50'} tw-font-satoshiBold
                                                tw-cursor-pointer tw-text-xs tw-flex tw-mx-1 tw-items-center
                                                 tw-justify-center  `}>
                                        {
                                            link.label
                                                .replaceAll("&raquo;","")
                                                .replaceAll("Previous","<")
                                                .replaceAll("Next",">")
                                                .replaceAll("&laquo;","")
                                        }
                                    </a>
                                )
                            })
                        }

                    </div>


                    <div className={"tw-pr-10 tw-text-gray-600 tw-text-xs tw-flex tw-items-center"}>
                        <span>{tableSummary}</span>
                    </div>

                </div>

            }


        </div>
    )
}



export default forwardRef(Table)