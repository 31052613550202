export default function JavAmountLabel(props) {
    return (
        <div>
            <div className={`${ props?.bgColor ? props?.bgColor : 'tw-bg-gray-50'} 
            ${ props?.border ? props?.border : 'tw-border' }
            tw-p-5 tw-rounded-xl tw-flex tw-flex-col `}>
                <span className={"tw-text-sm"}>{props?.title}</span>
                <span className={"tw-font-satoshiBold tw-mt-1 tw-text-2xl"}>
                    {props.value}
                </span>
            </div>
            {
                props?.renderToolTip && props?.renderToolTip()
            }
        </div>

    )
}