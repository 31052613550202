import {useEffect, useState} from "react";
import Logo from "../../../assets/javolin_logo_rev.svg";
import JavButton from "../Forms/JavButton";
import { XIcon } from "../JavIcons";
import MobileNavigationBar from "./MobileNavigationBar";

// import { Menu } from 'lucide-react';

const MobileSidebar = (props) => {

  useEffect(() => {

    console.log("MobileSidebar.props",props)
  }, [props]);


  return (
    <div className="tw-relative">
 
      {/* Sidebar */}
      {props.isSidebarVisible && (
        <div
          className="tw-fixed tw-inset-0 tw-z-50 tw-bg-black tw-bg-opacity-50"
          onClick={props.toggleSidebar}
        >
          <div className="tw-fixed tw-inset-y-0 tw-left-0 tw-w-full tw-max-w-xs tw-overflow-y-auto tw-transition-transform tw-duration-300 tw-ease-in-out tw-transform tw-translate-x-0 tw-bg-jav-primary-300 tw-border-blue-800">
            {/* Sidebar Content */}

            {/* Your Sidebar content goes here */}
            <div
              className={`${
                props.isSidebarVisible ? "tw-block" : "tw-hidden"
              } lg:tw-block tw-w-72
                    dark:tw-bg-jav-primary-900 tw-bg-jav-primary-300 tw-border-blue-800 dark:tw-border-[#181E2C]
                    tw-overflow-hidden tw-overflow-y-auto`}
            >
              <div className="tw-flex tw-justify-between ">
                <div
                  onClick={() => {}}
                  className="tw-h-20 tw-flex tw-pl-6 tw-mt-4"
                >
                  <img
                    src={Logo}
                    className="tw-h-10 p-1"
                    alt={"Javolin Logo"}
                  />
                </div>
                <JavButton
                  bgColor={"tw-bg-white"}
                  textColor="tw-text-white"
                  className={"tw-text-white tw-px-4 tw-mt-5 tw-h-9 tw-w-15"}
                  onClick={()=>{props.setIsSidebarVisible(!props.isSidebarVisible)}}
                >
                  {<XIcon height={20} width={20}/>}
                </JavButton>
              </div>
              <div className="tw-mt-2">
                <MobileNavigationBar setIsSidebarVisible={props.setIsSidebarVisible} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileSidebar;
