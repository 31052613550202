import JavLabel from "../../../Shared/Components/Forms/JavLabel";
import JavButton from "../../../Shared/Components/Forms/JavButton";
import {
    CheckIcon,
    LineIcon,
    XIcon,
} from "../../../Shared/Components/JavIcons";
import Dialog from "../../../Shared/Components/Dialog/Dialog";
import JavInput from "../../../Shared/Components/Forms/JavInput";
import {useRef, useState, useEffect} from "react";
import {makeJavolinRequest} from "../../../helpers/utils";
import {useAlert} from "../../../Shared/Context/AlertContext";

export default function EditWarehouse(props) {
    const isMobile = window.innerWidth <= 768;
    const tableRef = useRef();
    const {showAlert} = useAlert();
    const dialogWidth = isMobile ? "100%" : "40%";
    const [requestLoading, setRequestLoading] = useState(false);
    const [form, setForm] = useState({
        name: "",
        address: "",
    });

    // Initialize form state with props.details when the component mounts or props.details change
    useEffect(() => {
        if (props.details) {
            setForm({
                name: props.details.name || "",
                address: props.details.address || "",
            });
        }
    }, [props.details]);

    const handleChanges = (event) => {
        const {name, value} = event.target;
        setForm({
            ...form,
            [name]: value,
        });
    };
    const approveEditRequest = (id) => {
        setRequestLoading(true);
        makeJavolinRequest(
            {
                path: `/business/agric/warehouses`,
                method: "POST",
                data: form,
            },
            null,
            null,
            (data) => {

                props?.onCloseClicked();
                setRequestLoading(false);
                tableRef?.current?.reloadTable();
                showAlert("Request Approved Successfully", "SUCCESS", <CheckIcon/>);
                console.log("data", data);
            },
            (error) => {
                setRequestLoading(false);
                props?.onCloseClicked();
                showAlert("Error: " + error, "FAILED", <XIcon/>);
                console.log("error", error);
            }
        );
    };


    return (
        <Dialog
            position={"center"}
            style={{width: dialogWidth}}
            open={props?.open}
            onCloseClicked={props?.onCloseClicked}
            review={false}
            title={
                <>
                    <p className="tw-mb-1">Edit Warehouse</p>
                </>
            }
        >
            <div className={"tw-flex tw-flex-col tw-px-2"}>
                <div className={"tw-flex tw-flex-col tw-gap-6"}>
                    <JavInput
                        disabled={false}
                        value={form.name}
                        title={"Warehouse name"}
                        name={"name"}
                        onChange={handleChanges}
                        placeholder="Eg. Javolin Anex"
                    />

                    <JavInput
                        disabled={false}
                        value={form.address}
                        title={"Address"}
                        name={"address"}
                        onChange={handleChanges}
                        placeholder="Eg. Madina Zongo junction"
                    />
                </div>
                <div className={"tw-grid tw-grid-cols-2 tw-gap-2 tw-my-3"}>
                    <div
                        className={"tw-grid tw-grid-cols-1 tw-col-span-2 tw-gap-4 tw-py-4 "}
                    ></div>

                    <div
                        className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-8"}
                    >
                        <JavButton
                            title={"Cancel"}
                            textColor={"tw-gray-500"}
                            bgColor={"tw-bg-transparent"}
                            className={"tw-border"}
                            onClick={props?.onDeclinedClick}
                        />

                        <JavButton
                            title={"Create"}
                            textColor={"tw-text-white"}
                            bgColor={"tw-bg-jav-primary-200"}
                            isLoading={props.isLoadingApprove}
                            onClick={() => {
                                approveEditRequest();
                            }}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
