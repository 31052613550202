import Logo from "../../../assets/javolin_logo_rev.svg";
import {useSelector} from "react-redux";
import {currencyFormat} from "../../../helpers/utils";

export default function JavolinWalletCard(props) {


    const formatString = input => `${input?.slice(0, 3)} ${input?.slice(3, 7)} ${input?.slice(7, 12)} ${input?.slice(12)}`;

    return (
        <div className={`${props?.height ?? `tw-h-56`} tw-bg-jav-primary-300 tw-rounded-xl tw-overflow-hidden`}>
            <div className={"tw-relative tw-bg-bg-card-pattern tw-h-full tw-w-full tw-bg-no-repeat tw-bg-cover"}>

                <div className={"tw-flex tw-justify-between tw-px-5 tw-pt-5"}>

                    <div className={"tw-flex tw-flex-col tw-text-white"}>
                        <span className={"tw-text-xl tw-text-gray-100 tw-font-satoshi tw-py-1"}>Balance</span>
                        <span className={"tw-font-firmaBold tw-text-xl"}>{ props?.currency } { currencyFormat(props?.balance,2) }</span>
                    </div>

                    <img src={Logo}
                         className="tw-h-7 p-1" alt={"Javolin Logo"}/>

                </div>

                <div
                    className={"tw-absolute tw-text-white tw-bottom-0 tw-right-0 tw-filter tw-blur-sm tw-backdrop-filter tw-backdrop-blur-sm tw-left-0 tw-h-20"}>
                </div>

                <div
                    className={"tw-absolute tw-font-satoshiMedium tw-flex-col tw-px-5 tw-flex tw-text-white tw-bottom-0 tw-right-0 tw-left-0 tw-h-20"}>
                    <span className={"tw-text-xl tw-text-gray-300 tw-font-satoshi tw-py-1"}>{props?.accountName ?? "Javolin Account"}</span>
                    <span className={"tw-font-firmaBold tw-text-xl"}> {formatString(props?.accountNumber)} </span>
                </div>


            </div>
        </div>

    )
}