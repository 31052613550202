// AlertContext.js
import React, { createContext, useContext, useState } from 'react';


const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ show: false, message: '', messageType: 'SUCCESS', icon: '' });

  const showAlert = (message, messageType = 'SUCCESS', icon) => {
    setAlert({
      show: true,
      message,
      messageType,
      icon
    });
    
    setTimeout(() => hideAlert(), 2500);
  };

  const hideAlert = () => {
    setAlert({ ...alert, show: false });
  };

  return (
    <AlertContext.Provider value={{ ...alert, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
