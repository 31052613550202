import {ActionTypes} from "./type";
import {makeJavolinRequest} from "../../../helpers/utils";


export const fetchLoanDetails = (loanReference) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH_LOAN_DETAILS });
        makeJavolinRequest({
            path: `/business/loans/${loanReference}`,
            method: "GET",
        },{
            SUCCESS: ActionTypes.FETCH_LOAN_DETAILS_SUCCESS,
            ERROR: ActionTypes.FETCH_LOAN_DETAILS_ERROR
        },dispatch);
    }
}

export const fetchLoanContractDetails = (contractReference) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH_LOAN_CONTRACT_DETAILS });
        makeJavolinRequest({
            path: `/business/loan-contracts/${contractReference}`,
            method: "GET",
        },{
            SUCCESS: ActionTypes.FETCH_LOAN_CONTRACT_DETAILS_SUCCESS,
            ERROR: ActionTypes.FETCH_LOAN_CONTRACT_DETAILS_ERROR
        },dispatch);
    }
}

