import { CheckIcon } from '../JavIcons'; // Adjust the import path as needed

const Alert = ({ message, showAlert, messageType }) => {
  if (!showAlert) return null;
  const cardBackgroundColor = messageType === 'SUCCESS' ? 'tw-bg-jav-card-50' : 'tw-bg-white';

  const iconColor = 'white';
  const iconBackground = {
    SUCCESS: 'tw-bg-jav-alert-green-100', 
    ERROR: 'tw-bg-jav-alert-red-100',
    INFO: 'tw-bg-jav-alert-blue-100',
    WARNING: 'tw-bg-jav-alert-yellow-100', 
  };
  const currentBackground = iconBackground[messageType] || iconBackground.SUCCESS;


  return (
  <div className={`${cardBackgroundColor} tw-font-satoshiBold tw-shadow-md tw-w-96 tw-h-16 tw-rounded-2xl tw-flex tw-items-center tw-px-3 tw-py-1 tw-absolute tw-top-0 tw-right-0`}>
    <div className={`tw-flex-shrink-0 ${currentBackground} tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-justify-center tw-items-center`}>
      <CheckIcon width="20" height="20" color={iconColor} />
    </div>
     <p className="tw-flex-grow tw-text-lg tw-font-normal tw-text-left tw-ml-3 tw-my-auto" style={{ lineHeight: '2.5rem' }}>{message}</p>
  </div>
);
};

export default Alert;
