import 'antd/dist/antd.less';
import React from "react"
import { RecoverPassword, PasswordReset, ForexLogin, PhoneVerification, BusLogin } from "../../../Modules/Auth/Login";
import SignUp from "../../../Modules/Auth/Register/SignUp";
import AuthUserLayoutRoute from "../../../Shared/Components/Layouts/AuthUserLayout"

import {Switch, Redirect, Route} from "react-router-dom"
import LoginOption from '../../../Modules/Auth/Login/Pages/LoginOption';
import SignUpPage2 from '../../../Modules/Auth/Register/SignUpPage2';
import SignUpHome from '../../../Modules/Auth/Register/SignUpHome';
import BusinessSignUp from '../../../Modules/Auth/Register/BusinessSignUp';
import BusinessSignUpPage2 from '../../../Modules/Auth/Register/BusinessSignUpPage2';
import ResetPassword from '../../../Modules/Auth/Login/Pages/ResetPassword';
import ApplicationAuthLayout from "./ApplicationAuthLayout";
import RegistrationForm from '../../../Modules/Auth/Register/NewRegister';
import AgriTechLogin from "../../../Modules/Auth/Login/Pages/agritech/AgriTechLogin";
import useUserType from "../../../Modules/Auth/useUserType";



function InitialLayout() {

    const userType = useUserType();


    return (

        <Switch>

            <AuthUserLayoutRoute title="Phone Verification" path="/phone" component={PhoneVerification} />
            <AuthUserLayoutRoute title="ForexLogin" path="/login-option" component={LoginOption} />
            <AuthUserLayoutRoute title="ForexLogin" path="/business/login" component={BusLogin} />
            <AuthUserLayoutRoute exact title="Signup" path="/signup" component={SignUp} />
            <AuthUserLayoutRoute exact title="Signup" path="/register" component={SignUpHome} />
            <AuthUserLayoutRoute title="Recover Password" path="/forgot-password" component={RecoverPassword} />
            <AuthUserLayoutRoute title="Reset Password" path="/password/forgot" component={ResetPassword} />
            <AuthUserLayoutRoute title="Password Reset" path="/password-reset" component={PasswordReset} />
            <AuthUserLayoutRoute path="/signup/2" component={SignUpPage2} />
            <AuthUserLayoutRoute exact path="/business/signup/2" component={BusinessSignUp} />
            <AuthUserLayoutRoute path="/business/signup" component={BusinessSignUpPage2} />
            <ApplicationAuthLayout path={"/business/register"} component={RegistrationForm} />

            <AuthUserLayoutRoute path={"/agritech/login"} dontUseDefaultLayout={true} component={AgriTechLogin} />
            <AuthUserLayoutRoute path={"/forex/login"} component={ForexLogin} />
            <AuthUserLayoutRoute path={"/login"} component={ForexLogin} />


            <Redirect to="/login" />

        </Switch>

    );
}



export default InitialLayout;