import * as React from "react"

const CheckIcon = (props) => (
    <svg
        width="12" height="12" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M9.125 1.375L3.875 6.625L1.25 4" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default CheckIcon
