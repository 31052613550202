import React from "react";
import ThemeStyles from "../../../../style/Auth.module.css";
import { Button, Input, Form } from "antd";
import { useHistory } from "react-router";
import queryString from "query-string"


import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { recoverPassword, resetPassword } from "../duck/action";
const ResetPassword = (props) => {

let details =  queryString.parse(props.location.search)
  const dispatch = useDispatch();
  const userlogin = useSelector((state) => state.login);
  const history = useHistory()

  const text = useSelector((state) => state.language)

  const onFinish = (values) => {
    details={
        ...details,
        ...values
    }

    console.log(details)
    dispatch(resetPassword(details, history));

  };


  return (
    <>
      <div className={ThemeStyles.cardContent}>
        <div className={ThemeStyles.topSection}>
          <div className={ThemeStyles.authTitle}>
            Set a new password
          </div>
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          {/* <p className={ThemeStyles.subInfo}>
            We all forget sometimes. Enter your email and we will send you a link to reset your password
          </p> */}
         
          <Form.Item label={text["Password"]}>
                <Form.Item
                    noStyle
                    name="new_password"
                    rules={[
                        {
                            required: true,
                            message: text["Please input your password!"],
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password size="large" placeholder={"Enter new password"} />
                </Form.Item>
            </Form.Item>

            <Form.Item label={text["Confirm Password"]}>
                <Form.Item
                    noStyle
                    name="confirm_new_password"
                    dependencies={["new_password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: text["Please confirm your password!"],
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("new_password") === value
                                ) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    new Error(
                                        text["The two passwords that you entered do not match!"]
                                    )
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password size="large" placeholder={text["re-enter password"]} />
                </Form.Item>
            </Form.Item>

          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            shape="round"
            className="login-form-button"
            loading={userlogin.recoverpass}
          >
            confirm
          </Button>
        </Form>
        <p className={ThemeStyles.subInfo}>
          <Link to={"/login"}> return to login</Link>
        </p>
      </div>

    </>
  );
};

export default ResetPassword;
