const NotificationBadge = ({ count }) => {
    return (
      <div className="relative tw-w-4 tw-pl-3">
        {count > 0 && (
          <span className="tw-bg-red-500 tw-text-white  tw-rounded-full tw-w-5 tw-h-5 tw-flex tw-items-center tw-justify-center tw-text-xs tw-absolute tw-bottom-9">
            {count}
          </span>
        )}
      </div>
    );
  };
  
  export default NotificationBadge;