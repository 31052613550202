    
export const GETTING_PERMISSIONS_REQUEST = "GETTING_PERMISSIONS_REQUEST"
export const GETTING_PERMISSIONS_SUCCESS = "GETTING_PERMISSIONS_SUCCESS"
export const GETTING_PERMISSIONS_ERROR = "GETTING_PERMISSIONS_ERROR"

export const GETTING_ROLES_REQUEST = "GETTING_ROLES_REQUEST"
export const GETTING_ROLES_SUCCESS = "GETTING_ROLES_SUCCESS"
export const GETTING_ROLES_ERROR = "GETTING_ROLES_ERROR"

export const ADDING_ROLE_REQUEST = "ADDING_ROLE_REQUEST"
export const ADDING_ROLE_SUCCESS = "ADDING_ROLE_SUCCESS"
export const ADDING_ROLE_ERROR = "ADDING_ROLE_ERROR"


export const ADDING_USER_REQUEST = "ADDING_USER_REQUEST"
export const ADDING_USER_SUCCESS = "ADDING_USER_SUCCESS"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const ADDING_USER_ERROR = "ADDING_USER_ERROR"

export const GETTING_USERS_REQUEST = "GETTING_USERS_REQUEST"
export const GETTING_USERS_SUCCESS = "GETTING_USERS_SUCCESS"
export const GETTING_USERS_ERROR = "GETTING_USERS_ERROR"


export const GET_PENDING_TRANX_REQUEST = "GET_PENDING_TRANX_REQUEST"
export const GET_PENDING_TRANX_SUCCESS = "GET_PENDING_TRANX_SUCCESS"
export const GET_PENDING_TRANX_ERROR = "GET_PENDING_TRANX_ERROR"

export const APPROVE_TRANX_REQUEST = "APPROVE_TRANX_REQUEST"
export const APPROVE_TRANX_SUCCESS = "APPROVE_TRANX_SUCCESS"
export const APPROVE_TRANX_ERROR = "APPROVE_TRANX_ERROR"

export const DECLINE_TRANX_REQUEST = "DECLINE_TRANX_REQUEST"
export const DECLINE_TRANX_SUCCESS = "DECLINE_TRANX_SUCCESS"
export const DECLINE_TRANX_ERROR = "DECLINE_TRANX_ERROR"


export const APPROVE_USER_REQUEST = "APPROVE_USER_REQUEST"
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS"
export const APPROVE_USER_ERROR = "APPROVE_USER_ERROR"

export const DECLINE_USER_REQUEST = "DECLINE_USER_REQUEST"
export const DECLINE_USER_SUCCESS = "DECLINE_USER_SUCCESS"
export const DECLINE_USER_ERROR = "DECLINE_USER_ERROR"

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"


export const GET_TRANX_DETAILS = "GET_TRANX_DETAILS"
export const GET_TRANX_DETAILS_SUCCESS = "GET_TRANX_DETAILS_SUCCESS"
export const GET_TRANX_DETAILS_FAILED = "GET_TRANX_DETAILS_FAILED"

