import JavLabel from "../../Shared/Components/Forms/JavLabel";
import JavButton from "../../Shared/Components/Forms/JavButton";
import { LineIcon } from "../../Shared/Components/JavIcons";
import Dialog from "../../Shared/Components/Dialog/Dialog";

export default function RequestSummaryReview(props) {
  const isMobile = window.innerWidth <= 768;
  const dialogWidth = isMobile ? "100%" : "40%";
  return (
    <Dialog
      position={"top"}
      style={{ width:dialogWidth }}
      open={props?.open}
      onCloseClicked={props?.onCloseClicked}
      review={true}
      title={(
        <>
        <p className="tw-mb-1">Pending Request</p>
        <p className="tw-mb-0 tw-text-black tw-font-proximaBold tw-text-lg">{props.details?.loan?.reference}</p>
        </>
      )}
    >
      <div className={"tw-flex tw-flex-col tw-px-2"}>
        <div>
          <span className={"tw-font-satoshiBold tw-text-xl tw-text-gray-800"}>
            <span className={"tw-font-satoshi tw-text-gray-600"}></span> Review
            Pending Transaction
          </span>
        </div>

        <div className={"tw-grid tw-grid-cols-2 tw-gap-2 tw-my-3"}>
          <div
            className={"tw-grid tw-grid-cols-1 tw-col-span-2 tw-gap-4 tw-py-4 "}
          >
            <div className={"tw-flex tw-items-center"}>
              <span
                className={"tw-font-satoshiMedium tw-text-lg tw-text-blue-700"}
              >
                Transaction made by
              </span>
              <LineIcon className={"tw-flex-1 tw-pl-0"} />
            </div>
          </div>

          <JavLabel
            title={"User name"}
            value={props.details?.business?.full_name}
          />
          <JavLabel
            title={"Role"}
            value={props.details?.business?.role?.role?.name}
          />

          <div
            className={"tw-grid tw-grid-cols-1 tw-col-span-2 tw-gap-4 tw-py-4 "}
          >
            <div className={"tw-flex tw-items-center"}>
              <span
                className={"tw-font-satoshiMedium tw-text-lg tw-text-blue-700"}
              >
                Beneficiary
              </span>
              <LineIcon className={"tw-flex-1"} />
            </div>
          </div>

          <JavLabel
            title={"Bank Name"}
            value={props.details?.beneficiary[0]?.bank_name}
          />
          <JavLabel
            title={"Account Number"}
            value={props.details?.beneficiary[0]?.account_number}
          />
          <JavLabel
            title={"Account Name"}
            value={props.details?.beneficiary[0]?.name}
          />

          <div
            className={"tw-grid tw-grid-cols-1 tw-col-span-2 tw-gap-4 tw-py-4 "}
          >
            <div className={"tw-flex tw-items-center"}>
              <span
                className={"tw-font-satoshiMedium tw-text-lg tw-text-blue-700"}
              >
                Bags & Truck Info
              </span>
              <LineIcon className={"tw-flex-1"} />
            </div>
          </div>

          <JavLabel
            title={"No. of trucks"}
            value={`${props.details?.params?.expected_number_of_trucks}`}
          />
          <JavLabel
            title={"No. of bags / truck"}
            value={`${props.details?.params?.expected_number_of_bags_per_truck}`}
          />

          <JavLabel
            title={"Value of bag /kg"}
            value={`${props.details?.params?.weight_per_bag} Kg`}
          />
          <JavLabel
            title={"Cost per bag"}
            value={`GH₵ ${props.details?.params?.cost_price_per_bag}`}
          />

          <div
            className={"tw-grid tw-grid-row-3 tw-col-span-2 tw-gap-4 tw-py-4 "}
          >
            <JavLabel
              title={"Total no. of bags"}
              value={
                props.details?.params?.expected_number_of_trucks *
                props.details?.params?.expected_number_of_bags_per_truck
              }
            />
          </div>

          <div
            className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-8"}
          >
            <JavButton
              title={"Decline"}
              textColor={"tw-text-white"}
              bgColor={"tw-bg-red-500"}
              className={"tw-border"}
              isLoading={props.isLoading}
              onClick={props?.onDeclinedClick}
            />

            <JavButton
              title={"Approve"}
              textColor={"tw-text-white"}
              bgColor={"tw-bg-green-600"}
              isLoading={props.isLoadingApprove}
              onClick={props?.onApproveClicked}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
