import React, { useEffect, useRef, useState } from "react";
import { currencyFormat, makeJavolinRequest } from "../../../helpers/utils";
import JavButton from "../../../Shared/Components/Forms/JavButton";
import {
  CheckIcon,
  Edit2Icon,
  LoadingIcon,
} from "../../../Shared/Components/JavIcons";
import ThumbsDownIcon from "../../../Shared/Components/Icons/ThumsDown";

import { useDispatch } from "react-redux";
import XIcon from "../../../Shared/Components/Icons/XIcon";
import { useAlert } from "../../../Shared/Context/AlertContext";
import DefaultTable from "../../../Shared/Components/DefaultTable/defaultTable";
import ConfirmDialog from "../../../Shared/Components/Dialog/ConfirmDialog";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import EditIcon from "../../../Shared/Components/Icons/EditIcon";
import WarehouseReview from "./EditWarehouse";
import AddWarehouseReview from "./AddWarehouse";
import EditWarehouse from "./EditWarehouse";

const AgricWarehouse = () => {
  const [isLoadingApprove, setLoadingApproval] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const tableRef = useRef();
  const [requestDetails, setRequestDetails] = useState({
    hasOpen: false,
    data: null,
  });
  const dispatch = useDispatch();

  const { showAlert } = useAlert();

  const [isOpen, setIsOpen] = useState({ hasOpen: false, id: null });
  const [isUpdateOpen, setUpdateOpen] = useState({ hasOpen: false, id: null });

  const handleConfirm = (id) => {
    setIsOpen({ ...isOpen, hasOpen: true, id: id });
  };

  const handleCancel = () => {
    setIsOpen({ ...isOpen, hasOpen: false, id: null });
    tableRef?.current?.reloadTable();
  };

  const handleUpdateModalClose = () => {
    setUpdateOpen({ ...isOpen, hasOpen: false, id: null });
    tableRef?.current?.reloadTable();
  };
  const handleUpdateModal = (id) => {
    setUpdateOpen({ ...requestDetails, hasOpen: true, id: id });
  };


  const handleModal = (id) => {
    setRequestDetails({ ...requestDetails, hasOpen: true, data: id });
  };

  const closeModal = () => {
    setRequestDetails({ ...requestDetails, hasOpen: false, data: null });
    tableRef?.current?.reloadTable();
  };

  const declineRequest = (id) => {
    setRequestLoading(true);
    makeJavolinRequest(
      {
        path: `/business/agric/warehouses/${id}`,
        method: "DELETE",
    
      },
      null,
      null,
      (data) => {
        handleCancel();
        closeModal();
        setRequestLoading(false);
        showAlert("Request Deleted Successfully", "SUCCESS", <CheckIcon />);

        console.log("data", data);
      },
      (error) => {
        setRequestLoading(false);
        handleCancel();
        closeModal();
        showAlert("Error: " + error, "FAILED", <XIcon />);
        console.log("error", error);
      }
    );
  };

  return (
    <>
      <DefaultTable
        ref={tableRef}
        columns={["ID", "Warehouse Name", "Address", "Date Created", "Action"]}
        fields={[
          "id",
          "name",
          "address",
          "created_at",
          {
            render: (content) => {
              return (
                <div className={"tw-flex tw-justify-center"}>
                  <JavButton
                    bgColor={"tw-bg-blue-600"}
                    textColor="tw-text-white"
                    padding="2"
                    icon={EditIcon}
                    className={"tw-text-white  tw-px-4 tw-m-2 tw-h-9"}
                    onClick={() => {
                        handleUpdateModal(content);
                    }}
                  >
                    
                    Edit
                  </JavButton>

                  <JavButton
                    bgColor={"tw-bg-red-600"}
                    textColor="tw-text-white"
                    className={"tw-text-white tw-px-4 tw-m-2 tw-h-9 "}
                    icon={TrashIcon}
                    onClick={() => {
                      handleConfirm(content.id);
                    }}
                  >
                    Delete
                  </JavButton>
                </div>
              );
            },
          },
        ]}
        link={"/business/agric/warehouses"}
        title={`Warehouses`}
        tag={"api.table.warehouse"}
      />

      <ConfirmDialog
        isOpen={isOpen.hasOpen}
        handleCancel={handleCancel}
        message={
          <span className="">
            Are you sure you want to{" "}
            <span className="tw-font-satoshiBold tw-pl-1 tw-pr-1 tw-text-black">
              Delete
            </span>{" "}
            this Warehouse?
          </span>
        }
        handleConfirm={() => {
          declineRequest(isOpen.id);
        }}
        requestLoading={requestLoading}
      />

      <AddWarehouseReview
        details={requestDetails.data}
        open={requestDetails.hasOpen}
        onDeclinedClick={() => {
          closeModal();
        }}
        isLoading={requestLoading}
        isLoadingApprove={isLoadingApprove}
        onApproveClicked={() => {
        }}
        onCloseClicked={() => {
          closeModal();
        }}
      />
      <EditWarehouse
        details={isUpdateOpen.id}
        open={isUpdateOpen.hasOpen}
        onDeclinedClick={() => {
            handleUpdateModalClose();
        }}
        isLoading={requestLoading}
        isLoadingApprove={isLoadingApprove}
        onApproveClicked={() => {
          
        }}
        onCloseClicked={() => {
            handleUpdateModalClose();
        }}
      />

      {/* <div
        onClick={() => {
            handleModal("")
        }}
        className={
          "tw-absolute tw-flex tw-text-white tw-items-center tw-justify-center tw-bg-jav-primary-200 tw-bottom-5 tw-right-5 tw-border tw-rounded-full tw-w-16 tw-h-16"
        }
      >
        <PlusCircleIcon className="h-5 w-5 tw-cursor-pointer" />
      </div> */}
    </>
  );
};
export default AgricWarehouse;
