import {ActionTypes} from "./type";

const initial = {
    fetch_loan_details: {
        success: false,
        loading: false,
        error: null,
        data: null
    },
    fetch_loan_contract_details: {
        success: false,
        loading: false,
        error: null,
        data: null
    }
};

export const TradingHubReducer = (state = initial,action) => {
    switch (action.type) {

        //fetch_loan_details
        case ActionTypes.REQUEST_FETCH_LOAN_DETAILS:
            return {
                ...state,
                fetch_loan_details: {
                    ...state.fetch_loan_details,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.FETCH_LOAN_DETAILS_SUCCESS:
            return {
                ...state,
                fetch_loan_details: {
                    ...state.fetch_loan_details,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.FETCH_LOAN_DETAILS_ERROR:
            return {
                ...state,
                fetch_loan_details: {
                    ...state.fetch_loan_details,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        //fetch_loan_contract_details
        case ActionTypes.REQUEST_FETCH_LOAN_CONTRACT_DETAILS:
            return {
                ...state,
                fetch_loan_contract_details: {
                    ...state.fetch_loan_contract_details,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.FETCH_LOAN_CONTRACT_DETAILS_SUCCESS:
            return {
                ...state,
                fetch_loan_contract_details: {
                    ...state.fetch_loan_contract_details,
                    success: true,
                    loading: false,
                    data: action.payload
                }
            }
        case ActionTypes.FETCH_LOAN_CONTRACT_DETAILS_ERROR:
            return {
                ...state,
                fetch_loan_contract_details: {
                    ...state.fetch_loan_contract_details,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }



        default:
            return state

    }
}

