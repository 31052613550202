import React from "react";

function NotificationIcon({props}) {
  return (
    <svg
      fill="none"
      height="30"
      viewBox="0 0 24 24"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 16.1667H6C5.44772 16.1667 5.00406 15.7073 5.21455 15.1967C5.7376 13.9279 7.05588 12.6758 7.33333 11.5C8.25969 7.57434 8.60768 4.5 12 4.5C15.4133 4.5 15.7499 7.52186 16.6667 11.5C16.9356 12.6671 18.2644 13.9165 18.7877 15.1961C18.9967 15.7073 18.5523 16.1667 18 16.1667Z"
        stroke="blue"
      />
      <path
        d="M13 17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5"
        stroke="blue"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default NotificationIcon;
