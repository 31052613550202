import Dialog from "../../../../Shared/Components/Dialog/Dialog";
import JavInput from "../../../../Shared/Components/Forms/JavInput";
import {useState} from "react";
import JavButton from "../../../../Shared/Components/Forms/JavButton";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import JavFormFileInput from "../../../../Shared/Components/Forms/JavFormFileInput";
import JavDatePicker from "../../../../Shared/Components/Forms/JavDatePicker";
import {makeJavolinRequest} from "../../../../helpers/utils";

export default function LoanContractRequestDialog(props) {


const isMobile = window.innerWidth <= 768;
const dialogWidth = isMobile ? "100%" : "40%";
    const [form,setForm] = useState({
        file: null
    });
    const [createContract,setCreateContract] = useState({
        loading: false,
        data: null
    })

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onCreateClicked = () => {
        setCreateContract({...createContract,loading: true})
        makeJavolinRequest({
            path: '/business/loan-contracts',
            method: "POST",
            data: {...form, file: form?.file?.file}
        },null,null,(data) => {
            setCreateContract({...createContract,loading: false,data: data})
            alert("Contract Created Successfully")
            props.onCloseClicked();
            console.log("data",data)
        },(error) => {
            setCreateContract({...createContract,loading: false})
            console.log("error",error)
        })
    }


    return (
        <Dialog position={"top"} style={{width: dialogWidth}} open={props?.open} onCloseClicked={props?.onCloseClicked}
                title={"Create Contract"}>

            <div className={"tw-grid tw-grid-cols-2 tw-gap-4 tw-my-8"}>

                <div className={"tw-col-span-2"}>
                    <JavInput
                        title={"Contract Name"}
                        name={"name"}
                        value={form?.name}
                        onChange={handleChanges}
                    />
                </div>

                <JavDatePicker
                    title={"Start date"}
                    name={"start_date"}
                    range={false}
                    showFooter={false}
                    onChange={(date) => {
                        console.log("date", date);
                        setForm({
                            ...form,
                            start_date: date?.startDate
                        })
                    }}
                />

                <JavDatePicker
                    range={false}
                    showFooter={false}
                    title={"End date"}
                    name={"end_date"}
                    onChange={(date) => {
                        setForm({
                            ...form,
                            end_date: date?.startDate
                        })
                    }}
                />

                <JavInput
                    title={"Total Tons"}
                    name={"total_weight"}
                    value={form?.total_weight}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"Cost Per Tons (USD)"}
                    name={"cost_per_weight"}
                    value={form?.cost_per_weight}
                    onChange={handleChanges}
                />

                <JavInput
                    title={"Revenue Per Tons (USD)"}
                    name={"revenue_per_weight"}
                    value={form?.revenue_per_weight}
                    onChange={handleChanges}
                />


                <JavFormSelect
                    title={`Select Buyer`}
                    common_code={"CB001"}
                    code={"CB001"}
                    codeField={"code_name"}
                    position={"bottom"}
                    onChange={(item) => {
                        setForm({
                            ...form,
                            buyer_id: item?.id
                        })
                    }}
                />

                <div className={"tw-col-span-2"}>

                    <JavFormSelect
                        title={`Select Commodity`}
                        common_code={"CT001"}
                        code={"CT001"}
                        codeField={"code_name"}
                        position={"bottom"}
                        onChange={(item) => {
                            setForm({
                                ...form,
                                commodity_id: item?.id
                            })
                        }}
                    />
                </div>

                <div className={"tw-col-span-2"}>
                    <JavFormFileInput
                        title={"Contract Name"}
                        name={"expected_number_of_trucks"}
                        value={form?.expected_number_of_trucks}
                        data={form?.file}
                        onChange={(item) => {
                            console.log("item", item)
                            setForm({
                                ...form,
                                file: item
                            })
                        }}
                    />
                </div>


                <div className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-8"}>
                    <JavButton
                        title={"Cancel"}
                        textColor={"tw-gray-500"}
                        bgColor={"tw-bg-transparent"}
                        className={"tw-border"}
                        onClick={props?.onCancelClicked}
                    />
                    <JavButton
                        title={"Create Contract"}
                        textColor={"tw-text-white"}
                        isLoading={createContract?.loading}
                        onClick={onCreateClicked}
                    />
                </div>


                </div>

        </Dialog>
)
}