import {buildQueryParamFromForm, downloadFile} from "../../../../../helpers/utils";
import JavButton from "../../../../../Shared/Components/Forms/JavButton";
import DownloadIcon from "../../../../../Shared/Components/Icons/DownladIcon";
import Table from "../../../../../Shared/Components/Table";
import {useRef, useState} from "react";
import UploadDocumentDialog from "./components/uploadDocumentDialog";

export default function LoanContractDetailDocument(props) {

    const tableRef = useRef();
    const [showUploadDocument,setShowUploadDocument] = useState(false);
    const link = `admin-portal/documents?reference=${props?.data?.module}&reference_number=${props?.data?.reference}`;


    const onUploadDocumentClicked = () => {
        setShowUploadDocument(true);
    }



    return (
        <div className={"md:tw-px-2 tw-rounded-lg tw-my-2"}>

            <UploadDocumentDialog
                transaction={props?.data}
                open={showUploadDocument}
                title={"Upload Transaction Document"}
                onCloseClicked={() => {
                    setShowUploadDocument(false)
                    tableRef.current.reloadTable(`${link}&${buildQueryParamFromForm({})}`)
                }}
            />

            <div className={"tw-flex tw-mb-5 tw-justify-end"}>
                <JavButton
                    onClick={onUploadDocumentClicked}
                    title={"Upload Document"}
                    textColor={"text-white"}
                    className={"tw-mt-5"}
                />
            </div>

            <div className={"tw-bg-white tw-rounded-lg tw-border tw-shadow-sm md:tw-py-2"}>

                <Table
                    title={"Documents"}
                    ref={tableRef}
                    columns={["No","Document Type","Remarks","Uploaded By","Timestamp","Action"]}
                    link={link}
                    tag={"documents.table"}
                    fields={[{
                        render: (content,index) => {
                            return (
                                <td className={"text-center"}>
                                    {index + 1}
                                </td>
                            )
                        }
                    },{
                        render: (content) => {
                            return (
                                <div className={"tw-flex tw-h-10 tw-items-center tw-justify-center"}>
                                    {content?.document_type?.code_name}
                                </div>
                            )
                        }
                    },"meta.remarks","uploader.name","created_at",{
                        render: (content) => {
                            return (
                                <div className={"tw-flex tw-items-center tw-justify-center"}>
                                    <JavButton onClick={() => {
                                        downloadFile(`admin-portal/documents/${content?.id}/download`,null,content?.name).then(r => {
                                            // setExportDownloading(null);
                                        })
                                    }} className={"tw-p-1"} bgColor={"tw-bg-gray-200 "}>
                                        <DownloadIcon />
                                    </JavButton>
                                </div>
                            )

                        }
                    }]}
                />
            </div>



        </div>
    )
}