import JavSelect from "./JavSelect";
import JavDropdownNew from "./JavDropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCodes } from "./duck/action";
import { makeJavolinRequest } from "../../../helpers/utils";

export default function JavFormSelect(props) {
  const dispatch = useDispatch();
  const formsState = useSelector((state) => state.forms);
  const [remoteItems, setRemoteItems] = useState([]);
  const [value, setValue] = useState(props?.value);

  useEffect(() => {
    if (formsState.fetch_codes[props.code]) {
      console.log("formsState", formsState.fetch_codes[props.code]);
      setRemoteItems(
        formsState.fetch_codes[props.code].map((item) => {
          return {
            ...item,
            title: item[props?.codeField],
          };
        })
      );
      if (props.selected) {
        let selectedItem = formsState.fetch_codes[props?.code]?.filter(
          (item) => props?.selected.toString() === item.id.toString()
        )[0];
        setValue(selectedItem?.[props?.codeField]);
      }
    }
  }, [formsState]);

  const fetchCommonCodes = (code) => {
    switch (code) {
      case "users-auto-search":
        console.log("fetchCommonCodeViaEndpoint");
        fetchCommonCodeViaEndpoint("users-auto-search", "");
        break;
      default:
        dispatch(fetchCodes(code, props?.codeOption));
    }
  };

  const fetchCommonCodeViaEndpoint = (endpoint, search) => {
    makeJavolinRequest(
      {
        path: endpoint,
        method: "GET",
        query: { search },
      },
      null,
      null,
      (data) => {
        console.log("data", data);
        setRemoteItems(
          data.map((item) => {
            return {
              ...item,
              title: item[props?.codeField],
            };
          })
        );
      },
      (error) => {
        console.log("error is ", error);
      }
    );
  };

  useEffect(() => {
    if (props?.code) {
      fetchCommonCodes(props?.code);
    }
  }, [props?.code]);

  return (
    <div
      className={`tw-flex tw-font-satoshi
                    ${props.isColumn ? "tw-flex-row" : "tw-flex-col"} 
                    ${props.className}
                    `}
    >
      {props?.title && (
        <h3
          className={`tw-text-[30px] tw-text-black tw-font-satoshi
                    ${
                      props.isColumn
                        ? "tw-w-2/5 tw-bg-gray-100 tw-border tw-border-gray-200 tw-dark:bg-[#242A38] tw-flex tw-items-center tw-px-2"
                        : ""
                    }
                    `}
        >
          {props.title}
        </h3>
      )}

      <div
        className={`${props.isColumn ? "tw-w-3/5" : ""}
                            ${props.isColumn ? "" : "tw-rounded-md"}
                            ${
                              !props.isColumn
                                ? ""
                                : "tw-border tw-border-gray-200 dark:tw-border-gray-500"
                            }
                            `}
      >
        {props.useNetwork ?  (
          <JavDropdownNew
            selected={props.selected}
            onChange={props.onChange}
            bgColor={props.bgColor}
            items={props.code ? remoteItems : props.items}
            position={props.position}
            hideBorder={props.isColumn ? true : props.hideBorder}
            value={value}
            placeholder={props?.placeholder}
          />
        ):(
          <JavSelect
            selected={props.selected}
            onChange={props.onChange}
            bgColor={props.bgColor}
            items={props.code ? remoteItems : props.items}
            position={props.position}
            hideBorder={props.isColumn ? true : props.hideBorder}
            value={value}
            placeholder={props?.placeholder}
          />
        )}
      </div>
    </div>
  );
}
