export default function JavLabel(props) {
    return (
        <div className={"tw-flex tw-flex-col  tw-rounded-xl"}>
            <span className={"tw-text-lg tw-text-black tw-font-satoshiBold tw-bg-text-500"}>{props?.title}</span>
            {
                props?.render && props?.render()
            }
            {
                !props?.render &&
                <span className={`${ props?.valueFont ? props?.valueFont : 'tw-font-satoshiBlack' }  
                            ${props?.valueSize ? props?.valueSize: 'tw-text-xl'}
                            tw-text-gray-800 tw-mt-1 `}>
                    {props.value}
                </span>
            }


        </div>
    )
}