export default function FilterIcon (props) {
    return (
        <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M7.5 8H13.5" stroke="current" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 8H5.5" stroke="current" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5 3.5H13.5" stroke="current" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 3.5L10.5 3.5" stroke="current" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.5 2V5" stroke="current" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.5 12.5H13.5" stroke="current" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 12.5H8.5" stroke="current" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 14V11" stroke="current" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.5 6.5V9.5" stroke="current" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}