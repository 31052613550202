export const SET_PIN_REQUEST = "SET_PIN_REQUEST"
export const SET_PIN_SUCCESS = "SET_PIN_SUCCESS"
export const SET_PIN_ERROR = "SET_PIN_ERROR"
export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_CURRENCIES = "GET_CURRENCIES"
export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST"
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR"
export const GET_CURRENCIES_REQUEST = "GET_CURRENCIES_REQUEST"
export const GET_CURRENCIES_ERROR = "GET_CURRENCIES_ERROR"
export const GET_BANKS_AND_MOMOS_REQUEST = "GET_BANKS_AND_MOMOS_REQUEST"
export const GET_BANKS_AND_MOMOS_SUCCESS = "GET_BANKS_AND_MOMOS_SUCCESS"
export const GET_BANKS_AND_MOMOS_ERROR = "GET_BANKS_AND_MOMOS_ERROR"
export const ADD_DEFAULT_CURRENCIES = "ADD_DEFAULT_CURRENCIES"

export const GET_WALLETS_ERROR = "GET_WALLETS_ERROR"
export const GET_WALLETS_SUCCESS = "GET_WALLETS_SUCCESS"
export const GET_WALLETS_REQUEST = "GET_WALLETS_REQUEST"

export const SAVE_FUNDING_SOURCE_REQUEST = "SAVE_FUNDING_SOURCE_REQUEST"
export const SAVE_FUNDING_SOURCE_SUCCESS = "SAVE_FUNDING_SOURCE_SUCCESS"
export const SAVE_FUNDING_SOURCE_ERROR = "SAVE_FUNDING_SOURCE_ERROR"
export const GET_FUNDING_SOURCE = "GET_FUNDING_SOURCE"
export const GET_CONVERSION_SUCCESS = "GET_CONVERSION_SUCCESS"
export const GET_USER_SECURITY_QUESTIONS_SUCCESS = "GET_USER_SECURITY_QUESTIONS_SUCCESS"
export const GET_ALL_SECURITY_QUESTIONS_SUCCESS = "GET_ALL_SECURITY_QUESTIONS_SUCCESS"
export const SET_SECURITY_QUESTION_SUCCESS = "SET_SECURITY_QUESTION_SUCCESS"
export const SET_SECURITY_QUESTION_ERROR = "SET_SECURITY_QUESTION_ERROR"
export const SET_SECURITY_QUESTION_REQUEST = "SET_SECURITY_QUESTION_REQUEST"
export const VERIFY_IDENTITY_ERROR = "VERIFY_IDENTITY_ERROR"
export const VERIFY_IDENTITY_SUCCESS = "VERIFY_IDENTITY_SUCCESS"
export const VERIFY_IDENTITY_REQUEST = "VERIFY_IDENTITY_REQUEST"
export const SETCURRENT_ROUTE = "SETCURRENT_ROUTE"
export const GET_RULES_CURRENCIES = "GET_RULES_CURRENCIES"
export const ADD_WALLET = "ADD_WALLET"


export const RUNNING_HEADER_REQUEST = "RUNNING_HEADER_REQUEST"
export const RUNNING_HEADER_SUCCESS = "RUNNING_HEADER_SUCCESS"
export const RUNNING_HEADER_ERROR = "RUNNING_HEADER_ERROR"


    
    


