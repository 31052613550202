import JavInput from "../../../../Shared/Components/Forms/JavInput";
import JavAmountLabel from "../../../../Shared/Components/Forms/JavAmountLabel";
import JavButton from "../../../../Shared/Components/Forms/JavButton";
import {useContext, useState} from "react";
import {currencyFormat} from "../../../../helpers/utils";
import {LoanTabsContext} from "./LoanTabsContext";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";

export default function LoanConsignmentInfo(props){

    const {tabs,setTabs} = useContext(LoanTabsContext);

    const [form,setForm] = useState({
        expected_number_of_trucks: 1,
        expected_number_of_bags_per_truck: tabs?.consignmentInfo?.expected_number_of_bags_per_truck
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const validateForm = () => {
        return Object.values(form).filter(item => item === null).length === 0;
    }


    return (
        <div className={"tw-flex tw-flex-col"}>

            <div>
                <span className={"tw-font-satoshiBold tw-text-2xl"}><span className={"tw-font-satoshi tw-text-gray-500"}>Step 1:</span> Add bag & truck details</span>
            </div>


            <div className={"tw-grid tw-grid-cols-2 tw-gap-4 tw-my-8"}>

                {/*<JavInput*/}
                {/*    title={"Number of Trucks"}*/}
                {/*    name={"expected_number_of_trucks"}*/}
                {/*    value={form?.expected_number_of_trucks}*/}
                {/*    onChange={handleChanges}*/}
                {/*/>*/}

                <div className={"tw-col-span-2"}>

                    <JavInput
                        title={"Number of bags per truck"}
                        name={"expected_number_of_bags_per_truck"}
                        value={form?.expected_number_of_bags_per_truck}
                        onChange={handleChanges}
                    />


                </div>


                <JavInput
                    name={"weight_per_bag"}
                    value={props?.data?.weight_per_bag}
                    title={"Weight of bag (KG)"}
                    disabled={true}
                />

                <JavInput
                    disabled={true}
                    title={"Cost of bag (GH₵)"}
                    name={"cost_price_per_bag"}
                    value={props?.data?.cost_price_per_bag}
                />

                <div className={"tw-col-span-2"}>
                    <JavInput
                        title={"Total number of bags"}
                        value={form?.expected_number_of_bags_per_truck * form?.expected_number_of_trucks}
                        disabled={true}
                        name={"expected_number_of_bags"}
                    />
                </div>

                <div className={"tw-col-span-2"}>

                    <JavFormSelect
                        title={`Select Commodity`}
                        common_code={"CT001"}
                        code={"CT001"}
                        codeField={"code_name"}
                        position={"bottom"}
                        onChange={(item) => {
                            setForm({
                                ...form,
                                commodity_id: item?.id
                            })
                        }}
                    />
                </div>

                <div className={` ${ props?.data?.partial_disbursement_percentage < 100 ? 'tw-col-span-1' : 'tw-col-span-2'}`}>

                    <JavAmountLabel
                        title={"Loan Amount (100%)"}
                        value={`GHS ${currencyFormat((form?.expected_number_of_bags_per_truck * form?.expected_number_of_trucks) * props?.data?.cost_price_per_bag, 2)}`}
                    />

                    {
                        props?.data?.partial_disbursement_percentage < 100 &&
                        <JavAmountLabel
                            title={`Initial Disbursement Amt (${props?.data?.partial_disbursement_percentage}%)`}
                            value={`GHS ${currencyFormat(((form?.expected_number_of_bags_per_truck * form?.expected_number_of_trucks) * props?.data?.cost_price_per_bag) * (props?.data?.partial_disbursement_percentage / 100), 2)}`}
                        />
                    }
                </div>



                <div className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-8"}>
                    <JavButton
                        title={"Cancel"}
                        textColor={"tw-gray-500"}
                        bgColor={"tw-bg-transparent"}
                        className={"tw-border"}
                        onClick={props?.onCancelClicked}
                    />
                    <JavButton title={"Continue"} textColor={"tw-text-white"} onClick={() => {
                        if (validateForm()) {
                            const updateTabs = [...tabs.tabs];
                            updateTabs[props?.index].completed = true;
                            const updatedTabsHolder = {
                                ...tabs,
                                tabs: updateTabs,
                                selectedIndex: (props?.index ?? 0) + 1,
                                consignmentInfo: {...form, ...props?.data}
                            };
                            setTabs(updatedTabsHolder);
                        } else {
                            alert("Required field is empty")
                        }
                    }}/>
                </div>

            </div>

        </div>
    )
}