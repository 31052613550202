import React from "react";
import ThemeStyles from "../../../../../style/Auth.module.css";
import {Button, Input, Form} from "antd";
import Logo from "../../../../../assets/javolin_logo.png";
import 'react-intl-tel-input/dist/main.css';
import {Link, useHistory} from "react-router-dom";
import {login, SET_USER_TYPE} from "../../duck/action";
import {useDispatch, useSelector} from "react-redux";
import LangDropDown from "../../../../../Shared/Components/LangDropDown";
import Cookies from "js-cookie";

const ForexLogin = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userLoginState = useSelector((state) => state.login);
    const text = useSelector((state) => state?.language)

    const onFinish = async (values) => {
        dispatch({
            type: SET_USER_TYPE,
            data: 'business'
        });
        let res = await dispatch(login(values, history, 'business'));
        setTimeout(async () => {
            const accessToken = Cookies.get("javAccessToken");
            console.log("accessToken",accessToken)
            window.location.href = "/agric/inventory"
        },2000)
    };


    return (
        <>

            <div className={ThemeStyles.cardContent}>
                <div className={ThemeStyles.smallScreenLogo}>
                    <img src={Logo} alt="javolin logo"/>
                </div>
                <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <div className={ThemeStyles.topSection}>
                        <div style={{marginBottom: "1em"}}>
                            <LangDropDown fullname/>
                        </div>

                        <div className={ThemeStyles.authTitle}>
                            {text["Sign in"]}
                        </div>
                        <div className={ThemeStyles.subauthTitle}>
                            {text["Sign in to start trading with us"]}
                        </div>

                    </div>
                    <Form.Item
                        label={text["Email"]}
                    >
                        <Form.Item
                            noStyle
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: `${text["Email"]} ${text["is required"]}`,
                                    type: "email"
                                },
                                {
                                    message: "Your email be be a business domain email eg. jamesjones@javolin.com"
                                }

                            ]}
                        >

                            <Input size="large" placeholder="Eg. jamesjones@javolin.com"/>
                        </Form.Item>

                    </Form.Item>
                    <Form.Item
                        label={text["Password"]}
                    >
                        <Form.Item
                            noStyle
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: text["Please input your password!"],
                                }
                            ]}
                            hasFeedback
                        >
                            <Input.Password size="large" type={'password'} placeholder={text["password"]}/>
                        </Form.Item>
                    </Form.Item>
                    <div style={{textAlign: "right", fontWeight: 450, fontSize: "20px"}}>
                        <Link to='/forgot-password'>{text["Forgot Password?"]}</Link>
                    </div>
                    <Button
                        block
                        size="large"
                        style={{marginTop: "2em"}}
                        type="primary"
                        shape="round"
                        htmlType="submit"
                        className="login-form-button"
                        loading={userLoginState.loggingIn}
                    >
                        {text["Login"]}
                    </Button>

                </Form>

                <div className={ThemeStyles.footerMsg}>
                    <div className={ThemeStyles.footerContent}>
                        {text["DONT_HAVE_AN_ACCOUNT"]} <Link to={'/business/signUp'}> <span
                        className={ThemeStyles.footerLink}>{text["Create account"]}</span> </Link>
                    </div>
                </div>

            </div>

        </>
    );
};

export default ForexLogin;
