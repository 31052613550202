import React, { useState } from "react";
import JavInput from "../../../Shared/Components/Forms/JavInput";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeJavolinRequest } from "../../../helpers/utils";
import JavButton from "../../../Shared/Components/Forms/JavButton";
import JavFormSelect from "../../../Shared/Components/Forms/JavFormSelect";

const RegistrationForm = () => {
  const history = useHistory();

  const [register, setRegister] = useState({
    loading: false,
  });
  const [form, setForm] = useState({
    file: null,
  });

  const handleChanges = (event) => {
    const value = event.target.value;
    setForm({
      ...form,
      [event.target.name]: value,
    });
  };

  const onRegisterClicked = () => {
    setRegister({ ...register, loading: true });
    makeJavolinRequest(
      {
        path: "/business/register",
        method: "POST",
        data: { ...form, business_address: "business_address", currency_id: 2 },
      },
      null,
      null,
      (data) => {
        setRegister({ ...register, loading: false, data: data });
        alert("User Registered successfully");
        history.push("/login");
        console.log("data", data);
      },
      (error) => {
        setRegister({ ...register, loading: false, error: error });
        alert("Error: " + error);
        console.log("error", error);
      }
    );
  };

  return (
    <div className="tw-flex tw-bg-white tw-overflow-hidden tw-min-h-screen tw-min-w-full  tw-w-full md:tw-w-3/4 lg:tw-w-2/3 xl:tw-w-2/3 tw-hide-scrollbar">
        <div className="tw-w-full md:tw-w-1/2 tw-p-8 lg:tw-px-36 md:tw-px-20  tw-content-center">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-start">
            <img src="/inv_logo.png" alt="Logo" className="tw-w-32 tw-mb-4" />
            <h2 className="tw-text-4xl tw-font-satoshiBold tw-mb-2">Welcome</h2>
            <p className="tw-text-gray-600 tw-font-satoshiMedium tw-text-xl tw-mb-4">
              Register to start using our Inventory System
            </p>
          </div>
          <div className="tw-mt-8 tw-grid tw-gap-2 tw-grid-cols-1">
            <JavInput
              title={"Name of Business"}
              name={"business_name"}
              value={register.business_name}
              onChange={handleChanges}
            />

            <JavFormSelect
              title={`Select Business Type`}
              placeholder={"Select Business Type "}
              common_code={"UAT001"}
              code={"UAT001"}
              codeField={"code_name"}
              position={"bottom"}
              onChange={(item) => {
                setForm({ ...form, business_type: item.id });
              }}
            />

            <JavInput
              title={"Full Name"}
              value={form.full_name}
              name={"full_name"}
              onChange={handleChanges}
            />

            <JavInput
              title={"Email"}
              name={"email"}
              value={form.email}
              onChange={handleChanges}
            />

            <JavInput
              title={"Phone Number"}
              name={"phone_number"}
              value={form.phone_number}
              onChange={handleChanges}
            />

            <JavInput
              title={"Password"}
              name={"password"}
              type={"password"}
              onChange={handleChanges}
              value={form.password}
            />

            <div
              className={"tw-flex  tw-font-proxima tw-mt-2 tw-items-center "}
            >
              <input type={"checkbox"} />
              <span className={"tw-mx-1 tw-text-sm"}>
                I agree to{" "}
                <span className={"tw-text-jav-card-300"}>
                  Terms and Privacy Policy
                </span>
              </span>
            </div>

            <div className={"tw-flex mt-1"}>
              <JavButton
                title={"Continue"}
                textColor={"tw-text-white"}
                className={"tw-w-full tw-h-12 tw-text-lg"}
                isLoading={register.loading}
                onClick={onRegisterClicked}
              />
            </div>
          </div>
          <p className="tw-text-center tw-text-lg tw-font-satoshiBold tw-text-gray-600 tw-pt-2">
            Already have an account?{" "}
            <a className="tw-text-green-500 tw-mx-1 tw-text-xl tw-cursor-pointer" href="/login">
              Sign In
            </a>
          </p>
        </div>

        <div className="tw-hidden md:tw-block md:tw-w-2/3 tw-bg-cover tw-bg-center tw-p-4 tw-hide-scrollbar">
          <div
            className="tw-h-full tw-rounded-lg tw-overflow-hidden"
            style={{
              backgroundImage: `url('/bg_2.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>

      </div>
  
  );
};

export default RegistrationForm;
