import {Layout, Row, ConfigProvider, Table, Tag, Spin, Button} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormat, customDatFormat } from "../../helpers/utils";
import {getLoans} from "./duck/action";
import LoanTransactionDetail from "./LoanTransactionDetails";
import { useHistory } from "react-router";





const LoansPage = () => {
    const { Content } = Layout;


    const transactions = useSelector((state)=> state.loans?.fetch?.data)
    const users = useSelector((state)=> state?.userMgt?.users)
    const loading = useSelector((state)=> state?.userMgt?.approveTranx)
    const currencies = useSelector((state) => state?.resources?.defaultCurrencies)
    const [currencyCode, setCode] = useState("KES")
    const history = useHistory();


    const [transDetails, setTrans] = useState()

    const dispatch = useDispatch()
    const text = useSelector((state)=> state.language)


    useEffect(()=>{
        dispatch(getLoans())
    }, [dispatch])

    useEffect(() => {
        console.log("transactions",transactions);
    },[transactions])


    const customizeRenderEmpty = () => (
        <div style={{ textAlign: 'center' }}>
            <p>{text["You don't have any pending transactions"]}</p>
        </div>
    );


    const handleDecline = (e)=>{
        // dispatch(approveTransaction(e))
    }

    const handleView = (e)=>{
        const details = transactions[e]
        console.log("pending: ", details)
        let info = {
            ...details,
        }
        setTrans(info)
        setVisible(true)
    }

    const handleInstructDeal = ()=>{
        console.log("instruct detail")
        history.push({
            pathname: "/business/request",
            state: {
                currency: "GHS",
                rate: {
                    sender: {
                        amount: 10.0
                    },
                    recipient: {
                        amount: 5.0
                    },
                    javolin_rate: [1,1.0]
                },
                deal: {}
            }
        })
    }

    const handleApprove = (e)=>{
        console.log(e)
        // dispatch(approveTransaction(e))
    }

    const [isVisible, setVisible] = useState(false)


    const columns = [
        {
            title: "Loan Id",
            dataIndex: "reference",
            key: "reference",
        },
        {
        title: "Loan Type",
        dataIndex: "loan_type",
        key: "loan_type",
         },
        {
            title: text["Amount"],
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: text["Date"],
            dataIndex: "date",
            key: "date",
        },
        {
            title: text["User"],
            dataIndex: "user",
            key: "user",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: text["Action"],
            key: "action",
            render: (action) => {
                return (
                    <>
                        <Tag onClick={()=> handleView(action?.key)} style={{  padding: "10px", cursor:"pointer" }} color="gold">{text["view"]}</Tag>
                    </>
                );
            },
        },
    ];

    let tableData = transactions
        ? Object.values(transactions).map((trans) => {
            return {
                key: trans.id,
                user: trans?.business?.full_name,
                status: trans.status.replaceAll("_"," "),
                reference: trans?.reference,
                loan_type: trans?.loan_type?.code_name,
                date:  customDatFormat(trans?.created_at) ,
                amount: `${trans.currency} ${currencyFormat(trans?.amount)}`,
                action: trans?.id
            };
        })
        : [];



    return (
        <>
            <Content
                style={{
                    minHeight: 300,
                    padding: "50px",
                    margin: "1em",
                }}
            >
                <LoanTransactionDetail details={transDetails} setReview={setVisible} showReview={isVisible} />


                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "6px" }}>

                    <Button style={{background: "#0032A0", fontSize: "15px"}}  onClick={handleInstructDeal} type="primary" size="large" shape="round" >
                        Request Loan
                    </Button>

                </div>


                <Row>

                    <ConfigProvider renderEmpty={customizeRenderEmpty}>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            pagination={{ pageSize: 50 }}
                            scroll={{ y: 600 }}
                        />
                    </ConfigProvider>

                </Row>
            </Content>
        </>
    );
};

export default LoansPage;
