import React from "react";
import {Route} from "react-router-dom";
import NewApplicationToolbar from "./NewApplicationToolbar";

export default function NewApplicationRouteLayout({component: Component}) {

    return (
        <Route
            render={(props) => (
                <div
                    className={
                        "tw-min-h-screen top-0  tw-flex tw-w-screen tw-h-screen "
                    }
                >
                    <div className="tw-relative tw-flex-1 tw-overflow-hidden tw-overflow-y-auto tw-overflow-x-hidden">
                        <NewApplicationToolbar/>

                        <div className="md:tw-mt-6 tw-mx-1 md:tw-mx-4">
                            <Component {...props} />
                        </div>
                    </div>
                </div>
            )}
        />
    );
}
