


import { Button, Modal } from "antd"
import Styles from "../../Shared/Components/Menu/Menu.module.css"
import { XIcon } from "../../Shared/Components/JavIcons";
import JavContentTitle from "../../Shared/Components/JavContentTitle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getWireInstructions } from "./duck/action";



const BankWireMemoPopUp = ({ currencyCode, transactionId, isVisible, setVisible, reference }) => {


    const [wireInfo, setWireInfo] = useState({})
    const dispatch= useDispatch()

    useEffect(()=>{
        if(currencyCode){
            dispatch(getWireInstructions(currencyCode)).then((res)=>{
                console.log(res)
                setWireInfo(res)
            })
        }
    },[currencyCode, dispatch])

   const businessName = useSelector((state)=>state.user.business_name)

    const {local, international} = wireInfo
    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <>

            <Modal open={isVisible} onCancel={handleCancel}
                footer={false}
                centered
                closeIcon={
                    <div className="circle-close">
                        <XIcon width="1em" height="1em" />
                    </div>
                }
                bodyStyle={
                    {
                        padding: 0,
                        border: "16px 16px 0 0"
                    }
                }

                width="1000px"
            >
                <div className={Styles.jav_modal_header}>
                    <div className={Styles.jav_modal_secTitle}>Banks wire instructions</div>
                    <div style={{color: "white", paddingLeft: "0.5em", fontWeight: "bold"}} >{transactionId}</div>
                </div>

                <div style={{ display: "flex", padding: "2em 1em", flexDirection: "column", alignItems: "right", justifyContent: "center" }}>
                    <div>
                        <p>Dear valued customer,</p>
                        <p>To fund your account on Javolin via bank, you need to wire the money to either one of our bank accounts with details below: <span style={{ color: "red" }}>{reference}</span> </p>
                        <JavContentTitle title="Local Account" />
                        <p>Bank name: <span style={{ fontWeight: "bold" }}>{local?.bank_name}</span> </p>
                        <p>Address: <span style={{ fontWeight: "bold" }}>{local?.address}</span> </p>
                        <p>Account  name: <span style={{ fontWeight: "bold" }}>{local?.account_name}</span> </p>
                        <p>Account  number: <span style={{ fontWeight: "bold" }}>{local?.account_number}</span> </p>
                        <p>Swift (optional): <span style={{ fontWeight: "bold" }}>{local?.swift}</span> </p>
                        <JavContentTitle title="International Account" />
                        <p>Bank name: <span style={{ fontWeight: "bold" }}>{international?.bank_name}</span> </p>
                        <p>Address: <span style={{ fontWeight: "bold" }}>{international?.address}</span> </p>
                        <p>Account  number: <span style={{ fontWeight: "bold" }}>{international?.account_number}</span> </p>
                        <p>Beneficiary: <span style={{ fontWeight: "bold" }}>{international?.beneficiary}</span> </p>
                            <p>IBAN #: <span style={{ fontWeight: "bold" }}>{international?.iban} </span> </p>
                        <p>Beneficiary Bank SWIFT : <span style={{ fontWeight: "bold" }}>{international?.beneficiary_bank_swift}</span> </p>
                        <p>Correspondent Bank SWIFT : <span style={{ fontWeight: "bold" }}>{international?.correspondent_bank_swift} </span> </p>
                        <p>Reference : <span style={{ fontWeight: "bold" }}> {`${international?.reference} | ${businessName} partner`} </span> </p>
                        <p> <span style={{ fontWeight: "bold" }}>Important</span>: 
                            <li>
                            The name on your bank account must match the name on your JAVOLIN account.
                            </li>
                            <li>
                            If making multiple deposits over a short period, send different amounts to avoid delays.
                            </li>
                        
                         </p>
                    </div>

                    <div width="100%" style={{ textAlign: "center" }}>
                        <Button
                            block
                            type="primary"
                            size="large"
                            shape="round"
                            onClick={handleCancel}
                            style={{ maxWidth: "400px" }}
                        >
                            Okay
                        </Button>
                    </div>


                </div>

            </Modal>
        </>

    )

}


export default BankWireMemoPopUp