import JavInput from "../../../Forms/JavInput";
import React, {useEffect, useState} from "react";
import JavFormSelect from "../../../Forms/JavFormSelect";
import JavButton from "../../../Forms/JavButton";
import {useDispatch, useSelector} from "react-redux";
import {bookRate, getSpotRate} from "../../../../../Modules/TransferMoney/duck/action";
import {currencyFormat} from "../../../../../helpers/utils";
import Styles from "../../../Menu/Menu.module.css";
import ConfirmDialog from "../../../Dialog/ConfirmDialog";
import {useHistory} from "react-router";

export default function ApplicationSpotRateCalculator() {
    const dispatch = useDispatch()
    const currencies = useSelector((state) => state?.resources?.rules_currencies)
    const rateLoading = useSelector((state) => state?.transfer?.gettingRate)
    const bookingRate = useSelector((state) => state?.transfer?.bookingRate)
    const [spotRate,setSpotRate] = useState({})
    const [form,setForm] = useState({
        file: null,
        canBookRate: false
    });
    const history = useHistory()



    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onCheckRateClicked = () => {

        dispatch(getSpotRate({from: form?.from, to: form?.to, lock: "to", value: form?.value}))
            .then(rate => {
                if (rate) {
                    setSpotRate({
                        ...spotRate,
                        rate: rate,
                    })
                    setForm({...form,canBookRate: true})
                }
            }).catch(err => {
            console.log("error",err);
            })

    }

    const onBookRateClicked = () => {
        dispatch(bookRate(
            {
                "quote_id": spotRate?.rate?.quoteId,
            }
        )).then((val) => {
            setSpotRate({
                ...spotRate,
                deal: val?.response
            })
        })
    }

    return (
        <div className={"tw-p-5 tw-border tw-rounded-lg tw-border-blue-400 tw-bg-white"}>

            <span className={"tw-font-proximaBold tw-text-2xl"}>Spot Rate</span>

            <div className={"tw-grid tw-grid-cols-2 tw-gap-4 tw-my-2"}>

                <JavFormSelect
                    title={"Buy"}
                    name={"name"}
                    value={form?.name}
                    position={"bottom"}
                    items={currencies?.map((currency) => ({
                        ...currency,
                        title: currency.name,
                    }))}
                    onChange={(currency) => {
                        setForm({
                            ...form,
                            to: currency?.id
                        })
                    }}
                />


                <JavFormSelect
                    title={"Sell"}
                    name={"name"}
                    value={form?.name}
                    position={"bottom"}
                    items={currencies?.map((currency) => ({
                        ...currency,
                        title: currency.name,
                    }))}
                    onChange={(currency) => {
                        setForm({
                            ...form,
                            from: currency?.id
                        })
                    }}
                />

                <div className={"tw-col-span-2"}>
                    <JavInput
                        title={`Amount ${ form?.to ? `( ${form?.to } )` : ''}`}
                        name={"value"}
                        value={form?.value}
                        onChange={handleChanges}
                    />
                </div>

                <div className={`tw-grid ${form?.canBookRate ? 'tw-grid-cols-2' : 'tw-grid-cols-1'}  tw-col-span-2 tw-gap-4 tw-mt-4`}>

                    <JavButton
                        title={"Check Rate"}
                        textColor={"tw-text-white"}
                        className={"tw-h-12"}
                        isLoading={rateLoading}
                        onClick={onCheckRateClicked}
                    />


                    {
                        form?.canBookRate &&
                        <JavButton
                            title={"Book Rate"}
                            textColor={"tw-text-white"}
                            className={"tw-border tw-h-12"}
                            onClick={onBookRateClicked}
                        />
                    }


                </div>


                <div
                    className={"tw-p-4 tw-bg-blue-50 tw-col-span-2 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-h-16 tw-border tw-border-blue-400"}>

                    {
                        spotRate?.rate &&
                        <div className={"tw-flex tw-flex-col tw-items-center"}>
                            <span className={"tw-font-satoshiBold tw-text-black tw-text-lg"}>
                                {spotRate?.rate?.sender?.currency}
                                {currencyFormat(spotRate?.rate?.sender?.amount)} / {spotRate?.rate?.recipient?.currency} {currencyFormat(spotRate?.rate?.recipient?.amount)}
                            </span>
                            <span className={"tw-text-sm"}>
                                {spotRate?.rate ? `${Number(spotRate?.rate?.javolin_rate[1]).toFixed(4)} / ${Number(spotRate?.rate?.javolin_rate[0]).toFixed(4)}` : ''}
                            </span>
                        </div>
                    }

                    {
                        !spotRate?.rate &&
                        "Rate result appear here..."
                    }

                </div>

            </div>

            <ConfirmDialog
                isOpen={!!spotRate?.deal}
                title={"Deal Booked"}
                handleCancel={() => setSpotRate({...spotRate,deal: null})}
                onlyPositiveButton={true}
                message={
                    <span className="">
                        Your deal has been booked. Your deal number is <span className={"tw-font-proximaBold tw-text-red-600"}>{spotRate?.deal?.orderNumber}</span>
                    </span>
                }
                handleConfirm={() => {
                    history.push({
                        pathname: "/business/instruct-forex",
                        state: {
                            rate: spotRate?.rate,
                            deal: spotRate?.deal
                        }
                    })
                }}
                requestLoading={bookingRate}
            />


        </div>
    )
}