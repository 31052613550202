import React, {forwardRef} from 'react'
import Table from '../Table'


function DefaultTable(props,ref) {
  return (
    <div className="dark:bg-[#242A38] tw-bg-white dark:border-gray-500 tw-border tw-py-2 tw-mb-4 tw-rounded-xl tw-shadow-sm tw-px-2">
      <Table {...props}
          ref={ref}
      />
  </div>
  )
}

export default forwardRef(DefaultTable)