import Dialog from "../../../../../../Shared/Components/Dialog/Dialog";
import JavInput from "../../../../../../Shared/Components/Forms/JavInput";
import {useEffect, useState} from "react";
import JavButton from "../../../../../../Shared/Components/Forms/JavButton";
import JavTextArea from "../../../../../../Shared/Components/Forms/JavTextArea";
import {makeJavolinRequest} from "../../../../../../helpers/utils";
import JavFormFileInput from "../../../../../../Shared/Components/Forms/JavFormFileInput";
import {fetchLoanContractDetails} from "../../../../duck/action";
import {useDispatch} from "react-redux";

export default function BLDialog(props) {

    const [uploadBl,setUploadBl] = useState({
        loading: false,
        data: null
    })
    const [form,setForm] = useState({
        bl_number: ""
    });
    const dispatch = useDispatch();

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const onCloseClicked = () => {
        setForm({})
        props?.onCloseClicked();
    }

    const onUploadBlTapped = () => {

        setUploadBl({...uploadBl,loading: true})
        makeJavolinRequest({
            path: `/business/loan-contracts/${props?.contract?.id}/updateBl`,
            method: "POST",
            data: {
                file: form?.file?.file,
                bl_number: form?.bl_number,
                shipping_line: 1
            }
        },null,null,(data) => {
            setUploadBl({...uploadBl,loading: false,data: data})
            setForm({...form,blNumber: null})
            alert("Bl Updated Successfully")
            const contractReference = window.location.href.split("/")?.[6]
            dispatch(fetchLoanContractDetails(contractReference))
            onCloseClicked();
        },(error) => {
            setUploadBl({...uploadBl,loading: false})
            console.log("error",error)
        })
    }

    // useEffect(() => {
    //     dispatch(getDataForTable({link: `/business/loans?contract_id=${props?.data?.id}`},`api.loan-contract-loans-${props?.contract?.id}`))
    // }, []);

    return (
        <Dialog position={"top"} style={{width: '30%'}} open={props?.open} onCloseClicked={onCloseClicked}
                title={"Carry In"}>

            <div className={"tw-flex tw-flex-col tw-gap-4 tw-mt-3"}>

                <JavInput
                    title={"Bl Number"}
                    name={"bl_number"}
                    value={form?.bl_number}
                    onChange={handleChanges}
                />

                <JavFormFileInput
                    title={"BL File"}
                    meta={form?.file?.meta}
                    data={form?.file}
                    onChange={(fileChange) => {
                        setForm({ ...form, file: fileChange });
                    }}
                />

                <div className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-4"}>
                    <JavButton
                        title={"Cancel"}
                        textColor={"tw-gray-500"}
                        bgColor={"tw-bg-transparent"}
                        className={"tw-border"}
                        onClick={props?.onCancelClicked}
                    />
                    <JavButton
                        title={"Upload BL"}
                        textColor={"tw-text-white"}
                        isLoading={uploadBl?.loading}
                        onClick={onUploadBlTapped}
                    />
                </div>

            </div>


        </Dialog>
    )
}