import {
    HomeNavIcon,
    PendingTransactionNavIcon,
    UserManagementNavIcon,
    WalletNavIcon,
} from "../JavIcons";


const ApplicationMenu = () => {
    return {
        "forex": [
            {
                "icon": HomeNavIcon,
                "title": "Dashboard",
                "route": "/"
            },
            {
                "icon": WalletNavIcon,
                "title": "Wallet",
                "route": "/business/wallet"
            },
            {
                "icon": PendingTransactionNavIcon,
                "title": "Pending Transactions",
                "route": "/business/requests"
            },
            {
                title: "User Management",
                items: [
                    {
                        "icon": UserManagementNavIcon,
                        "title": "User",
                        "route": "/user-management"
                    },
                    {
                        "icon": UserManagementNavIcon,
                        "title": "Roles",
                        "route": "/role-management"
                    }
                ]
            }
        ],
        "agritech": [
            {
                "icon": HomeNavIcon,
                "title": "Dashboard",
                "route": "/agric/inventory"
            },
            {
                "icon": WalletNavIcon,
                "title": "Warehouses",
                "route": "/agric/warehouse"
            }
        ]
    }
}

export default ApplicationMenu;