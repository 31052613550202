import React, {useEffect, useState} from "react";
import JavButton from "../../../Shared/Components/Forms/JavButton";
import AddNewInventoryDialog from "./AddInventoryDialog";
import {
    currencyFormat,
    makeJavolinGetHttpRequest
} from "../../../helpers/utils";
import AgricWarehouse from "../Warehouse/AgricWarehouse";
import AddWarehouseReview from "../Warehouse/AddWarehouse";
import JavFormSelect from "../../../Shared/Components/Forms/JavFormSelect";

const InventoryDashboard = () => {
    const [activeTab, setActiveTab] = useState("home");
    const [warehouseData, setWarehouseData] = useState({
        loading: false,
        data: null,
    });
    const [dashboard, setDashboard] = useState({
        loading: false,
        data: null,
    });
    const [requestDetails, setRequestDetails] = useState({
        hasOpen: false,
        data: null,
    });
    const [filterForm, setFilterForm] = useState({
        warehouse_id: null,
        commodity_id: null
    })
    const [isOpen, setIsOpen] = useState({hasOpen: false, id: null});

    const handleConfirm = (id) => {
        setIsOpen({...isOpen, hasOpen: true, id: id});
    };

    const closeModal = (id) => {
        setIsOpen({...isOpen, hasOpen: false, id: id});
    };

    const closeModalAdd = (id) => {
        setRequestDetails({...requestDetails, hasOpen: false, data: id});
    };

    const handleModal = (id) => {
        setRequestDetails({...requestDetails, hasOpen: true, data: id});
    };

    const inventorySummary = [
        {
            label: "Total Bags",
            bgColor: "tw-bg-jav-msc-color-300",
            icon: "/fi_3556920.svg",
        },

        {
            label: "Total Value",
            bgColor: "tw-bg-jav-msc-color-500",
            icon: "/TipJar.svg",
        },
        {
            label: "Total Quantity(kg)",
            bgColor: "tw-bg-jav-msc-color-400",
            icon: "/ShoppingCart.svg",
        },
        {
            label: "Total Location",
            bgColor: "tw-bg-jav-msc-color-600",
            icon: "/Warehouse.svg",
        },
    ];

    const loadDashboard = () => {
        setDashboard({...dashboard, loading: true});
        makeJavolinGetHttpRequest(
            `/business/agric/dashboard`
            , filterForm,
            (data) => {
                setDashboard({...dashboard, loading: false, data: data});
            },
            (error) => {
                setDashboard({...dashboard, loading: false, error: error});
                console.log("error", error);
            }
        );
    };

    const loadWarehouses = () => {
        setWarehouseData({...warehouseData, loading: true});
        makeJavolinGetHttpRequest(
            "/business/agric/warehouses", {},
            (data) => {
                setWarehouseData({...warehouseData, loading: false, data: data});
            },
            (error) => {
                setWarehouseData({...warehouseData, loading: false});
            }
        );
    };

    useEffect(() => {
        loadDashboard();
    }, [filterForm]);

    useEffect(() => {
        loadWarehouses();
    }, []);

    const onInventoryItemClicked = (inventory) => {
    };

    return (
        <>
            <div
                className="xl:tw-px-20 lg:tw-px-10 md:tw-px-5 sm:tw-px-5 tw-px-4 tw-py-3 tw-overflow-hidden  tw-overflow-y-auto">
                <div className="tw-flex tw-justify-between tw-items-center">
                    <div className="tw-flex tw-gap-6">
                        <div
                            onClick={() => {
                                setActiveTab("home");
                            }}
                            className={`${
                                activeTab === "home" ? "tw-border-b-4" : ""
                            } tw-border-jav-primary-200 tw-text-2xl tw-rounded-b-sm ${
                                activeTab === "home" ? "tw-font-satoshiBold" : "tw-font-satoshi"
                            } ${
                                activeTab === "home"
                                    ? "tw-text-jav-primary-200"
                                    : "tw-text-jav-active-600"
                            } tw-cursor-pointer`}
                        >
                            Home
                        </div>
                        <div
                            onClick={() => {
                                setActiveTab("warehouse");
                            }}
                            className={`${
                                activeTab === "warehouse" ? "tw-border-b-4" : ""
                            } tw-border-jav-primary-200 tw-text-2xl tw-rounded-b-sm ${
                                activeTab === "warehouse"
                                    ? "tw-font-satoshiBold"
                                    : "tw-font-satoshi"
                            } ${
                                activeTab === "warehouse"
                                    ? "tw-text-jav-primary-200"
                                    : "tw-text-jav-active-600"
                            }  tw-cursor-pointer tw-text-xl`}
                        >
                            Warehouse
                        </div>
                    </div>
                    <div className="tw-flex tw-items-center">
                        <div
                            className="tw-flex tw-mr-4 tw-rounded-full tw-cursor-pointer tw-border tw-shadow-xl tw-bg-white tw-border-jav-active-700 tw-mt-5  tw-px-7 tw-text-xl tw-font-satoshiBold ">
                            <JavFormSelect
                                placeholder={"All Warehouse"}
                                hideBorder={true}
                                position={"bottom"}
                                className={"tw-w-44"}
                                items={warehouseData?.data}
                                useNetwork={true}
                                onChange={(item) => {
                                    setFilterForm({
                                        ...filterForm,
                                        warehouse_id: item?.id
                                    })
                                }}
                            />

                        </div>
                        <div
                            className="tw-flex tw-mr-4 tw-rounded-full tw-cursor-pointer tw-border tw-shadow-xl tw-bg-white tw-border-jav-active-700 tw-mt-5  tw-px-7 tw-text-xl tw-font-satoshiBold ">
                            <JavFormSelect
                                placeholder={"All products"}
                                common_code={"CT001"}
                                code={"CT001"}
                                hideBorder={true}
                                codeField={"code_name"}
                                position={"bottom"}
                                className={"tw-w-36"}
                                onChange={(item) => {
                                    setFilterForm({
                                        ...filterForm,
                                        commodity_id: item?.id
                                    })
                                }}
                            />
                        </div>
                        <JavButton
                            bgColor={""}
                            textColor="tw-text-white"
                            className={
                                "tw-text-white tw-text-sm tw-h-9 tw-cursor-pointer tw-mt-5 tw-py-7 tw-px-7 sm:tw-text-2xl tw-rounded-full "
                            }
                            isLoading={false}
                            onClick={() => {
                                activeTab === "home" ? handleConfirm("1") : handleModal("");
                            }}
                        >
              <span className="">
                {activeTab === "home"
                    ? "Add new inventory"
                    : "Add new warehouse"}
              </span>
                        </JavButton>
                    </div>
                </div>

                {activeTab === "home" && (
                    <div className="tw-mt-3 tw-overflow-hidden">
                        <div className="tw-mb-10">
                            <h2 className="tw-text-3xl tw-font-satoshiBold tw-mb-4 tw-text-jav-msc-color-200">
                                Inventory Summary
                            </h2>
                            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-7">
                                {dashboard?.data?.summaries &&
                                    Object.keys(dashboard?.data?.summaries)?.map((key, index) => {
                                        let item = dashboard?.data?.summaries?.[key];

                                        return (
                                            <div
                                                key={index}
                                                className={`${inventorySummary[index].bgColor} tw-text-white tw-p-4 tw-rounded-lg tw-shadow-md`}
                                            >
                                                <div className="tw-flex tw-justify-between tw-items-start">
                                                    <div>
                                                        <div className="tw-text-2xl tw-font-satoshiBold tw-pb-3">
                                                            {index === 1 ? currencyFormat(item, 2) : item}
                                                        </div>
                                                        <div className="tw-text-lg">
                                                            {inventorySummary[index].label}
                                                        </div>
                                                    </div>
                                                    <img
                                                        className="tw-text-lg"
                                                        src={inventorySummary[index].icon}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>

                        <div>
                            <h2 className="tw-text-3xl tw-font-satoshiBold tw-mb-4 tw-text-jav-msc-color-200">
                                Inventory List
                            </h2>
                            <div
                                className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-x-10 tw-gap-y-4">
                                {dashboard?.data?.inventory?.map((item, index) => {
                                    let image = JSON.parse(item?.commodity?.code_option);
                                    return (
                                        <div
                                            key={index}
                                            className="tw-flex tw-flex-col sm:tw-flex-row tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-md tw-mb-4 tw-w-full"
                                            onClick={() => onInventoryItemClicked(item)}
                                        >
                                            <img
                                                src={image.image_url}
                                                alt={item?.commodity?.code_name || "Commodity image"}
                                                className="tw-w-full sm:tw-w-36 tw-h-36 tw-rounded-lg tw-mb-4 sm:tw-mb-0 sm:tw-mr-4 tw-object-cover"
                                            />
                                            <div className="tw-flex-1 tw-mt-2">
                                                <div className="tw-flex tw-justify-between tw-items-start">
                                                    <h3 className="tw-text-2xl tw-font-satoshiBold tw-text-jav-msc-color-200 tw-mb-2">
                                                        {item?.weight}
                                                        {item?.weight_unit}{" "}
                                                        {item?.commodity?.code_name || "Unknown Commodity"}
                                                    </h3>
                                                    <div
                                                        className="tw-ml-auto tw-flex tw-items-start sm:tw-items-center">
                                                    </div>
                                                </div>

                                                <div className="tw-flex tw-flex-col">
                                                    <div
                                                        className="tw-grid tw-grid-cols-2 tw-grid-flow-col  sm:tw-flex-row tw-gap-4 tw-mt-3">
                                                            <span className="tw-text-lg tw-font-satoshiMedium">
                                                                Total bags:{" "}
                                                                <span className="tw-text-xl tw-font-satoshiBold tw-text-black">
                                                                {" "} {item?.package || 0}{" "} {item?.package_unit?.toLowerCase() || "units"}
                                                                </span>
                                                            </span>
                                                        <span className="tw-text-lg tw-font-satoshiMedium">
                                                              Total weight:
                                                              <span className="tw-text-xl tw-font-satoshiBold tw-text-black">
                                                                {" "} {item?.weight || 0}{" "} {item?.weight_unit?.toLowerCase() || "kg"}
                                                              </span>
                                                            </span>
                                                    </div>
                                                    <div className="tw-flex tw-gap-6 tw-mt-4">
                                                        <div className="tw-text-xl tw-font-satoshiBold ">
                                                            {item?.warehouse?.name || "Unknown Warehouse"}
                                                        </div>
                                                        {" | "}
                                                        <div
                                                            className="tw-text-lg tw-font-satoshiMedium tw-text-jav-msc-color-800">
                                                            {item?.warehouse?.address || "Unknown Location"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}

                {activeTab === "warehouse" && (
                    <div className="tw-mt-4">
                        <AgricWarehouse/>
                        {/* Add warehouses content here */}
                    </div>
                )}
            </div>
            <AddNewInventoryDialog
                open={isOpen.hasOpen}
                isLoading={false}
                isLoadingApprove={false}
                onApproveClicked={() => {
                }}
                onCloseClicked={() => {
                    closeModal();
                }}
            />
            <AddWarehouseReview
                details={requestDetails.data}
                open={requestDetails.hasOpen}
                onDeclinedClick={() => {
                    closeModalAdd();
                }}
                onCloseClicked={() => {
                    closeModalAdd();
                }}
            />
        </>
    );
};

export default InventoryDashboard;
