import {forwardRef, useImperativeHandle, useState} from "react";
import JavButton from "../JavButton";

function JavTab(props,ref) {

    const [selectedIndex,setSelectedIndex] = useState(0);

    useImperativeHandle(ref,() => {
        return {
            setSelectedIndex: (index) => {
                setSelectedIndex(index)
            },
            moveForward: () => {
                if (props.children && (props.children.length - 1) > selectedIndex) {
                    setSelectedIndex(selectedIndex + 1)
                }
            },
            moveBackward: () => {
                if (selectedIndex > 0) {
                    setSelectedIndex(selectedIndex - 1)
                }
            },
            getCurrentIndex: () => {
                return selectedIndex
            }
        }
    },[selectedIndex]);


    const align = () => {
        switch (props?.align) {
            case "left":
                return 'tw-mx-2'
            default:
                return 'tw-flex-1'
        }
    }

    return (
        <div className={`md:tw-my-2 tw-flex tw-flex-col tw-rounded tw-border ${props.className}`}>
            <div className={`tw-w-full tw-items-center tw-overflow-hidden tw-flex ${props?.align === 'left' ? '' : 'tw-justify-around' }
                            tw-h-14 ${props?.bgColor ?? 'tw-bg-jav-primary-300'} tw-rounded-t dark:tw-bg-[#181E2C] tw-border-b tw-border-white`}>
                {
                    props.children && props.children.map((item,index) => {
                        return (
                            <div key={index} onClick={() => setSelectedIndex(index)}
                                 className={`tw-flex tw-text-xs md:tw-text-sm tw-cursor-pointer ${align()} tw-h-full tw-justify-center
                                  ${selectedIndex === index ? `tw-border-b-2 tw-font-satoshiBlack ${props?.activeTextColor ?? 'tw-text-white'} dark:tw-text-white` : `tw-font-satoshiMedium ${props?.normalTextColor ?? 'tw-text-gray-300'}`}
                                  tw-items-center`}>
                                {item.props.title}
                            </div>
                        )
                    })
                }

                {
                    props?.children[selectedIndex]?.props?.rightActions &&
                    <div className={"tw-flex-1 tw-mx-5 tw-flex tw-items-center tw-justify-end"}>
                        {

                            props?.children[selectedIndex]?.props?.rightActions?.map(item => (
                                <JavButton {...item} />
                            ))
                        }
                    </div>
                }


            </div>


            <div className={"mt-2"}>
            {
                    props && props.children.map( (item,index) => {
                        return selectedIndex === index && (
                            props.children[index]
                        )
                    })
                }
            </div>
        </div>

    )
}

export default forwardRef(JavTab)