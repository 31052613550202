import { Modal, Button } from "antd";
import React, { useEffect, useState } from "react"
import ThemeStyles from "../../style/Auth.module.css";
import { Form, Input, } from "antd";
import SwipeableViews from 'react-swipeable-views';
import Styles from "./Pin.module.css"
import { useDispatch, useSelector } from "react-redux";
import { LeftArrow, RightArrow, ShieldIcon, XIcon } from "../../Shared/Components/JavIcons"
import { getAllSecurityQuestions, setPin } from "./duck/action"
import SuccessTransaction from "./SuccessTransaction"


import {
    showErrorNotification,
} from "../actions/alert.actions";
import ErrorAlert from "./ErrorAlert";
import { TaskTodo } from "./InitialSetup/InitialSetup";
import OtpInput from "react-otp-input";




const IntroHead = ({title, desc, bg, children, close})=>{
    return(
            <div style={{background: bg}} className={Styles.head}>
                <div style={{padding: "10px 20px", width: "100%"}}>
                    <h2 style={{marginTop:"0"}} className={Styles.title}>{title}</h2>
                    <p className={Styles.subTitle}>{desc}</p>
                    {children}
                </div>
            </div>
    )
}
const SetPinMessage = ({ handleCancel, next, completed}) => {
    return (
        <>
            <IntroHead
            bg={"linear-gradient(90deg, #FBED96 0%, #ABECD6 100%)"}
            title={"Welcome to Javolin Forex"}
            desc={"Welcome to Javolin. Get to know more about how to get started on our forex platform"}
            
            />
            <div className={Styles.center}>
                     <div style={{ fontSize: "20px", textAlign: "center", fontWeight: "450", color: "#727986" }}>WHAT'S NEXT</div>
                    <div style={{ fontSize: "28px", textAlign: "center", fontWeight: "700", color: "#0032A0" }}>Let’s secure your account</div>
                <div style={{ padding: "0 20px", width: "100%"}} >
                    <TaskTodo  completed={completed?.setPin} title={"Secure your Account"} desc="Choose a 6-digit PIN you will remember" fontSub={"20px"} />
                    <TaskTodo completed={completed?.bus_kyc} title={"Business KYC"} desc="Complete your business profile" fontSub={"20px"} />
                </div>
                <Button
                    shape="round"
                    block
                    size="large"
                    style={{ margin: "2em 0 1em" }}
                    type="primary"
                    onClick={next}
                
                >
                    Continue
                </Button>
                {/* <div onClick={handleCancel} className={Styles.footer}>Set later</div> */}
            </div>
        </>
    )
}



const Pin = ({ isPinSetVisible, setIsPinSetVisible, completed, showSecurityQuestions }) => {
    const state = useSelector((state) => state.pin)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [pinTrim, setPinTrim] = useState('')
    const [indexValue, setIndexValue] = useState(0)
    const [showSuccess, setShowSuccess] = useState(false)
    const [hasError, setError] = useState({ isError: false, msg: "" })
    let codeP = []
    let codeP2 = []

    useEffect(()=>{
        dispatch(getAllSecurityQuestions())
    },[dispatch])

    const SetPinSuccess = ({showSuccess,setShowSuccess, showSecurityQuestions }) => {
        const handleShowSuccessContinue = ()=>{
            showSecurityQuestions(true)
            setShowSuccess(false)
        }
        return (
            <>
                <SuccessTransaction
                    showSuccess={showSuccess}
                    setShowSuccess ={setShowSuccess}
                    handleClick={handleShowSuccessContinue}
                    titleT="PIN Set Successful"
                    action={"continue"}
                    msg={
                        <>You have successfully reset your pin.
                        </>
                    }
                />
            </>
        )
    }

    const handleCancel = () => {
        setIsPinSetVisible(false);
    };
    const handleShowSuccess=()=>{
        // setShowSuccess(true)
        setIsPinSetVisible(false)
        showSecurityQuestions(true)
    }

    const onCreate = () => {
        let confirmPin = codeP2.toString().replaceAll(',', '')

        if (pinTrim !== confirmPin) {
            dispatch(
                showErrorNotification("Pin did not match")
            );
            setError({ isError: true, msg: "Pin did not match" })
            prevSlide()

        } else {
            dispatch(setPin({ "passcode": pinTrim }, handleShowSuccess))
        }

    }
    const handleOk = () => {
        const pin_values = form.getFieldsValue("pin")
        console.log(pin_values)
        if (indexValue < 1) {
            nextSlide()
        } else {
            if (indexValue === 1) {
                if (pin_values?.pin?.length < 6) {
                    dispatch(
                        showErrorNotification("Pin must have 6 digits")
                    );
                } else {
                    nextSlide()
                }
            } else if (indexValue === 2) {
                if (pin_values?.pin !== pin_values?.confirm_pin) {
                    dispatch(
                        showErrorNotification("Password did not match")
                    );
                } else {
                    dispatch(setPin({ "passcode": pin_values?.pin }, handleShowSuccess))
                }
            }
        }
    }

    const handleFocus = (i, e) => {

        let name = e.name
        if (name === "pin") {
            codeP[i] = e.value
        } else if (name === "confirm_pin") {
            codeP2[i] = e.value
        }

        if (e.nextSibling)
            e.nextSibling.focus()

    }

    


    const nextSlide = () => {
        let newIndex = indexValue
        newIndex = newIndex + 1
        setIndexValue(newIndex)
       
    }

    const prevSlide = () => {
        let newIndex = indexValue
        newIndex = newIndex - 1
        setIndexValue(newIndex)
    }

    const SetPin = ({ name, desc, title, action, onClickAction, loadingBtn, progress  }) => {
        
        const handleBackClick = ()=>{
            prevSlide()
        }

        return (
            <>  
                <div onClick={handleBackClick} style={{position: "absolute", padding: "1em", cursor: "pointer"}} >
                    <RightArrow width={"1em"} />
                </div>
                <IntroHead
                bg={"#ECF7E8"}
                title={title}
                desc={"Set your PIN to secure your account"}
                children={progress}
                />
                <div className={Styles.center} style={{ marginTop: "70px" }}>
                    <p style={{ fontSize: "24px", textAlign: "center", color: "#727986" }} className={Styles.subTitle}>
                    {desc}
                    </p>
                    <Form.Item
                        name={name}
                        rules={[
                            {
                                required: true,
                                message: "Please enter the pin here",
                            },
                        ]
                    }
                    >
                        <OtpInput
                                    inputStyle={{ width: "100%", padding: "1em", margin: "0.2em", borderRadius: "8px", border: "1px solid #AFB3BD" }}
                                    containerStyle={{ display: "grid", gridTemplateColumns: "repeat(6, 1fr)", columnGap: "5px", width: "100%", maxWidth:"500px", margin: "0.5em" }}
                                    numInputs={6}
                                    isInputSecure
                                />
                        {/* <ErrorAlert msg={hasError?.msg} show={hasError?.isError} /> */}
                    </Form.Item>
                    <Button
                        block
                        size="large"
                        style={{margin: "2em 0 1em"  }}
                        type="primary"
                        onClick={onClickAction}
                        loading={loadingBtn}
                        shape="round"
                    >
                        {action}
                    </Button>
                </div>
            </>
        )
    }
    

    return (
        <Modal
            open={isPinSetVisible}
            className={Styles.popupModals}
            footer={false}
            width={706}
            closable={true}
            centered
            closeIcon={
                <div className={Styles.circleClose}>
                    <XIcon width="1em" />
                 </div>
            }
            maskClosable={indexValue < 3 ? false : true}
            onCancel={handleCancel}
            bodyStyle={
                {
                    padding: "0"
                }
            }
        >



            <Form
                form={form}
                name="normal_login"
              >
                <SetPinSuccess 
                showSuccess={ showSuccess } 
                setShowSuccess={setShowSuccess} 
                showSecurityQuestions={showSecurityQuestions} 
                /> 

                <SwipeableViews
                    axis='x'
                    index={indexValue}
                >
                    <SetPinMessage completed={completed} handleCancel={handleCancel} next={nextSlide} />
                    <SetPin handleFocus={handleFocus} name="pin"
                        title="Set your PIN"
                        action="Set pin"
                        onClickAction={handleOk}
                        hasError={hasError}
                        desc={"Set your PIN to secure your account"}
                        progress={
                            <div className={Styles.progressBar}>
                                <div style={{marginRight: "0.2em"}} className={Styles.progress}>
                                </div>
                                <div style={{background: "#B8CDB2"}} className={Styles.progress}>
                                </div>
                            </div>
                        }
                    />
                    <SetPin handleFocus={handleFocus} name="confirm_pin"
                        title="Set your PIN"
                        action="Confirm pin"
                        onClickAction={handleOk}
                        hasError={hasError}
                        loadingBtn={state.settingPin}
                        pinTrim={pinTrim}
                        desc={"Repeat your PIN to confirm"}
                        progress={
                            <div className={Styles.progressBar}>
                                <div style={{marginRight: "0.2em"}} className={Styles.progress}>
                                </div>
                                <div  className={Styles.progress}>
                                </div>
                            </div>
                        }
                    />
                </SwipeableViews>
            </Form>
        </Modal>
    )
}

export default Pin

