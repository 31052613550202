import {useEffect, useRef, useState} from "react";
import useOnClickOutside from 'use-onclickoutside'

export default function JavDropdownNew(props) {

    const [opened,setOpened] = useState(false)
    const [value,setValue] = useState(null)

    const getPosition = (position) => {
        switch (position) {
            case "bottom":
                return "tw-left-0 tw-right-0";
            default:
                return "tw--top-52 tw-left-0 tw-right-0"
        }
    }
    const ref = useRef(null);
    useOnClickOutside(ref, (e) => {
        if (e.target) {
            if (e.target.dataset.class !== "allowed") {
                if (opened) {
                    setOpened(false);
                }
            }
        }
    })

    useEffect(() => {
        if (props.items && props?.items?.length > 0) {
            let text = props?.items[0].name;
            if (props?.items[0] instanceof Object) {
                text = props?.items[0]?.name;
            }
            setValue(text)
        }
    },[])

    return (

        <div className={`tw-flex tw-bg-white tw-dark:bg-[#242A38] tw-items-center tw-text-gray-600 ${props.bgColor} ${props?.holderClassName}`}>
            { props.title && <span className={"text-xs mx-1"}>{props.title}</span>}
            <div className={`tw-relative ${props.hideBorder ? '' : 'tw-border dark:tw-border-gray-500'} tw-w-full tw-rounded tw-px-1 tw-py-2.5`}>

                <div className={"tw-flex tw-items-center tw-mx-1"}>
                    <input data-class={"allowed"} ref={ref}
                           placeholder={props?.placeholder}
                           className={"tw-outline-none tw-py-1 tw-cursor-pointer tw-font-satoshi tw-w-full tw-text-xl tw-bg-white dark:tw-bg-[#242A38] dark:tw-text-gray-100"}
                           onFocus={() => { setOpened(true) }}
                           autoComplete={Math.random()}
                           value={value ?? props.value }
                    />
                    {
                        !props.hideArrowIcon &&
                        <svg onClick={() => setOpened(true)} className="tw-flex-shrink-0 tw-cursor-pointer tw-w-4 tw-h-4 tw-mx-1 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                             fill="currentColor" viewBox="0 0 20 20">
                            <path d="M6 9l6 6 6-6"/>
                        </svg>
                    }

                </div>



                <div className={`tw-mt-4 tw-absolute tw-z-50 tw-border 
                            tw-overflow-hidden tw-overflow-y-auto
                            tw-rounded-lg tw-max-h-44 tw-bg-white 
                            tw-mb-2
                           ${getPosition(props.position)}
                         ${opened ? 'tw-block' : 'tw-hidden'}
                         `}
                >
                    {
                        props?.items?.map((item,index) => {
                            let text = item.name;
                            if (item instanceof Object) {
                                text = item.name;
                            }

                            return (
                                <div key={index} data-class={"allowed"} onClick={() => {
                                    console.log("clicked")
                                    setValue(text)
                                    setOpened(false)
                                    props.onChange(item);
                                }}
                                     className="tw-border-t tw-flex tw-justify-between tw-text-gray-600
                            hover:tw-bg-gray-50 tw-text-xs tw-cursor-pointer
                             tw-items-center">
                                    {
                                        item?.render ? item?.render() :
                                            <div data-class={"allowed"} className={"tw-px-5 tw-flex-1 tw-flex tw-items-center tw-justify-between tw-font-satoshiMedium tw-py-3"}>
                                                <span data-class={"allowed"} className="tw-text-xl">{text}</span>
                                                {
                                                    text === value &&
                                                    <div data-class={"allowed"} className={"tw-h-2 tw-bg-yellow-400 tw-w-2 tw-rounded tw-border tw-border-yellow-500"}/>
                                                }
                                            </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}