import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import JavInput from "../../../../Shared/Components/Forms/JavInput";
import JavButton from "../../../../Shared/Components/Forms/JavButton";
import {useContext, useEffect, useState} from "react";
import {makeJavolinRequest} from "../../../../helpers/utils";
import {LoanTabsContext} from "./LoanTabsContext";

export default function LoanSuppliersList(props) {

    const {tabs,setTabs} = useContext(LoanTabsContext);
    const [suppliersList,setSuppliersList] = useState([]);
    useEffect(() => {
        console.log("LoanSuppliersList",tabs)
    }, [tabs]);
    const [selectedSuppliersList,setSelectedSuppliersList] = useState([]);

    useEffect(() => {

        makeJavolinRequest({
            path: '/business/accounts?permission=LOCAL_LOAN_SUPPLIER',
            method: "GET"
        },null,null,(data) => {
            console.log("data",data)
            setSuppliersList(data);
        },(error) => {
            console.log("error",error)
        })
    },[])


    return (
        <div className={"tw-flex tw-flex-col"}>

            <div className={"tw-mb-6"}>
                <span className={"tw-font-satoshiBold tw-text-2xl"}><span
                    className={"tw-font-satoshi tw-text-gray-500"}>Step 2:</span> Provide Suppliers Details</span>
            </div>


            {
                [...Array(Number(tabs?.consignmentInfo?.expected_number_of_trucks)).keys()].map((item, index) => {
                    return (
                        <div key={index} className={"tw-grid tw-grid-cols-1 tw-gap-4 tw-py-4 tw-border-b"}>

                            <JavFormSelect
                                title={`Select Supplier (${index + 1})`}
                                position={"bottom"}
                                items={suppliersList.map(item => ({...item, title: item.full_name}))}
                                onChange={(item) => {
                                    let newSelectedSuppliersList = [...selectedSuppliersList];
                                    newSelectedSuppliersList[index] = {
                                        id: index,
                                        supplier: {
                                            id: item?.id,
                                            name: item?.name
                                        }
                                    }
                                    setSelectedSuppliersList(newSelectedSuppliersList);
                                }}
                            />

                        </div>
                    )
                })
            }

            <div className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-8"}>
                <JavButton
                    title={"Back"}
                    textColor={"tw-gray-500"}
                    bgColor={"tw-bg-transparent"}
                    className={"tw-border"}
                    onClick={() => {
                        const updateTabs = [...tabs.tabs];
                        updateTabs[props?.index].completed = false;
                        const updatedTabsHolder =
                            { ...tabs,
                                tabs: updateTabs,
                                selectedIndex: (props?.index ?? 0) - 1
                            };
                        setTabs(updatedTabsHolder);
                    }}
                />
                <JavButton
                    title={"Continue"}
                    textColor={"tw-text-white"}
                    onClick={() => {
                        const updateTabs = [...tabs.tabs];
                        updateTabs[props?.index].completed = true;
                        const updatedTabsHolder = { ...tabs, tabs: updateTabs,suppliers: selectedSuppliersList, selectedIndex: (props?.index ?? 0) + 1 };
                        setTabs(updatedTabsHolder);
                    }}
                />
            </div>


        </div>
    )
}