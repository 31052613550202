import JavContentTitle from "../JavContentTitle"

import Styles from "../../Components/Menu/Menu.module.css"
import Logo from "../../../assets/javolin_logo_rev.svg";

const PrintWireInstructions = ()=>{
    return(
        <>
            <div className={Styles.jav_modal_header}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                <div className={Styles.jav_modal_secTitle}>Bank wire information</div>
                <div className={Styles.logoWrapper}>
                            <img src={Logo} alt="logo" className={Styles.logo} />
                        </div>
                    
                </div>
                        
                    <div style={{fontWeight: "bold", color: "white", padding: "1em"}}>{`JAV20230523FX3940`}</div>
                    
                </div>

                <div style={{ display: "flex", padding: "2em 1em", flexDirection: "column", alignItems: "right", justifyContent: "center" }}>
                    <div>
                        <p>Dear valued customer,</p>
                        <p>To fund your account on Javolin via bank, you need to wire the money to either one of our bank accounts with details below: </p>
                        <div style={{background: "#e2d324"}}>
                            <JavContentTitle title="Local Account" />
                        </div>
                        <p>Bank name: <span style={{ fontWeight: "bold" }}>ABSA BANK GHANA</span> </p>
                        <p>Address: <span style={{ fontWeight: "bold" }}>HIGH STREET, ABSA HOUSE - ACCRA, Ghana</span> </p>
                        <p>Account  name: <span style={{ fontWeight: "bold" }}>Javolin Group Ghana Limited</span> </p>
                        <p>Account  number: <span style={{ fontWeight: "bold" }}>0481079527</span> </p>
                        <p>Swift (optional): <span style={{ fontWeight: "bold" }}>BARCGHAC</span> </p>
                        <div>
                        <JavContentTitle title="International Account" />
                        </div>
                        
                        <p>Bank name: <span style={{ fontWeight: "bold" }}>Barclays Bank PLC</span> </p>
                        <p>Address: <span style={{ fontWeight: "bold" }}>1 Churchill Place, London EC14 5HP, United Kingdom</span> </p>
                        <p>Account  number: <span style={{ fontWeight: "bold" }}>748445588</span> </p>
                        <p>Beneficiary: <span style={{ fontWeight: "bold" }}> Monex USA. 1201 New York Avenue, NW, Suite 300 Washington, DC 20005 USA </span> </p>
                            <p>IBAN #: <span style={{ fontWeight: "bold" }}>GB04BARC20000088627544 </span> </p>
                        <p>Beneficiary Bank SWIFT : <span style={{ fontWeight: "bold" }}>BARCGB22</span> </p>
                        <p>Correspondent Bank SWIFT : <span style={{ fontWeight: "bold" }}>BARCGHAC </span> </p>
                        <p>Reference : <span style={{ fontWeight: "bold" }}>294901 | Javolin Group LLC | Zenith bank Ghana Partner</span> </p>
                        <p> <span style={{ fontWeight: "bold" }}>Important</span>: 
                            <li>
                            The name on your bank account must match the name on your JAVOLIN account.
                            </li>
                            <li>
                            If making multiple deposits over a short period, send different amounts to avoid delays.
                            </li>
                        
                         </p>
                    </div>



                </div>

        </>
    )
}


export default PrintWireInstructions