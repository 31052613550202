export const FETCH_LOANS = "FETCH_LOANS"
export const LOANS_SUCCESS = "LOANS_SUCCESS"
export const LOANS_ERROR = "LOANS_ERROR"



export const REQUEST_REQUEST_LOAN = "REQUEST_REQUEST_LOAN"
export const SUCCESS_REQUEST_LOAN = "SUCCESS_REQUEST_LOAN"
export const ERROR_REQUEST_LOAN = "ERROR_REQUEST_LOAN"


