import { useState } from "react";
import Styles from "../../Menu/Menu.module.css";
import SideMenuItem from "./MenuItem";

const MenuDropdownItem = ({ items, head }) => {
  const [collapsed, setCollaped] = useState();

  const handleCollapse = () => {
    setCollaped(!collapsed);
  };
  return (
    <div className={Styles.menuDropdown}>
      <nav>
        <div
          style={{ color: "#888B93" }}
          className={`tw-flex tw-py-4 tw-px-4`}
          onClick={handleCollapse}
        >
          {head.icon}
          <span className={"tw-text-xl tw-ml-4 tw-text-white"}>{head.title}</span>
        </div>
      </nav>

      {collapsed ? (
        <div style={{ marginLeft: "3em" }} classname={Styles.subMenu}>
          {items.map((item) => {
            return <div key={item.id}>{item.menu}</div>;
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MenuDropdownItem;
