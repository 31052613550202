import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {SET_USER_TYPE, login} from "../../duck/action";
import JavInput from "../../../../../Shared/Components/Forms/JavInput";
import JavButton from "../../../../../Shared/Components/Forms/JavButton";
import Cookies from "js-cookie";

const AgriTechLogin = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userLoginSate = useSelector((state) => state.login);

    const [form, setForm] = useState({
        email: "",
        password: "",
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value,
        });
    };

    const onFinish = async (values) => {
        dispatch({
            type: SET_USER_TYPE,
            data: "business",
        });
        let res = await dispatch(login({...values,"user_type": "agritech"}, history, "business"));
        setTimeout(async () => {
            const accessToken = Cookies.get("javAccessToken");
            console.log("accessToken",accessToken)
            window.location.href = "/agric/inventory"
        },2000)
    };

    return (
        <div
            className="tw-flex tw-bg-white tw-overflow-hidden tw-min-h-screen tw-min-w-full  tw-w-full md:tw-w-3/4 lg:tw-w-2/3 xl:tw-w-2/3">
            <div className="tw-w-full md:tw-w-1/2 tw-p-8 lg:tw-px-36 md:tw-px-20 tw-min-h-screen tw-content-center">
                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                    <img
                        src="/inv_logo.png"
                        alt="Logo"
                        className="tw-w-56 tw-h-360 tw-mb-4"
                    />
                    <h2 className="tw-text-5xl tw-font-bold tw-mb-2">Welcome back</h2>
                    <p className="tw-text-gray-600 tw-mb-4 tw-text-xl">
                        Sign in to start using our Inventory System
                    </p>
                </div>
                <div className="tw-mt-8 tw-grid tw-gap-2 tw-grid-cols-1">
                    <JavInput
                        title={"Email"}
                        name={"email"}
                        placeholder={"Enter Email"}
                        value={form.email}
                        onChange={handleChanges}
                    />
                    <div className="tw-mb-4"/>
                    <JavInput
                        title={"Password"}
                        name={"password"}
                        type={"password"}
                        placeholder={"Enter password"}
                        onChange={handleChanges}
                        value={form.password}
                    />

                    <div className="tw-mb-4 tw-text-right">
                        <a
                            className="tw-text-blue-500 tw-text-xl tw-font-semibold"
                            href="/forgot-password"
                        >
                            Forgot password?
                        </a>
                    </div>
                    <div className={"tw-flex mt-1"}>
                        <JavButton
                            title={"Continue"}
                            textColor={"tw-text-white"}
                            className={"tw-w-full tw-h-14 tw-text-2xl"}
                            isLoading={userLoginSate?.loading}
                            onClick={async () => {
                                await onFinish(form);
                            }}
                        />
                    </div>
                </div>
                <p className="tw-text-center tw-text-xl tw-font-satoshiBold tw-text-gray-600 tw-pt-5">
                    Don't have an account?{" "}
                    <a
                        className="tw-text-jav-card-500 tw-font-semibold"
                        href="/business/register"
                    >
                        Create account
                    </a>
                </p>
            </div>

            <div
                className="tw-hidden md:tw-block md:tw-w-2/3 tw-relative tw-bg-cover tw-bg-center tw-p-4 tw-hide-scrollbar">
                <div
                    className="tw-h-full tw-rounded-lg tw-overflow-hidden tw-m-10 tw-bg-cover tw-bg-center tw-bg-no-repeat tw-absolute tw-inset-0"
                    style={{backgroundImage: "url('/plain_bg.png')"}}
                >
                    <div className="tw-h-full tw-p-2.5"></div>
                </div>
                <div
                    className="tw-h-full tw-rounded-lg tw-overflow-hidden tw-bg-contain tw-bg-center tw-bg-no-repeat tw-absolute tw-inset-0"
                    style={{backgroundImage: "url('/map.png')"}}
                ></div>
            </div>
        </div>
    );
};

export default AgriTechLogin;
