import DefaultTable from "../../../../../Shared/Components/DefaultTable/defaultTable";
import CarryInIcon from "../../../../../Shared/Components/Icons/CarryInIcon";
import CarryOutIcon from "../../../../../Shared/Components/Icons/CarryOutIcon";
import React, { useEffect, useRef, useState } from "react";
import {buildQueryParamFromForm, currencyFormat} from "../../../../../helpers/utils";
import { useSelector } from "react-redux";
import EditIcon from "../../../../../Shared/Components/Icons/EditIcon";
import CreateInventoryDialog from "./components/CreateInventroyDialog";
import MoveShipmentDialog from "./components/MoveForShippmentDialog";
import DashboardStatItem from "../../../../../Shared/Components/DashboardStatItem";
import ArchiveIcon from "../../../../../Shared/Components/Icons/ArchiveIcon";
import WeightIcon from "../../../../../Shared/Components/Icons/WeightIcon";
import CashIcon from "../../../../../Shared/Components/Icons/CashIcon";

export default function LoanContractDetailParticulars(props) {
  const tradingHub = useSelector((state) => state.tradingHub);
  const tableRef = useRef();
  const [open, setOpen] = useState(false);
  const [openShippment, setShippmentOpen] = useState(false);
  const tableState = useSelector( (state) => state.table)

  useEffect(() => {
    tableRef?.current?.reloadTable(
      `/business/loan-carry-in-out?${buildQueryParamFromForm({
        contract_id: tradingHub?.fetch_loan_contract_details?.data?.id,
        location_id: 64,
      })}`
    );
  }, [tradingHub?.fetch_loan_contract_details?.data]);

  return (
      <div>


          <div className={"tw-grid tw-gap-4 tw-py-4  md:tw-grid-cols-2 lg:tw-grid-cols-4 xl:tw-grid-cols-4"}>

              <DashboardStatItem
                  title={"Financed Tons"}
                  value={tableState?.[`api.loan-carry-in-out-${props?.contract?.id}`]?.data?.meta?.stats?.expected_weight}
                  icon={<ArchiveIcon className={"tw-h-8 tw-w-8 tw-p-1 tw-stroke-2 tw-stroke-current tw-text-blue-600"}/>}
              />

              <DashboardStatItem
                  title={"Tons in Warehouse"}
                  value={tableState?.[`api.loan-carry-in-out-${props?.contract?.id}`]?.data?.meta?.stats?.stock_weight}
                  icon={<WeightIcon className={"tw-h-8 tw-w-8 tw-p-1 tw-stroke-2 tw-stroke-current tw-stroke-blue-500"}/>}
              />

              <DashboardStatItem
                  title={"Shipped Tons"}
                  value={tableState?.[`api.loan-carry-in-out-${props?.contract?.id}`]?.data?.meta?.stats?.carry_out_weight}
                  icon={<CarryInIcon className={"tw-h-8 tw-w-8 tw-p-1 tw-stroke-2 tw-stroke-current tw-text-green-600"}/>}
              />

              <DashboardStatItem
                  title={"Tons in deficit"}
                  value={tableState?.[`api.loan-carry-in-out-${props?.contract?.id}`]?.data?.meta?.stats?.deficit_weight}
                  icon={<CarryOutIcon className={"tw-h-8 tw-w-8 tw-p-1 tw-stroke-2 tw-stroke-current tw-text-red-500"}/>}
              />

              <DashboardStatItem
                  title={"Amount(USD) in deficit"}
                  value={"$"+currencyFormat(tableState?.[`api.loan-carry-in-out-${props?.contract?.id}`]?.data?.meta?.stats?.deficit_amount,2)}
                  icon={<CashIcon className={"tw-h-8 tw-w-8 tw-p-1 tw-stroke-2 tw-stroke-current tw-text-yellow-600"}/>}
              />

              <DashboardStatItem
                  title={"Un Financed Tons"}
                  value={tableState?.[`api.loan-carry-in-out-${props?.contract?.id}`]?.data?.meta?.stats?.un_financed_weight}
                  icon={<WeightIcon className={"tw-h-8 tw-w-8 tw-p-1 tw-stroke-2 tw-stroke-current tw-text-blue-500"}/>}
              />

              <DashboardStatItem
                  title={"Un Financed Amount"}
                  value={"$"+currencyFormat(tableState?.[`api.loan-carry-in-out-${props?.contract?.id}`]?.data?.meta?.stats?.un_financed_amount,2)}
                  icon={<CashIcon className={"tw-h-8 tw-w-8 tw-p-1 tw-stroke-2 tw-stroke-current tw-text-green-600"}/>}
              />


          </div>

          <DefaultTable
              columns={[
                  "id",
                  "Transport Medium",
                  "Quantity",
                  "Activity",
                  "Purchase/Selling Price",
                  "User",
                  "Timestamp",
              ]}
              title={"Stocks"}
              tag={`api.loan-carry-in-out-${props?.contract?.id}`}
              ref={tableRef}
              fields={[
                  "id",
                  {
                      // "meta.transport_medium",
                      render: (content) => {

                          return (
                              <td className={"text-center"}>
                                  {content.carry_type === "2" ? "-" : content?.meta.transport_medium}
                              </td>
                          );
                      },
                  },
                  "weight",
                  {
                    render: (content) => {
                        return (
                            <td className={"text-center"}>
                                {  content?.carry_type === "2" && <span className={"tw-bg-red-300 tw-text-xs tw-px-2 tw-py-1 tw-rounded tw-border tw-border-red-500"}> out </span>}
                                {  content?.carry_type === "1" && <span className={"tw-bg-green-300 tw-text-xs tw-px-2 tw-py-1 tw-rounded tw-border tw-border-green-500"}> in </span>}
                            </td>
                        )
                    }
                  },
                  {
                      render: (content) => {

                          return (
                              <td className={"text-center"}>
                                  USD {content.carry_type === "2" ? content?.meta?.selling_price_fcy : content?.meta?.purchase_price_fcy}
                              </td>
                          );
                      },
                  },
                  "creator.full_name",
                  "created_at",
              ]}
              rightActions={[
                  {
                      title: "Create Inventory",
                      textColor: "tw-text-white",
                      icon: EditIcon,
                      className: "tw-py-1.5 tw-stroke-2",
                      iconColor: "tw-stroke-current tw-text-green-600 tw-stroke-2",
                      onClick: () => {
                          setOpen(true);
                      },
                      show: true,
                  },
                  {
                      title: "Move for shipment",
                      textColor: "tw-text-white",
                      icon: CarryOutIcon,
                      className: "tw-py-1.5 tw-stroke-2 tw-ml-2",
                      iconColor: "tw-stroke-current tw-text-green-600 tw-stroke-1",
                      onClick: () => {
                          setShippmentOpen(true);
                      },
                      show: true,
                  },
              ]}
          />

          <CreateInventoryDialog
              open={open}
              contract={props?.contract}
              onCloseClicked={() => {
                  setOpen(false);
              }}
              tableRef={tableRef}
          />

          <MoveShipmentDialog
              open={openShippment}
              contract={props?.contract}
              onCloseClicked={() => {
                  setShippmentOpen(false);
              }}
              tableRef={tableRef}
          />
      </div>
  );
}
