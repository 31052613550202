export default function ProgressChart(props) {
    return (
        <div className={`w-relative ${props?.className}`}>
            <svg className="tw-w-full tw-h-full" viewBox="0 0 100 100">
                <circle
                    className="tw-text-gray-200 tw-stroke-current"
                    strokeWidth="10"
                    cx="50"
                    cy="50"
                    r="40"
                    fill="transparent"
                ></circle>
                <circle
                    className="tw-text-jav-card-500 progress-ring__circle tw-stroke-current"
                    strokeWidth="10"
                    strokeLinecap="round"
                    cx="50"
                    cy="50"
                    r="40"
                    fill="transparent"
                    strokeDashoffset={`calc(400 - ( 400 * ${ 0.62 * (props?.percentage || 0) } ) / 100)`}
                ></circle>

                <text x="50" y="50" className={"tw-font-satoshiBold"} fontFamily="Verdana" fontSize="12" textAnchor="middle"
                      alignmentBaseline="middle"> { (props?.percentage || 0)?.toFixed(2)} %
                </text>

            </svg>
        </div>

    )
}