import { Layout, Row, ConfigProvider, Table, Tag } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Edit, Edit2Icon, Trash, TrashIcon } from "../../Shared/Components/JavIcons";
import AddRole from "./AddRole";
import { deleteRole, getRoles } from "./duck/action";
import AccessControl from "../../Shared/Components/AccessControl/AccessControl"

import Styles from "./UserMgt.module.css"
import EditRole from "./EditRole";
import RolesList from "./RolesList";




const RoleManagement = () => {
    const text = useSelector((state) => state?.language)
    const allPerm = useSelector((state)=> state.userMgt.permissions)
    const { Content } = Layout;

    const [isVisible, setIsModalVisible] = useState(false)
    const [openAddRole, setOpenAddRole] = useState(false)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getRoles())
    }, [dispatch])

    const roles = useSelector((state) => state.userMgt.roles)

    const customizeRenderEmpty = () => (
        <div style={{ textAlign: 'center' }}>
            <p>{text["You haven’t added any roles yet. Add new role"]}</p>
        </div>
    );

    const [editUser, setEditUser] = useState(false)

    const handleEdit = (user)=>{
        setEditUser(user.id)
        setIsModalVisible(true)
     }
 
     const handleDelete = (user)=>{
        dispatch(deleteRole(user.id))
     }




    const columns = [
        {
            title: text["Role Name"],
            dataIndex: "name",
            key: "name",
        },
        {
            title: text["Role Permissions"],
            dataIndex: "permissions",
            key: "permissions",
            render: (permissions) => {
                return (
                    permissions.map((per) => {
                        if(per.name !== "TRANSACTION_LIMIT"){
                            return (

                                <Tag className={Styles.permTile} style={{ color: '#000C26', marginTop: "5px", padding: "10px", borderRadius: "4px", border: "1px solid #3DA31F" }} color='#ECF7E8' key={per.id}>
                                    {per.name}
                                </Tag>
                            )
                        }
                    })
                );
            },
        },
        {
            title: text["Action"],
            key: "action",
            render: (id) => {
                return (
                    <div key={id}>
                        {id.id !== 1 ? (
                        <AccessControl
                            allowedPermissions={['EDIT_ROLE']} 
                            renderNoAccess={''}
                        >
                            <Tag onClick={()=>handleEdit(id)} style={{ color: '#FFFFFF', padding: "5px 10px", borderRadius: "20px", fontSize: "16px", cursor:"pointer"}} color="#2272F4" >
                                <Edit height="1.2em" width="1.2em" color='#FFFFFF' />
                                {text['edit']}
                            </Tag>
                        </AccessControl>
                        ):""}
                        {id.id !== 1 ? (
                            <AccessControl
                            allowedPermissions={['DELETE_ROLE']}
                            renderNoAccess={''}
                        >
                            <Tag onClick={()=>handleDelete(id)} style={{ color: '#FFFFFF', padding: "5px 10px" , borderRadius: "20px", fontSize: "16px", cursor:"pointer"}} color="#DD4918" >
                            <Trash height={'1.2em'} width={'1.2em'} color='#FFFFFF' />
                            {text["delete"]}
                        </Tag>
                        </AccessControl>
                        ): ""}
                        
                        
                    </div>
                );
            },
        },
    ];





    const [form] = useForm();
    return (
        <>
            <Content
                style={{
                    minHeight: 300,
                    padding: "",
                    margin: "0",
                }}
            >
                <div className={Styles.titleRow}>
                   

                    <AccessControl
                            allowedPermissions={['CREATE_BUSINESS_ROLE']} 
                            renderNoAccess={''}
                        >
                        <EditRole editUser={editUser} isVisible={isVisible} setIsModalVisible={setIsModalVisible} />
                        <AddRole isVisible={openAddRole} setIsModalVisible={setOpenAddRole} />
                    </AccessControl>
                </div>
                <div className="tw-mb-3"></div>
               
                <RolesList />

            </Content>
        </>
    );
};

export default RoleManagement;
