
export default function DashboardStatItem(props) {
    return (
        <div className={"tw-flex tw-bg-white tw-cursor-pointer tw-border tw-items-start tw-rounded tw-px-2 tw-py-4"}>

            <div className={"tw-p-2 tw-mr-5 tw-border tw-bg-gray-100 tw-rounded"}>
                { props.icon}
            </div>

            <div className={"tw-flex leading-none tw-flex-col"}>
                <h4 className={"tw-font-satoshiMedium tw-text-sm tw-font-light tw-text-gray-800"}>{props.title}</h4>
                <h1 className={"tw-font-proximaBold tw-text-2xl"}>
                    {props.value}
                </h1>
            </div>

        </div>
    )
}