import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxis: {
            display: false
        },
        xAxis: {
            grid: {
                display: false
            }
        }
    },
    plugins: {
        legend: {
            position: 'top',
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
        datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'start',
            clamp: true,
            labels: {
                title: {
                    font: {
                        weight: 'bold'
                    }
                }
            },
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderRadius: 4,
            formatter: function (value, context) {
                return value; // Display the value of the bar
            },
            display: function(context) {
                return true;
            },
            padding: 6
        }
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

export function LineChart() {
    return <Line options={options} data={data} />;
}
