import JavLabel from "../../../../../Shared/Components/Forms/JavLabel";
import moment from "moment";
import {currencyFormat} from "../../../../../helpers/utils";
import Table from "../../../../../Shared/Components/Table";

export default function LoanDetailSummary(props) {

    return (
        <div className={"tw-flex tw-flex-col md:tw-p-3"}>

            <div className={"tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-y-8 tw-py-4 tw-px-4 tw-bg-gray-100 tw-rounded"}>

                <div className={"md:tw-col-span-4"}>
                    <span
                        className={"tw-uppercase tw-font-satoshiBold tw-text-sm tw-text-blue-800"}>Transaction Details</span>
                </div>

                <JavLabel
                    title={"Transaction Reference"}
                    value={props?.data?.reference}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Transaction Amount"}
                    render={() => {
                        return (
                            <span className={"tw-font-satoshiBlack tw-text-blue-600 tw-mt-1 tw-text-lg"}>
                                { props?.data?.currency } {currencyFormat(props?.data?.amount)}
                            </span>
                        )
                    }}
                />

                <JavLabel
                    title={"Module"}
                    value={"Loans"}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Timestamp"}
                    value={props?.data?.created_at?.split(".")[0]}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Description"}
                    value={props?.data?.description}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Transaction Fee"}
                    value={`GHS 0.00`}
                    valueSize={"tw-text-lg"}
                />

                <JavLabel
                    title={"Status"}
                    render={() => {
                        return (
                            <div>
                                 <span
                                     className={"tw-font-satoshiBold tw-py-1.5 tw-px-3 tw-rounded-full tw-text-jav-card-500 tw-bg-jav-card-100 tw-mt-1 tw-text-xs"}>
                                    { props?.data?.status }
                                </span>
                            </div>

                        )
                    }}
                />


            </div>


            <div className={"tw-grid tw-my-5 tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-y-8 tw-py-4 tw-px-4 tw-rounded"}>

                <div className={"md:tw-col-span-4"}>
                    <span className={"tw-uppercase tw-font-satoshiBold tw-text-sm tw-text-blue-800"}>General Loan Details</span>
                </div>

                <JavLabel
                    title={"Initiated"}
                    value={props?.data?.business?.full_name}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Role"}
                    value={props?.data?.business?.role?.role?.name}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Beneficiary Amount"}
                    value={props?.data?.beneficiary?.name}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />


                <JavLabel
                    title={"Beneficiary Bank Account"}
                    value={`${props?.data?.beneficiary?.account_number} [ ${props?.data?.beneficiary?.bank_name} ]`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />


            </div>


            <div className={"tw-grid tw-px-4 tw-my-5 tw-grid-cols-1 md:tw-grid-cols-4 tw-bg-gray-100 tw-gap-y-8 tw-py-4  tw-rounded"}>

                <div className={"md:tw-col-span-4"}>
                    <span className={"tw-uppercase tw-font-satoshiBold tw-text-sm tw-text-blue-800"}>Loan Request Details</span>
                </div>

                <JavLabel
                    title={"Expected Bags"}
                    value={`${props?.data?.expected_number_of_bags} Bags`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Expected Trucks"}
                    value={`${props?.data?.expected_number_of_trucks} Truck(s)`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Expected Bags Per Truck"}
                    value={`${props?.data?.expected_number_of_bags_per_truck} Bags`}
                    valueSize={"tw-text-lg"}
                    valueFont={"tw-font-satoshiBold"}
                />

                <JavLabel
                    title={"Loan Amount"}
                    value={`GHS ${ currencyFormat(props?.data?.amount) }`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />

                <JavLabel
                    title={"Disbursed Amount"}
                    value={`GHS ${currencyFormat(props?.data?.total_disbursed_amount)}`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />


                <JavLabel
                    title={"Total Repaid Amount"}
                    value={`GHS ${currencyFormat(props?.data?.total_repaid_amount)}`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />

                <JavLabel
                    title={"Profit Amount"}
                    value={`GHS ${ currencyFormat( props?.data?.total_repaid_amount - props?.data?.total_disbursed_amount) }`}
                    valueFont={"tw-font-satoshiBold"}
                    valueSize={"tw-text-lg"}
                />




            </div>

            <div className={""}>

                <Table
                    renderTitle={() => {
                        return (
                            <span className={"tw-uppercase tw-font-satoshiBold tw-text-sm tw-text-blue-800"}>Suppliers List </span>
                        )
                    }}
                    hideBottomToolbar={true}
                    columns={["#", "Sub Loan No" ,"Supplier", "Paid At"]}
                    data={props?.data?.loan_concerns?.map((item,index) => (
                        {
                            fields: [
                                {
                                    title: index + 1
                                },
                                {
                                    title: item?.meta?.sub_loan_reference
                                },
                                {
                                    title: item?.concern_user?.full_name
                                },
                                {
                                    render: () => {
                                        return (
                                            <td className={"textCenter"}>
                                                {item?.meta?.paid_at ?? "-"}
                                            </td>
                                        )
                                    }
                                }
                            ]
                        }
                    ))}
                />

            </div>


        </div>
    )
}