import React, { useRef, useState } from "react";
import DefaultTable from "../../Shared/Components/DefaultTable/defaultTable";
import JavButton from "../../Shared/Components/Forms/JavButton";
import { Edit, TrashIcon, UsersIcon } from "../../Shared/Components/JavIcons";
import ConfirmDialog from "../../Shared/Components/Dialog/ConfirmDialog";
import { useDispatch } from "react-redux";
import { makeJavolinRequest } from "../../helpers/utils";
import { refreshPage, showToast } from "../../Shared/Components/Toast/duck/action";
import EditUser from "./EditUser";
import { useAlert } from "../../Shared/Context/AlertContext";
import CheckIcon from "../../Shared/Components/Icons/CheckIcon";
import XIcon from "../../Shared/Components/Icons/XIcon";

function UserList() {
  const [dialogData, setDialogData] = useState({ hasOpen: false, id: null });
  const [requestLoading, setRequestLoading] = useState(false);
const { showAlert } = useAlert();

  const [editUser, setEditUser] = useState(false)
  const [isVisible, setIsModalVisible] = useState(false)
  const [isEditVisible, setIsEditModalVisible] = useState(false)
  const tableRef = useRef();


  const handleConfirm = (id) => {
    setDialogData({ ...dialogData, hasOpen: true, id: id });
    tableRef?.current?.reloadTable()
  };

  const handleCancel = () => {
    setDialogData({ ...dialogData, hasOpen: false, id: null });
    tableRef?.current?.reloadTable()
  };


  const declineRequest = (id) => {
    setRequestLoading(true);
    makeJavolinRequest(
      {
        path: `/business/accounts/${id}`,
        method: "DELETE",
      },
      null,
      null,
      (data) => {
        handleCancel();
        setRequestLoading(false);
        showAlert("User Deleted Successfully","SUCCESS", (<CheckIcon />))
       
        console.log("data", data);
      },
      (error) => {
        setRequestLoading(false);
        handleCancel();
        showAlert("Error: "  + error,"FAILED", (<XIcon />))
        console.log("error", error);
      }
    );
  };

  const handleEdit = (user) => {
    setEditUser(user)
    setIsEditModalVisible(true)
}


  return (
    <>
    <DefaultTable
    ref={tableRef}
      columns={[
        "Full Name",
        "Email",
        "Phone Number",
        "Role",
        "Status",
        "Action",
      ]}
      fields={[
        "full_name",
        "email",
        "phone_number",
        "account_role",
        {
          render: (content) => {
            var status = content?.status?.toLowerCase();
            return (
              <td className={"tw-text-center "}>
                <span
                  className={`${
                    status === "approved"
                      ? "tw-text-green-400"
                      : "tw-text-yellow-600"
                  } ${
                    status === "approved"
                      ? "tw-bg-jav-card-100"
                      : "tw-bg-yellow-100"
                  } tw-p-2 tw-rounded-md`}
                >
                  {status}
                </span>
              </td>
            );
          },
        },
        {
          render: (content) => {
            return (
              <div className={"tw-flex tw-justify-center"}>
                <JavButton
                  bgColor={"tw-bg-blue-700"}
                  textColor="tw-text-white"
                  padding="2"
                  className={
                    "tw-text-white  tw-px-4 tw-m-2 tw-h-9"
                  }
                  onClick={()=>{
                    handleEdit(content)
                  }}
                >
                  {<Edit width={"1.5em"} color={"white"} height={"1.5em"} />}
                  Edit
                </JavButton>

                <JavButton
                  bgColor={"tw-bg-red-700"}
                  textColor="tw-text-white"
                  onClick={()=>{
                    handleConfirm(content.id)}}
                  className={
                    "tw-text-white tw-px-4 tw-m-2 tw-h-9"
                  }
                >
                  {<TrashIcon width={"1.5em"} color={"white"} height={"1.5em"} className={"tw-pr-3"} />}
                  Delete
                </JavButton>
              </div>
            );
          },
        },
      ]}
      link={"/business/accounts"}
      title={"User List"}
      tag={"api.table.accounts"}
    />
     <ConfirmDialog
        isOpen={dialogData.hasOpen}
        handleCancel={handleCancel}
        message={
          <span className="">
            Are you sure you want to{" "}
            <span className="tw-font-satoshiBold tw-pl-1 tw-pr-1 tw-text-black">
              Delete
            </span>{" "}
            this user?
          </span>
        }
        handleConfirm={()=>{declineRequest(dialogData.id)}}
        requestLoading={requestLoading}
      />

<EditUser editUser={editUser} isVisible={isEditVisible} setEditUser={setEditUser} setIsModalVisible={setIsEditModalVisible} />

    </>
  );
}

export default UserList;
