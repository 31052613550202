export const ActionTypes = {

    REQUEST_FETCH_LOAN_DETAILS: "REQUEST_FETCH_LOAN_DETAILS",
    FETCH_LOAN_DETAILS_SUCCESS: "FETCH_LOAN_DETAILS_SUCCESS",
    FETCH_LOAN_DETAILS_ERROR: "FETCH_LOAN_DETAILS_ERROR",

    REQUEST_FETCH_LOAN_CONTRACT_DETAILS: "REQUEST_FETCH_LOAN_CONTRACT_DETAILS",
    FETCH_LOAN_CONTRACT_DETAILS_SUCCESS: "FETCH_LOAN_CONTRACT_DETAILS_SUCCESS",
    FETCH_LOAN_CONTRACT_DETAILS_ERROR: "FETCH_LOAN_CONTRACT_DETAILS_ERROR",


}