import Dialog from "../../../../../../Shared/Components/Dialog/Dialog";
import JavInput from "../../../../../../Shared/Components/Forms/JavInput";
import {useState} from "react";
import JavButton from "../../../../../../Shared/Components/Forms/JavButton";
import {currencyFormat, makeJavolinRequest} from "../../../../../../helpers/utils";
import JavAmountLabel from "../../../../../../Shared/Components/Forms/JavAmountLabel";
import JavTextArea from "../../../../../../Shared/Components/Forms/JavTextArea";

export default function MoveShipmentDialog(props) {

    const [form,setForm] = useState({
        contract_id: props?.contract?.id,
        weight: 0,
        description: "",
        selling_price_fcy: props?.contract?.revenue_per_weight,

    });
    const [moveShipment,setMoveShipment] = useState({})

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }


    const onMovedForShipmentTapped = () => {
        if (form?.weight === 0) {
            alert("weight can't be zero (0)")
            return;
        }
        setMoveShipment({...moveShipment,loading: true})
        makeJavolinRequest({
            path: `/business/loans/${form?.loan_id}/carryInOut`,
            method: "POST",
            data: {
                ...form,
                carry_type: 2,
                weight_unit: 'tons',
                location_id: props?.location?.id,
            }
        },null,null,(data) => {
            setMoveShipment({...moveShipment,loading: false,data: data})
            alert("Moved for Shippment Successfully")
            props.onCloseClicked();
            props?.tableRef?.current?.reloadTable();
            console.log("data",data)
        },(error) => {
            setMoveShipment({...moveShipment,loading: false})
            console.log("error-----------------------",error?.data)
        })
    }

    return (
        <Dialog position={"top"} style={{width: '30%'}} open={props?.open} onCloseClicked={props?.onCloseClicked}
                title={"Move for shippment"}>

            <div className={"tw-flex tw-flex-col tw-gap-4 tw-mt-3"}>

                <JavInput
                    title={"Shipment Weight"}
                    name={"weight"}
                    type={"number"}
                    value={form?.weight}
                    onChange={handleChanges}
                />


                <JavInput
                    title={"Selling Price/Weight (USD)"}
                    name={"selling_price_fcy"}
                    value={form?.selling_price_fcy}
                    onChange={handleChanges}
                    disabled={true}
                />


                <JavTextArea
                    title={"Description"}
                    placeholder={"give description here..."}
                    rows={4}
                    name={"description"}
                    value={form?.description}
                    onChange={handleChanges}
                />

                <JavAmountLabel
                    title={"Total Selling Price"}
                    value={`USD ${currencyFormat(form?.weight * form?.selling_price_fcy)}`}
                />


                <div className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-4"}>
                    <JavButton
                        title={"Cancel"}
                        textColor={"tw-gray-500"}
                        bgColor={"tw-bg-transparent"}
                        className={"tw-border"}
                        onClick={props?.onCloseClicked}
                    />
                    <JavButton
                        title={"Ship Now"}
                        textColor={"tw-text-white"}
                        isLoading={moveShipment?.loading}
                        onClick={onMovedForShipmentTapped}
                    />
                </div>


            </div>
        </Dialog>
    )
}