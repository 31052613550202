import {useEffect, useState} from "react";
import moment from "moment";
import Datepicker from "react-tailwindcss-datepicker-tw-prefixx";

export default function JavDatePicker(props) {

    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    });

    useEffect(() => {
        setDate({startDate: props.date,endDate: props.date});
    },[props.date])

    return (
        <div className={`tw-flex tw-font-satoshiMedium ${props.isColumn ? 'tw-flex-row' : 'tw-flex-col'}`}>

            <h3 className={`tw-text-sm
                ${props.isColumn ? 'tw-w-2/5 tw-bg-gray-200 tw-border-l tw-border-t tw-border-b tw-border-gray-300 dark:tw-bg-[#242A38] tw-flex tw-items-center tw-px-2' : ''}
            `}>{props.title}</h3>


            <div className={`${props.isColumn ? 'tw-w-3/5' : ''}
                            ${props.isColumn ? '' : 'tw-rounded-md'}
                            ${!props.isColumn ? '' : 'tw-border tw-border-gray-300 dark:tw-border-gray-500'}
                            `}>
                <Datepicker
                    value={date}
                    popoverDirection={"down"}
                    useRange={props.range ?? false}
                    showShortcuts={true}
                    showFooter={props.showFooter ?? true}
                    onChange={mDate => {
                        setDate(mDate);
                        props.onChange && props.onChange(mDate);
                    }}
                    // maxDate={props?.maxDate ?? moment().toDate()}
                    asSingle={!props.range}
                    containerClassName={`tw-relative tw-h-10 tw-flex tw-w-full tw-h-full tw-text-gray-700 ${props.isColumn ? '' : 'tw-border'} tw-rounded`}
                    inputClassName={""}
                />
            </div>


        </div>
    )
}