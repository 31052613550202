import Table from "../../../../../Shared/Components/Table";
import React, {useEffect} from "react";

export default function LoanDetailsActivityLog(props) {

    useEffect(() => {
        console.log("props",props)
    }, [props]);

    return (
        <div className={"md:tw-px-5 md:tw-mt-5"}>
            <div
                className="dark:tw-bg-[#242A38] tw-bg-white dark:border-gray-500 tw-border md:tw-py-2 tw-mb-4 tw-rounded-xl tw-shadow-sm md:tw-px-2">
                <Table
                    columns={["NO","Action", "User", "Remarks", "Timestamp"]}
                    title={"Loan Activity Log"}
                    tag={"table.loan_activity"}
                    hideBottomToolbar={true}
                    data={props?.data?.tracking?.map((item,index) => (
                        {
                            fields: [
                                {
                                    title: index + 1
                                },
                                {
                                    title: item?.status_name
                                },
                                {
                                    render: () => {
                                        return (
                                            <td className={"text-center"}>
                                                { item?.creator ?? '-' }
                                            </td>
                                        )
                                    }
                                },
                                {
                                    render: () => {
                                        return (
                                            <td className={"text-center"}>
                                                { item?.reference ?? '-' }
                                            </td>
                                        )
                                    }
                                },
                                {
                                    render: () => {
                                        return (
                                            <td className={"text-center"}>
                                                { item?.action_timestamp ?? '-' }
                                            </td>
                                        )
                                    }
                                }
                            ]
                        }
                    ))}
                />
            </div>


        </div>
    )
}