import React from "react";
import DefaultTable from "../../Shared/Components/DefaultTable/defaultTable";
import { currencyFormat } from "../../helpers/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const colorsFromStatus = (status) => {
    switch (status) {

        case "waiting approval":
            return {
                "border": "tw-border-yellow-500",
                "text": "tw-text-yellow-500"
            }
        case "completed":
            return {
                "border": "tw-border-green-200",
                "text": "tw-text-green-400"
            }
        default:
            return {}

    }
}


function LoanTransaction(props) {
  const history = useHistory();

  return (
    <DefaultTable
      columns={[
        "Transaction ID",
        "Transaction Type",
        "Amount",
        "Status",
        "Date",
        "Reference",
      ]}
      fields={[
        {
          render: (content) => {
            return (
              <td
                onClick={() => {
                  history.push(`/business/transaction/${content.id}`, content);
                }}
                className={
                  "tw-text-center tw-underline tw-text-blue-600 tw-cursor-pointer"
                }
              >
                {content?.account_reference}
              </td>
            );
          },
        },
        "transaction_type",
        {
          render: (content) => {
            return (
              <td className={"tw-text-center"}>
                GHC {currencyFormat(content?.amount, 2)}
              </td>
            );
          },
        },
        {
          render: (content) => {
            var status = content?.status?.toLowerCase().replaceAll("_", " ");
            return (
              <td className={"tw-text-center "}>
                <span
                  className={`${ Object.values(colorsFromStatus(status)).join(" ")} tw-py-1 tw-px-2 tw-border tw-rounded-lg`}
                >
                  {status}
                </span>
              </td>
            );
          },
        },
        "created_at",
        "reference",
      ]}
      link={"/business/transactions"}
      title={props?.title ?? "Transactions"}
      tag={"api.table.transactions"}
      pageNumbers={props?.pageNumbers ?? 10}
    />
  );
}

export default LoanTransaction;
