import {LoadingIcon} from "../JavIcons";

export default function JavButton({icon: IconComponent,...props}) {

    const bgColor = props.bgColor || 'tw-bg-jav-primary-200';
    const textColor = props.textColor ? props.textColor : 'tw-text-gray-700';
    const padding = props.padding ? props.padding : 'tw-p-3';

    return (
        <button
            disabled={props.disabled}
            className={`tw-flex tw-items-center tw-justify-center 
                            tw-rounded-3xl
                            tw-font-proximaBold
                            tw-text-xs
                            ${bgColor}
                            ${padding}
                            ${textColor ? textColor : 'tw-text-gray-100'} ${props.className}`}
            onClick={props.onClick}
        >
            {props.title}
            {
                props.hideChildrenWhenLoading && props.isLoading ? "" :
                    props.children
            }
            { props.isLoading &&
                <LoadingIcon className={`tw-animate-spin tw-h-5 tw-w-5 tw-mx-2 tw-fill-current tw-fill-white ${props.isLoading ? 'tw-block' : 'tw-hidden'}`}/>
            }
            {
                IconComponent &&
                <IconComponent className={`tw-w-5 tw-mx-1.5 ${props?.iconColor}`}/>
            }
        </button>
    )
}