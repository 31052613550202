import Dialog from "../Dialog/Dialog";
import {useEffect, useRef, useState} from "react";
import JavInput from "./JavInput";
import JavButton from "./JavButton";

export default function JavConfirmationPin(props) {


    const [pin,setPin] = useState([...Array(6)]);

    const inputRefs = useRef([]);

    const handleInputChange = (index, e) => {
        const value = e.target.value;
        // Update pin state with the new value at the specified index
        const newPin = [...pin];
        newPin[index] = value;
        setPin(newPin);

        // Move focus to the next input field if not the last index
        if (index < pin.length - 1 && value !== "") {
            inputRefs.current[index + 1].focus();
        }
    };

    useEffect(() => {
        console.log("pin",pin)
    },[pin])

    return (
        <Dialog
            open={props?.open}
            position={"center"}
            titleWithSubtitle={true}
            style={{width: '30%'}}
            onCloseClicked={props?.onCloseClicked} title={"Loan Request"}>

            <div className={"tw-p-8 tw-relative tw-font-satoshi tw-flex-col tw-items-center"}>

                <div className={"tw-grid tw-gap-x-5 tw-grid-cols-6"}>

                    {
                        pin.map((item,index) => {
                            return (
                                <input
                                    maxLength={1}
                                    type={"password"}
                                    onChange={(e) => handleInputChange(index, e)}
                                    ref={el => inputRefs.current[index] = el}
                                    className={"tw-h-12 tw-border-gray-600 tw-font-satoshiBlack tw-text-center tw-outline-none tw-rounded tw-border"}
                                />
                            )
                        })
                    }

                </div>

                <div className={"tw-px-10 tw-pt-10 tw-flex tw-flex-col"}>

                    <JavButton
                        title={"Confirm Transaction"}
                        textColor={"tw-text-white"}
                        className={"tw-w-full tw-py-4 tw-font-satoshiBold"}
                        isLoading={props?.confirmButtonLoading}
                        onClick={() => {
                            if (pin.filter(it => it === undefined).length === 0 ) {
                                props?.onConfirmClicked(pin.join(""))
                            }
                        }}
                    />

                    <span className={"tw-text-center mt-3 tw-cursor-pointer"}>
                        Forgot Password
                    </span>

                </div>

            </div>

        </Dialog>
    )
}