export default function ApplicationNavBarItem({ icon: Icon, title, isActive, onClick }) {
    return (
        <div 
            onClick={onClick} 
            className={`tw-flex tw-cursor-pointer tw-items-center tw-px-4 md:tw-px-6 ${isActive && 'tw-bg-jav-primary-900'} tw-w-full`}
        >
            <Icon className={"tw-fill-white"} />
            <span className={"tw-font-satoshiMedium tw-mx-2 tw-py-4 md:tw-py-5 tw-text-sm md:tw-text-md"}>
                {title}
            </span>
        </div>
    );
}
