import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const useUserType = () => {
    const [userType, setUserType] = useState(null);
    const location = useLocation();

    const isEmpty = (value) => {
        return value === null || value === undefined;
    }

    const isLocal = () => {
        return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    }


    useEffect(() => {

        const hostname = window.location.hostname;
        const queryParams = new URLSearchParams(location.search);
        const queryParamUserType = queryParams.get('user_type');

        let determinedUserType = "javolin";


        //first check the hostname if we're no on dev
        if (!isLocal()) {
            determinedUserType = hostname.split(":")[1]
        }

        if (determinedUserType === "javolin" && !isEmpty(queryParamUserType)  ) {
            determinedUserType = queryParamUserType;
        }

        if (determinedUserType === "javolin") {
            determinedUserType = "forex";
        }

        setUserType(determinedUserType);

    },[location])

    return userType;
}

export default useUserType;