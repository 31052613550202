import SearchIcon from "../Icons/SearchIcon";
import {useRef} from "react";
import {LoadingIcon} from "../JavIcons";

export default function JavTextArea(props) {

    const text = useRef();

    return (
        <div className={`tw-flex tw-flex-col ${props.className}`}>


            <h3 className={`tw-text-sm tw-text-gray-600`}>{props.title}</h3>

            <div className={"tw-flex"}>

                <textarea onChange={props.onChange}
                       onFocus={props.onFocus}
                       value={props.value}
                       className={`tw-outline-none tw-flex-1 ${props.height} ${props.hideBorder ? '' : 'tw-border'} 
                                    dark:bg-[#242A38] tw-py-1 tw-text-sm tw-rounded-md tw-px-1 dark:tw-text-white`}
                       placeholder={props.placeholder ? props.placeholder : props.name}
                       type={props.type}
                       name={props.name}
                       rows={props.rows}
                       ref={text}
                />

                <div className={`tw-w-12 tw-flex tw-items-center tw-justify-center
                                tw-bg-white tw-border tw-cursor-pointer 
                                tw-rounded tw-mx-1 ${props.hasRightButton ? '' : 'tw-hidden'}`}
                    onClick={() => props.onRightButtonClicked(text.current)}
                >
                    {
                        props.isLoading ?
                            <LoadingIcon
                                className={`tw-animate-spin tw-h-5 tw-w-5 tw-mx-2
                                            tw-fill-gray-600 ${props.isLoading ? 'tw-block' : 'tw-hidden'}`}
                            /> :
                            <SearchIcon/>
                    }
                </div>

            </div>


        </div>
    )
}