import Dialog from "../../../../../Shared/Components/Dialog/Dialog";
import JavInput from "../../../../../Shared/Components/Forms/JavInput";
import JavAmountLabel from "../../../../../Shared/Components/Forms/JavAmountLabel";
import JavButton from "../../../../../Shared/Components/Forms/JavButton";
import {currencyFormat, makeJavolinRequest} from "../../../../../helpers/utils";
import CheckIcon from "../../../../../Shared/Components/Icons/CheckIcon";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {fetchLoanDetails} from "../../../duck/action";
import JavFormFileInput from "../../../../../Shared/Components/Forms/JavFormFileInput";

export default function EditLoanWayBill(props) {

    const dispatch = useDispatch();
    const [form,setForm] = useState({
        actual_total_weight: "",
        total_bags: "",
        balance: 0,
        value: 1
    });
    const [updateWayBill,setUpdateWayBill] = useState({
        loading: false,
        data: null
    });
    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const getRepayableAmount = () => {
        if (form?.balance > 0) {
            return form?.expected_total_amount  + (( form?.total_bags * 35  ) / 2 )
        }
        return form?.expected_total_amount - form?.balance +
            (( form?.total_bags * 35  ) / 2 )
    }

    const updateWayBillInfo = () => {
        setUpdateWayBill({...updateWayBill,loading: true, data: null});
        makeJavolinRequest({
            path: `/business/loans/${props?.data?.id}/update-waybill`,
            method: "POST",
            data: {total_weight: form?.actual_total_weight}
        },null,null,(data) => {
            setUpdateWayBill({...updateWayBill,loading: false,data: data});
            props?.onCloseClicked();
            const loanReference = window.location.href.split("/")?.[5]
            dispatch(fetchLoanDetails(loanReference))
            console.log("data",data)
        },(error) => {
            setUpdateWayBill({...updateWayBill,loading: false,error: error});
            console.log("error",error)
        })
    }


    useEffect(() => {
        setForm({
            ...form,
            total_bags: form?.actual_total_weight / ( props?.loanRequestInfo?.weight_per_bag ),
            expected_total_amount: ( props?.data?.amount * ( form?.actual_total_weight / props?.loanRequestInfo?.weight_per_bag ) ) / props?.data?.expected_number_of_bags,
            balance:  (( props?.data?.amount * ( form?.actual_total_weight / props?.loanRequestInfo?.weight_per_bag ) ) / props?.data?.expected_number_of_bags) -  ( props?.data?.amount * ( props?.data?.partial_disbursement_percentage / props?.loanRequestInfo?.weight_per_bag ) )
        })
    }, [props?.data,form?.actual_total_weight]);

    return (
        <Dialog position={"center"} style={{width: '40%'}}  hasBorder={true} open={props?.open} onCloseClicked={props?.onCloseClicked} title={"Waybill"}>
            <div className={"tw-grid tw-p-5 tw-grid-cols-2 tw-gap-x-5"}>
                {/*{form?.expected_total_amount}*/}

                <div className={"tw-col-span-2 tw-gap-2 tw-mb-5"}>
                    <span className={"tw-font-satoshiBold tw-text-sm"}> Upload Waybill</span>
                    <JavFormFileInput
                        title={"Contract Name"}
                        name={"expected_number_of_trucks"}
                        value={form?.expected_number_of_trucks}
                        data={form?.file}
                        onChange={(item) => {
                            console.log("item", item)
                            setForm({
                                ...form,
                                file: item
                            })
                        }}
                    />
                </div>


                <div className={"tw-col-span-2 tw-mb-2"}>

                    <JavInput
                        title={"Total number of kg"}
                        name={"actual_total_weight"}
                        onChange={handleChanges}
                    />

                </div>


                <JavInput
                    title={"Weight Per Bag (KG)"}
                    name={"actual_weight_per_bag"}
                    value={props?.loanRequestInfo?.weight_per_bag}
                    disabled={true}
                />

                <JavInput
                    title={"Total Bags"}
                    name={"actual_total_bags"}
                    value={form?.total_bags}
                    disabled={true}
                />


                <div className={"tw-col-span-2"}>
                    <hr className={"tw-px-5"}/>
                </div>


                <div
                    className={` ${props?.data?.partial_disbursement_percentage < 100 ? 'tw-col-span-1' : 'tw-col-span-2'}`}>


                    <JavAmountLabel
                        title={"Loan Amount (100%)"}
                        bgColor={"tw-bg-blue-50"}
                        border={"tw-border tw-border-blue-500"}
                        renderToolTip={() => {
                            return (
                                <div>
                                    <span className={"tw-font-satoshi tw-text-xs"}>Full Amount</span>
                                </div>
                            )
                        }}
                        value={`GHS ${currencyFormat(props?.data?.amount)}`}
                    />

                    {
                        props?.data?.partial_disbursement_percentage < 100 &&
                        <JavAmountLabel
                            title={`Loan Amount (${props?.data?.partial_disbursement_percentage}%)`}
                            border={"tw-border tw-border-blue-500"}
                            bgColor={"tw-bg-blue-100"}
                            value={`GHS ${currencyFormat(props?.data?.amount * (props?.data?.partial_disbursement_percentage / 100))}`}
                            renderToolTip={() => {
                                return (
                                    <div className={"tw-flex tw-items-center tw-py-1 tw-text-jav-card-500"}>

                                        <div className={"tw-bg-jav-card-500 tw-rounded-full tw-p-1"}>
                                            <CheckIcon className={"tw-h-2 tw-w-2"}/>
                                        </div>

                                        <span className={"tw-text-xs tw-mx-1"}>Received already</span>


                                    </div>
                                )
                            }}
                        />
                    }


                </div>


                <div className={"tw-col-span-2"}>
                    <hr className={"tw-px-5"}/>
                </div>


                <div
                    className={` ${props?.data?.partial_disbursement_percentage < 100 ? 'tw-col-span-1' : 'tw-col-span-2'}`}>


                    {
                        props?.data?.partial_disbursement_percentage < 100 &&
                        <JavAmountLabel
                            title={"Second Disbursement Amt"}
                            value={`GHS ${currencyFormat(Math.abs(form?.balance))}`}
                            bgColor={form?.balance > 0 ? 'tw-bg-jav-card-200' : 'tw-bg-yellow-100'}
                            border={form?.balance > 0 ? 'tw-border-jav-card-200' : 'tw-border-yellow-100'}
                            renderToolTip={() => {
                                return (
                                    <div className={"tw-flex"}>
                                    <span className={"tw-font-satoshi tw-text-xs tw-py-1"}>
                                        {form?.balance > 0 ? 'Balance you will receive from Javolin' : 'Amount you need to balance javolin'}
                                    </span>
                                    </div>
                                )
                            }}
                        />
                    }

                    <JavAmountLabel
                        bgColor={"tw-bg-red-100"}
                        border={"tw-border-red-100"}
                        title={"Total Payable Amount"}
                        value={`GHS ${currencyFormat(getRepayableAmount())}`}
                    />

                </div>






                <div className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-8"}>
                    <JavButton
                        title={"Cancel"}
                        textColor={"tw-gray-500"}
                        bgColor={"tw-bg-transparent"}
                        className={"tw-border"}
                        onClick={props?.onCloseClicked}
                    />
                    <JavButton
                        title={"Continue"}
                        textColor={"tw-text-white"}
                        isLoading={updateWayBill?.loading}
                        onClick={updateWayBillInfo}
                    />
                </div>


            </div>


        </Dialog>
    )
}