import {useContext, useMemo, useState} from "react";
import {currencyFormat} from "../../../../../../helpers/utils";
import JavInput from "../../../../../../Shared/Components/Forms/JavInput";
import JavAmountLabel from "../../../../../../Shared/Components/Forms/JavAmountLabel";
import JavButton from "../../../../../../Shared/Components/Forms/JavButton";
import {LoanContractTabContext} from "../../Tabs/LoanContractTabContext";

export default function LoanContractDisbursementConsignmentTab(props) {


    const {tabs,setTabs} = useContext(LoanContractTabContext);
    const [form,setForm] = useState({
        rangeValue: 50
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const [selectedWeight,remainingWeight,amount] = useMemo(() => {
        return [
            (form.rangeValue / 100) * tabs?.contract?.remaining_weight,
            tabs?.contract?.remaining_weight - ((form.rangeValue / 100) * tabs?.contract?.remaining_weight),
            ((form.rangeValue / 100) * tabs?.contract?.remaining_weight) * tabs?.contract?.cost_per_weight
        ];
    },[form.rangeValue, tabs?.contract])

    return (
        <div className={"tw-flex tw-flex-col"}>

            <div>
                <span className={"tw-font-satoshiBold tw-text-2xl"}><span
                    className={"tw-font-satoshi tw-text-gray-500"}>Step 1:</span> Add Consignment Details</span>
            </div>


            <div className={"tw-grid tw-grid-cols-2 tw-gap-4 tw-my-8"}>

                <div className={"tw-col-span-2 tw-font-satoshi tw-py-3 tw-border tw-rounded-lg tw-flex "}>

                    <div className={"tw-flex-1 tw-px-5 tw-border-r tw-flex tw-flex-col"}>
                        <span className={"tw-text-sm"}>Contract number</span>
                        <span className={"tw-font-satoshiBold"}>{tabs?.contract?.reference}</span>
                    </div>

                    <div className={"tw-flex-1 tw-px-5  tw-flex tw-flex-col"}>
                        <span className={"tw-text-sm"}>Total weight</span>
                        <span className={"tw-font-satoshiBold"}>{currencyFormat(tabs?.contract?.total_weight)} tons</span>
                    </div>

                </div>

                <div
                    className={"tw-col-span-2 tw-p-5 tw-rounded-lg tw-border tw-border-jav-card-500 tw-bg-jav-card-100"}>

                    <div className={"tw-py-2"}>
                        <span className={"tw-font-medium tw-text-black tw-text-sm"}>Select weight(ton)</span>
                    </div>
                    <div className={"tw-relative tw-w-full "}>
                        <input type={"range"}
                               onChange={(value) => {
                                   setForm({...form, rangeValue: value.target.value})
                                   console.log("value", value.target.value)
                               }}
                               value={form?.rangeValue}
                               className={"tw-w-full tw-cursor-pointer tw-appearance-none tw-bg-jav-card-200"}/>

                        <div style={{width: `${form?.rangeValue}%`}}
                             className={"tw-absolute tw-rounded-l-lg tw-rounded-r-lg tw-bg-jav-alert-green-100 tw-w-full tw-h-2 tw-top-2.5 tw-z-20 tw-left-0"}/>

                    </div>
                    <div className={"tw-relative tw-flex tw-py-2 tw-justify-between"}>
                        <span className={"tw-font-satoshiBold tw-text-2xl"}>{currencyFormat("0", 1)}</span>
                        <span className={"tw-font-satoshiBold tw-text-2xl"}>{currencyFormat(tabs?.contract?.remaining_weight, 0)}</span>
                        <span style={{left: `${form?.rangeValue - 2}%`}}
                              className={"tw-absolute tw-font-satoshiBold tw-text-2xl"}>
                            {currencyFormat(selectedWeight, 0)}
                        </span>
                    </div>
                </div>

                <JavInput
                    title={"Selected Weight(Tons)"}
                    name={"weight"}
                    value={currencyFormat(selectedWeight, 0)}
                    disabled={false}
                    onChange={(event) => {
                        let value = event?.target?.value;
                        setForm({...form,
                            rangeValue: ( value * 100 ) / tabs?.contract?.remaining_weight
                        })
                    }}
                />

                <JavInput
                    title={"Weight(Tons) Remaining"}
                    name={"weight"}
                    disabled={true}
                    value={currencyFormat( remainingWeight, 0)}
                    onChange={handleChanges}
                />


                <div className={"tw-col-span-2"}>
                    <hr className={"tw-px-5"}/>

                    <JavAmountLabel
                        title={"Disbursement Amount"}
                        value={`${tabs?.contract?.currency}${currencyFormat(amount)}`}
                        bgColor={'tw-bg-blue-50'}
                        border={"tw-border tw-border-blue-500"}
                    />
                </div>


                <div className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-8"}>
                    <JavButton
                        title={"Back"}
                        textColor={"tw-gray-500"}
                        bgColor={"tw-bg-transparent"}
                        className={"tw-border"}
                        onClick={() => {
                            const updateTabs = [...tabs.tabs];
                            updateTabs[props?.index].completed = false;
                            const updatedTabsHolder =
                                {
                                    ...tabs,
                                    tabs: updateTabs,
                                    selectedIndex: (props?.index ?? 0) - 1,
                                    beneficiary: null
                                };
                            setTabs(updatedTabsHolder);
                        }}
                    />
                    <JavButton
                        title={"Continue"}
                        textColor={"tw-text-white"}
                        onClick={() => {
                            const updateTabs = [...tabs.tabs];
                            updateTabs[props?.index].completed = true;
                            const updatedTabsHolder =
                                {
                                    ...tabs,
                                    consignmentInfo: {selectedWeight,remainingWeight,amount},
                                    tabs: updateTabs,
                                    selectedIndex: (props?.index ?? 0) + 1,
                                };
                            setTabs(updatedTabsHolder);
                        }}
                    />
                </div>



            </div>


        </div>
    )
}