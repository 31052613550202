import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from "../../../../../../Shared/Components/Icons/CloseIcon";
import JavFormFileInput from "../../../../../../Shared/Components/Forms/JavFormFileInput";
import JavFormSelect from "../../../../../../Shared/Components/Forms/JavFormSelect";
import JavInput from "../../../../../../Shared/Components/JavInput";
import JavButton from "../../../../../../Shared/Components/Forms/JavButton";
import Dialog from "../../../../../../Shared/Components/Dialog/Dialog";

export default function UploadDocumentDialog(props) {

    const dispatch = useDispatch();
    const transactionState = useSelector( (state) => state.transactions)


    const [form,setForm] = useState({
        file: "",
        remarks: "",
    });


    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }


    const uploadDocument = () => {
        // dispatch(uploadTransactionDocument({
        //     file: form.file.file,
        //     reference_number: props.transaction?.reference,
        //     reference: props.transaction?.module,
        //     meta: {
        //         remarks: form.remarks,
        //         option: form.document_option
        //     }
        // }))
    }

    useEffect(() => {

        if (transactionState?.upload_transaction_document.error) {
            alert(transactionState?.upload_transaction_document.error)
        }

        if (transactionState?.upload_transaction_document.success) {
            props.onCloseClicked();
        }


    },[transactionState?.upload_transaction_document])


    if (!props.open) {
        return <></>;
    }

    return (
        <Dialog position={"top"} style={{width: '30%'}} open={props?.open} onCloseClicked={props?.onCloseClicked}
                title={"Document Uploads"}>

                <div className={"tw-grid tw-grid-cols-1 tw-mb-1 dark:tw-bg-[#242A38] dark:tw-border-gray-600 tw-gap-x-3 tw-gap-y-4 tw-py-4 tw-px-4 tw-my-2"}>

                    <JavFormFileInput
                        title={"Select Document"}
                        name={"name"}
                        meta={form?.file?.meta}
                        data={form?.file?.file}
                        onChange={(fileChange) => {
                            console.log(fileChange)
                            setForm({ ...form, file: fileChange });
                        }}
                    />

                    <JavFormSelect
                        code={"TDO001"}
                        codeField={"code_name"}
                        position={"bottom"}
                        onChange={(item) => setForm({ ...form, document_option: item.id })}
                        title={"Document Option"}
                    />


                    <JavInput
                        title={"Remarks"}
                        placeholder={"Remarks"}
                        name={"remarks"}
                        value={form.remarks}
                        onChange={handleChanges}
                    />


                    <div className={"tw-flex tw-justify-center tw-mt-5"}>

                        <JavButton
                            title={"Upload"}
                            padding={"tw-px-16 tw-py-3"}
                            textColor={"text-white"}
                            isLoading={transactionState?.upload_transaction_document?.loading}
                            onClick={uploadDocument}
                        />

                    </div>


                </div>

        </Dialog>
    )
}