import DefaultTable from "../../../../../Shared/Components/DefaultTable/defaultTable";
import {buildQueryParamFromForm, currencyFormat} from "../../../../../helpers/utils";
import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";

const  LoanContractDetailOverview = (props,ref)  => {

    const tradingHub = useSelector((state) => state.tradingHub)
    const tableRef = useRef();
    const history = useHistory()

    useImperativeHandle(ref,() => {
        return {
            reloadLoanTable: () => {
                tableRef?.current?.reloadTable()
            }
        }
    },[]);

    useEffect(() => {
        tableRef?.current?.reloadTable(`/business/loans?${buildQueryParamFromForm({
            contract_id: tradingHub?.fetch_loan_contract_details?.data?.id
        })}`);
    },[tradingHub?.fetch_loan_contract_details?.data])

    useEffect(() => {
        console.log("LoanContractDetailOverview props: ",props);
    }, []);

    return (
        <div className={""}>

            <DefaultTable
                columns={["Loan Request Number", "Request By", "Amount", "Loan Type", "Status", "Timestamp"]}
                ref={tableRef}
                fields={
                    [{
                        render: (content) => {
                            return (
                                <td onClick={() => {
                                    history.push(`/business/loans/${content?.reference}`)
                                }} className={"tw-text-center tw-underline tw-text-blue-600 tw-cursor-pointer"}>
                                    {content?.reference}
                                </td>
                            )
                        }
                    }, "business.full_name", {
                        render: (content) => {
                            return (
                                <td className={"tw-text-center"}>
                                    {content?.currency} {currencyFormat(content?.amount,2)}
                                </td>
                            )
                        }
                    }, {
                        render: (content) => {
                            return (
                                <td className={"tw-text-center"}>
                                    {content?.loan_type?.code_name}
                                </td>
                            )
                        }
                    }, {
                        render: (content) => {
                            return (
                                <td className={"tw-text-center "}>
                                    <span className={"tw-text-green-400 tw-bg-jav-card-100 tw-p-2 tw-rounded-xl"}>
                                        {content?.status?.toLowerCase().replaceAll("_"," ")}
                                    </span>
                                </td>
                            )
                        }
                    }, 'created_at']
                }
                title={"Transactions"}
                tag={`api.loan-contract-loans-${props?.data?.id}`}
            />

        </div>
    )
}

export default forwardRef(LoanContractDetailOverview)
