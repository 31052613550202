import React, {useEffect, useMemo, useRef, useState} from "react";
import JavButton from "../../Shared/Components/Forms/JavButton";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";
import Table from "../../Shared/Components/Table";
import {currencyFormat, makeJavolinRequest} from "../../helpers/utils";
import DoughnutChart from "../../Shared/Components/Charts/DoughnutChart";
import LoanRequestDialog from "./LoanRequestDialog";
import LoanConsignmentInfo from "./LoanRequestDialog/Tabs/LoanConsignmentInfo";
import LoanBeneficiary from "./LoanRequestDialog/Tabs/LoanBeneficiary";
import LoanSuppliersList from "./LoanRequestDialog/Tabs/LoanSuppliersList";
import LoanSummaryReview from "./LoanRequestDialog/Tabs/LoanSummaryReview";
import {useHistory} from "react-router-dom";
import JavConfirmationPin from "../../Shared/Components/Forms/JavConfirmationPin";
import { useAlert } from "../../Shared/Context/AlertContext";
import XIcon from "../../Shared/Components/Icons/XIcon";
import {BarChart} from "../../Shared/Components/Charts/BarChart";
import JavTab from "../../Shared/Components/Forms/Tabs";
import LoanContractRequestDialog from "./LoanContract/LoanContractRequestDialog";
import DefaultTable from "../../Shared/Components/DefaultTable/defaultTable";
import {HorizontalChart} from "../../Shared/Components/Charts/HorizontalChart";

export default function TradingHub () {

    const [openRequestLoanDialog,setOpenRequestLoanDialog] = useState(false);
    const [openConfirmationPinDialog,setOpenConfirmationPinDialog] = useState(false);
    const [requestLoanLoading,setRequestLoanLoading] = useState(false);
    const [openContractDialog,setOpenContractDialog] = useState(false);
    const [form,setForm] = useState();
    const tableRef = useRef();
    const tabRef = useRef();
    const history = useHistory();
    const [setupLoan,setSetupLoan] = useState({
        loading: false,
        data: null
    });
    const [tradingHubStats,setTradingHubStats] = useState({
        loading: false,
        data: null
    })
    const { showAlert } = useAlert();

    const [chartLabels,disbursedAmountDataset,totalRepaidAmount] = useMemo(() => {
        return [
            tradingHubStats?.data?.loans?.map(item => {
                return item["month_year"]
            }) ?? [],
            {
                label: 'Loan Requests',
                data: tradingHubStats?.data?.loans?.map(item => {
                    return item["total_disbursed_amount"]
                }) ?? [],
                borderColor: '#60a5fa',
                backgroundColor: '#60a5fa',
                tension: 0.1
            },
            {
                label: 'Dataset 2',
                data: tradingHubStats?.data?.loans?.map(item => {
                    return item["total_repaid_amount"]
                }) ?? [],
                borderColor: '#16a34a',
                backgroundColor: '#16a34a'
            }
        ];
    },[tradingHubStats?.data])

    const setupRequestLoan = () => {
        setSetupLoan({...setupLoan,loading: true, data: null});
        makeJavolinRequest({
            path: '/business/loans/local/setup',
            method: "GET"
        },null,null,(data) => {
            setSetupLoan({...setupLoan,loading: false,data: data});
            setOpenRequestLoanDialog(true);
            console.log("data",data)
        },(error) => {
            setSetupLoan({...setupLoan,loading: false,error: error});
            console.log("error",error)
        })
    }

    const selectTradingHubStats = () => {
        setTradingHubStats({...tradingHubStats,loading: true, data: null});
        makeJavolinRequest({
            path: '/business/trading-hub',
            method: "GET"
        },null,null,(data) => {
            setTradingHubStats({...tradingHubStats,loading: false,data: data});

            let rr = data?.loans?.map(item => {
                return item["month_year"]
            })

            console.log("rr",rr);


            console.log("data",data)
        },(error) => {
            setTradingHubStats({...tradingHubStats,loading: false,error: error});
            console.log("error",error)
        })
    }

    const requestLoan = (data) => {
        setRequestLoanLoading(true);
        makeJavolinRequest({
            path: '/business/loans',
            method: "POST",
            data: {...data,loan_type: "1"}
        },null,null,(data) => {
            setRequestLoanLoading(false);
            setOpenConfirmationPinDialog(false);
            alert("Loan Created Successfully");
            tableRef?.current?.reloadTable()
            console.log("data",data)
        },(error) => {
            setRequestLoanLoading(false);
            alert("Error: "+error);
            showAlert("Error: "  + error,"FAILED", (<XIcon />))
            // console.log("error",error)
        })
    }

    const onLoanRequestFormFilled = (data) => {
        console.log("data",data);
        setOpenRequestLoanDialog(false)
        setOpenConfirmationPinDialog(true);
        setForm(data);
    }


    useEffect(() => {
        //constructor
        selectTradingHubStats();

        return () => {
            //de-constructor
        }
    }, []);

    return (
        <div className={"tw-w-full tw-px-5"}>

            <div className={"tw-flex tw-flex-col lg:tw-flex-row"}>

                <div className={"tw-flex tw-w-full lg:tw-w-1/3 tw-pr-2 tw-flex-col"}>

                    <div className={"tw-h-44 tw-bg-gradient-to-r tw-from-yellow-200 tw-to-green-300 tw-w-full tw-font-satoshi" +
                        " tw-pl-6 tw-pt-2 lg:tw-pt-6 md:tw-pt-6 tw-border tw-border-jav-card-200 tw-rounded-3xl"}>

                        <h4 className={"tw-font-satoshiBold"}>Welcome to Javolin Trading Hub, David!</h4>

                        <span className={"tw-font-satoshi tw-text-sm"}>
                            Eu turpis netus felis elementum sed ipsum praesent pellentesque enim. Sit nunc ultrices nec convallis adipiscing amet. Non et laoreet egestas ac blandit malesuada in hendrerit urna.
                        </span>

                    </div>

                    <div
                        className={"tw-h-44 tw-bg-jav-primary-900 tw-text-white tw-from-jav-primary-200" +
                            " tw-to-blue-400 tw-relative tw-overflow-hidden tw-my-4 tw-w-full tw-font-satoshi" +
                            " tw-pl-6 tw-pt-8 tw-border tw-rounded-3xl"}
                    >

                        <div className={"tw-h-full tw-w-2/3"}>
                            <h4 className={"tw-font-proximaBold"}>Request Loan</h4>
                            <span className={"tw-font-satoshi tw-text-sm"}>Eu turpis netus felis elementum sed ipsum praesent pellentesque enim. Sit nunc ultrices</span>
                        </div>

                        <div className={"tw-absolute tw--bottom-28 tw--right-4"}>
                            <div className={"tw-w-48 tw-h-48 tw-rounded-3xl tw-transform tw--rotate-20 tw-bg-gray-100"} />
                        </div>

                        <div className={"tw-absolute tw-bottom-4 tw-right-4"}>
                            <JavButton
                                title={"Request Loan"}
                                textColor={"tw-text-white"}
                                className={"tw-px-4"}
                                isLoading={setupLoan?.loading}
                                onClick={() => {
                                    setupRequestLoan()
                                }}
                            ></JavButton>

                        </div>

                    </div>

                </div>

                <div className={"tw-flex tw-flex-col md:tw-flex-row tw-w-full md:tw-w-2/3"}>

                    <div className={"tw-w-full md:tw-w-1/3 tw-mr-2 tw-flex tw-flex-col tw-grid-cols-1"}>

                        <div className={"tw-relative tw-h-20  tw-flex tw-justify-between tw-border tw-bg-white tw-rounded-lg"}>

                            <div className={"tw-m-2"}>
                                <span className={"tw-font-proximaBold tw-text-sm"}>Current Outstanding Loan</span>
                                <h3 className={"tw-text-2xl tw-font-satoshiBold"}>Gh₵ 0.00</h3>
                            </div>

                            <div className={"tw-absolute tw--top-1 tw--right-1"}>
                                 <span className="tw-relative tw-flex tw-h-3 tw-w-3">
                                    <span
                                        className="tw-animate-ping tw-absolute tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-purple-400 tw-opacity-75"></span>
                                    <span
                                        className="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-green-500"></span>
                                </span>
                            </div>

                            <div className={"tw-m-1 tw-absolute tw-bottom-0 tw-right-0 tw-flex tw-justify-between tw-items-end tw-flex-col"}>
                                <span className={"tw-text-xs tw-font-proxima"}>2 days more</span>
                            </div>

                        </div>

                        <div className={"tw-rounded tw-mt-4 tw-bg-white tw-px-2 tw-font-satoshiMedium tw-text-sm tw-border"}>
                            <div className={"tw-py-2 tw-flex tw-flex-col tw-border-b"}>
                                <span className={"tw-text-sm tw-font-satoshiBold"}>📊Product </span>
                                <span className={"tw-mt-1 tw-text-xs tw-text-gray-500"}> loan request on products</span>
                            </div>
                            <div className={"tw-h-52"}>
                                <HorizontalChart
                                    title={"Loan Products"}
                                    data={[
                                        {"title": "plantain","value": tradingHubStats?.data?.loans?.length},
                                        {"title": "cashew","value": 0},
                                    ]}
                                />
                            </div>
                        </div>


                    </div>


                    <div
                        className={"tw-h-92 tw-flex tw-flex-col tw-overflow-hidden tw-my-2 md:tw-my-0 tw-font-satoshi tw-border tw-bg-white tw-w-full lg:tw-w-2/3 tw-rounded-xl"}>

                        <div className={"tw-h-16 tw-flex tw-px-2 tw-items-center tw-border-b"}>
                            <span className={"tw-font-firmaBold tw-text-center tw-text-sm"}>Loan Request Report</span>
                        </div>

                        <div className={"tw-h-full tw-px-2 tw-my-1"}>
                            <BarChart labels={chartLabels} datasets={[disbursedAmountDataset]}/>
                        </div>


                    </div>

                </div>


            </div>


            <JavTab
                align={"left"}
                ref={tabRef}
                bgColor={"tw-bg-white"}
                activeTextColor={"tw-text-blue-600"}
                normalTextColor={"tw-text-black"}
                className={"tw-w-full"}>

                <DefaultTable
                    columns={["Loan Request Number", "Request By", "Amount", "Loan Type", "Status", "Timestamp"]}
                    ref={tableRef}
                    fields={
                        [{
                            render: (content) => {
                                return (
                                    <td onClick={() => {
                                        history.push(`/business/loans/${content?.reference}`)
                                    }} className={"tw-text-center tw-underline tw-text-blue-600 tw-cursor-pointer"}>
                                        {content?.reference}
                                    </td>
                                )
                            }
                        }, "business.full_name", {
                            render: (content) => {
                                return (
                                    <td className={"tw-text-center"}>
                                        GHC {currencyFormat(content?.amount,2)}
                                    </td>
                                )
                            }
                        }, {
                            render: (content) => {
                                return (
                                    <td className={"tw-text-center"}>
                                        {content?.loan_type === "1" ? "Local Loan" : "Local Agriculture Loan"}
                                    </td>
                                )
                            }
                        }, {
                            render: (content) => {
                                return (
                                    <td className={"tw-text-center"}>
                                        <span className={"tw-text-green-400 tw-bg-jav-card-100 tw-p-2 tw-rounded-xl"}>
                                            {content?.status?.toLowerCase().replaceAll("_", " ")}
                                        </span>
                                    </td>
                                )
                            }
                        }, 'created_at']
                    }
                    link={"/business/loans?loan_type=1"}
                    title={"Local Loans"}
                    tag={"api.table"}
                />

                <div
                    title={"International Loans"}
                    rightActions={[
                        {
                            "onClick": () => { setOpenContractDialog(true) },
                            "title": "Create Contract",
                            "textColor": "tw-text-white"
                        }
                    ]}>
                    <DefaultTable
                        columns={["Contract Request Number", "Start Date", "End-Date", "Amount", "Created By", "Timestamp"]}
                        ref={tableRef}
                        fields={
                            [{
                                render: (content) => {
                                    return (
                                        <td onClick={() => {
                                            history.push(`/business/loans/contracts/${content?.reference}`)
                                        }} className={"tw-text-center tw-underline tw-text-blue-600 tw-cursor-pointer"}>
                                            {content?.reference}
                                        </td>
                                    )
                                }
                            }, "start_date", "start_date", {
                                render: (content) => {
                                    return (
                                        <td className={"tw-text-center"}>
                                            GHC {currencyFormat(content?.amount,2)}
                                        </td>
                                    )
                                }
                            }, "business.business_name", 'created_at']
                        }
                        link={"/business/loan-contracts"}
                        title={"International Contracts"}
                        tag={"api.loan-contract"}
                    />

                </div>

            </JavTab>

            <LoanContractRequestDialog
                open={openContractDialog}
                onCloseClicked={() => {
                    setOpenContractDialog(false);
                }}
            />

            <LoanRequestDialog
                open={openRequestLoanDialog} onCloseClicked={() => {
                    setOpenRequestLoanDialog(false)
                }}>
                <LoanConsignmentInfo
                    index={0}
                    title={"Bags & Truck Info"}
                    data={setupLoan?.data}
                    onCancelClicked={() => {
                        setOpenRequestLoanDialog(false)
                    }}
                />
                <LoanSuppliersList index={1} title={"Supplier List"} />
                <LoanBeneficiary index={2} title={"Beneficiary"} />
                <LoanSummaryReview index={3} title={"Review & Confirm"} onFinish={onLoanRequestFormFilled} />
            </LoanRequestDialog>

            <JavConfirmationPin
                open={openConfirmationPinDialog}
                confirmButtonLoading={requestLoanLoading}
                onConfirmClicked={(pin) => {
                    requestLoan({ ...form, pin: pin })
                }}
                onCloseClicked={() => {
                    setOpenConfirmationPinDialog(false)
                }}
            />

        </div>
    )
}
