import CloseIcon from "../Icons/CloseIcon";
import {useEffect, useState} from "react";
import JavButton from "../Forms/JavButton";

export default function Dialog(props) {

  useEffect(() => {}, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  if (!props.open) {
    return <></>;
  }

  const getPosition = (position) => {
    let positionClassName = "";
    switch (position) {
      case "top":
        positionClassName = "tw-items-start";
        break;
      case "bottom":
        positionClassName = "tw-items-end";
        break;
      case "center":
      default:
        positionClassName = "tw-items-center";
    }
    return positionClassName;
  };

  return (
    <div
      className={`tw-absolute tw-flex tw-z-10 tw-justify-center
                        tw-p-2 tw-top-0 tw-left-0 ${getPosition(
                          props?.position
                        )} 
                        tw-w-screen tw-h-screen
                        tw-backdrop-filter
                        tw-backdrop-blur-sm`}
    >
      <div
        className={
          "tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-bg-black tw-opacity-60"
        }
      ></div>

      <div
        style={!isMobile ? props?.style : {}}
        className={`tw-w-full ${ props?.width ?? "tw-w-96"} ${
          props?.hasBorder && "tw-border"
        } tw-z-20 tw-overflow-hidden tw-bg-white tw-rounded-xl`}
      >
        {props?.titleWithSubtitle && (
          <div
            className={
              "tw-bg-jav-card-100 tw-relative tw-py-8 tw-flex tw-items-center tw-justify-center"
            }
          >
            <div className={"tw-flex tw-flex-col tw-text-center"}>
              <span className={"tw-font-satoshiBold tw-text-lg tw-text-black"}>
                Enter Pin
              </span>
              <span className={"tw-font-satoshiMedium"}>
                Enter your 6 digit PIN to complete <br />
                transaction
              </span>
            </div>
            <CloseIcon
              onClick={props.onCloseClicked}
              className={
                "tw-stroke-current tw-shadow-lg tw-text-black tw-stroke-2 tw-absolute tw-top-3 tw-right-5 tw-bg-white tw-rounded-full tw-cursor-pointer"
              }
            />
          </div>
        )}

        {!props?.titleWithSubtitle && (
          <div
            className={`tw-border-b ${
              props.review ? "tw-bg-bg-review" : "tw-bg-jav-primary-300"
            } tw-text-white tw-px-4 tw-py-3 tw-flex
                                tw-items-center tw-justify-between`}
          >
            <h3 className="tw-text-sm tw-font-satoshiBold">{props.title}</h3>

            <CloseIcon
              onClick={props.onCloseClicked}
              className={
                `tw-stroke-current tw-shadow-lg tw-text-black tw-stroke-2 ${props.review ? "tw-bg-white": "tw-bg-blue-50"} tw-rounded-full tw-cursor-pointer`
              }
            />
          </div>
        )}

        <div className="tw-py-3 tw-px-3">{props.children}</div>

        <div
          className={`tw-grid tw-px-5 tw-gap-x-4
                                tw-grid-cols-${
                                  props.actions?.length <= 3
                                    ? props.actions?.length
                                    : 3
                                } tw-pb-2`}
        >
          {props?.actions?.map((action) => {
            return (
              <JavButton
                onClick={action.onClick}
                isLoading={action.loading}
                className={`tw-text-white ${action.className}`}
                textColor={"tw-text-white"}
                title={action.title}
                bgColor={action?.bgColor}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
