import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { currencyFormat } from "../../helpers/utils";
import JavContentTitle from "../../Shared/Components/JavContentTitle";
import Styles from "../../Shared/Components/ReviewPopUp/ReviewPopUp.module.css";
import BankWireMemoPopUp from "../TransferMoney/BankWireMemoPopUp";
import PendingActionTag from "../UserManagement/PendingActionTag";

const LoanTransactionDetail = ({ setReview, showReview, details }) => {
    const text = useSelector((state) => state.language);
    const [visibleBankMemo, setVisibleBankMemo] = useState(false);

    const handleCancel = () => {
        setReview(false);
    };

    return (
        <>
            <BankWireMemoPopUp currencyCode={details?.currency} transactionId={details?.reference} isVisible={visibleBankMemo} setVisible={setVisibleBankMemo} />
            <Modal
                open={showReview}
                style={{ top: 100 }}
                footer={false}
                closable={false}
                onCancel={handleCancel}
                width={795}
                bodyStyle={{ padding: 0 }}
            >
                <div className={Styles.reviewCard}>
                    <div>
                        <div className={Styles.header}>
                            <div>
                                <div className={Styles.secTitle}>
                                    {text["Review Pending Transaction"]}
                                </div>
                                <div className={Styles.secSubTitle}>{`${details?.reference}`}</div>
                            </div>
                            <div onClick={handleCancel} className={Styles.cancel}>
                                {text["cancel"]}
                            </div>
                        </div>
                        <div className={Styles.contentArea}>
                            <JavContentTitle title="Transaction Made by" />
                            <div className={Styles.rowGrid2}>
                                <div>
                                    <div className={Styles.detailsTitle}>
                                        {text["User name"]}
                                    </div>
                                    <div className={Styles.ans}>
                                        {details?.business?.full_name}
                                    </div>
                                </div>
                                <div>
                                    <div className={Styles.detailsTitle}>
                                        {text["Role"]}
                                    </div>
                                    <div className={Styles.ans}>
                                        {details?.business?.is_parent ? "Super Admin" : details?.business?.role?.role?.name}
                                    </div>
                                </div>
                            </div>

                            <JavContentTitle title={text["Beneficiary account"]} />
                            <div className={Styles.rowGrid}>
                                <div>
                                    <div className={Styles.detailsTitle}>
                                        {text["Account Name"]}
                                    </div>
                                    <div className={Styles.ans}>
                                        {details ? details?.beneficiary?.name : ""}
                                    </div>
                                </div>
                                <div>
                                    <div className={Styles.detailsTitle}>
                                        {text["Account Number"]}
                                    </div>
                                    <div className={Styles.ans}>
                                        {details ? details?.beneficiary?.account_number : ""}
                                    </div>
                                </div>
                                <div>
                                    <div className={Styles.detailsTitle}>
                                        {text["Bank name"]}
                                    </div>
                                    <div className={Styles.ans}>
                                        {details ? details?.beneficiary?.bank_name : ""}
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "grid", justifyContent: "space-between", gridTemplateColumns: "repeat(3, 1fr)" }}>
                                <div>
                                    <div className={Styles.detailsTitle}>
                                        {text["Initial Trans. Amount"]}
                                    </div>
                                    <div className={Styles.ans}>
                                        {`${details?.currency} ${currencyFormat(details?.amount)}`}
                                    </div>
                                </div>
                                <div>
                                    <div className={Styles.detailsTitle}>
                                        {"Interest Fee"}
                                    </div>
                                    <div className={Styles.ans}>
                                        {`${details?.currency} ${currencyFormat(Number(details?.interest).toFixed(2))}`}
                                    </div>
                                </div>
                                <div>
                                    <div className={Styles.detailsTitle}>
                                        {"Repayment Amount"}
                                    </div>
                                    <div className={Styles.ans}>
                                        {`${details?.currency} ${currencyFormat(details?.amount)}`}
                                    </div>
                                </div>
                            </div>


                            <div style={{ display: "grid", justifyContent: "space-between", gridTemplateColumns: "repeat(2, 1fr)" }}>
                                <div>
                                    <div className={Styles.detailsTitle}>
                                        {"Loan Type"}
                                    </div>
                                    <div className={Styles.ans}>
                                        {details?.loan_type?.code_name}
                                    </div>
                                </div>
                                <div>
                                    <div className={Styles.detailsTitle}>
                                         {"Current Status"}
                                    </div>
                                     <div className={Styles.ansRed}>
                                        {details?.status}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: "1em 2em" }}>
                        {details?.status === "COMPLETED" && (
                            <PendingActionTag 
                                handler={() => { setVisibleBankMemo(true); }} 
                                title={"Repay Loan"} 
                                closeModal={setReview} 
                                action={"handler"} 
                                styles={{ fontSize: "17px", textAlign: "center", width: "300px" }} 
                                details={details}
                            />
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default LoanTransactionDetail;
