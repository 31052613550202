import JavButton from "../../Shared/Components/Forms/JavButton";
import {Cash, Loan, Money, TransferDasIcon} from "../../Shared/Components/JavIcons";
import PlayIcon from "../../Shared/Components/Icons/PlayIcon";
import CheckIcon from "../../Shared/Components/Icons/CheckIcon";
import ApplicationSpotRateCalculator
    from "../../Shared/Components/Layouts/Components/SpoteRateCalculator/ApplicationSpotRateCalculator";
import JavProductCard from "../../Shared/Components/Products/JavProductCard";
import {useSelector} from "react-redux";
import JavolinWalletCard from "../../Shared/Components/WalletCard/JavolinWalletCard";
import {PlusCircleOutlined} from "@ant-design/icons";
import LoanTransaction from "../Transaction/LoanTransaction";

export default function ApplicationDashboard() {

    const userDetails = useSelector((state) => state?.user)
    const text = useSelector((state) => state?.language)
    const state = useSelector((state) => state?.resources)
    const default_wallet = userDetails?.default_savings_wallet
    const currencies = state?.defaultCurrencies ? state?.defaultCurrencies : {}


    const setupAccountList = [
        {
            title: "Create account",
            completed: true
        },
        {
            title: "Secure your Account",
            subTitle: "Set your account pin",
            completed: useSelector((state)=>state?.resources?.userSecurityQuestions)
        },
        {
            title: "Business KYC",
            subTitle: "Provide your business details",
            completed: userDetails?.business_kyc
        }
    ]

    return (
        <div className={"tw-px-5"}>
            {/* <div className={"tw-leading-none tw-flex tw-flex-col tw-font-satoshiBold"}>
                <span className={"tw-text-2xl tw-text-gray-700"}>Dashboard</span>
                <h5 className={""}>Home</h5>
            </div> */}


            <div className={"tw-grid  tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-x-10 tw-gap-y-5 tw-font-satoshiMedium"}>

                <div className={"tw-h-44 tw-border tw-bg-gradient-to-r tw-from-yellow-200 tw-to-green-300 tw-p-5 tw-rounded-2xl"}>
                    <h5 className={"tw-text-black tw-font-satoshiBold tw-text-lg"}>Welcome to Javolin</h5>

                    <p className={"tw-w-4/5 tw-text-gray-800"}>
                        Welcome to Javolin. Get to know more about how to get started on our forex platform
                    </p>

                    <JavButton
                        className={"tw-hidden md:tw-flex"}
                        textColor={"tw-text-white"}
                        title={"Overview in 60s"}
                        icon={PlayIcon}
                    />

                </div>

                <div className={"tw-h-full tw-row-span-2"}>

                    <ApplicationSpotRateCalculator/>

                </div>

                <div className={"md:tw-h-56 tw-border tw-flex tw-flex-col md:tw-flex-row tw-overflow-hidden  tw-bg-jav-primary-200 tw-rounded-2xl"}>

                    <div className={"tw-w-full py-5 md:py-0 md:tw-w-2/5 tw-px-5 tw-flex tw-flex-col tw-justify-center tw-bg-bg-pattern"}>

                        <div className={"tw-bg-white tw-w-16 sm:tw-text-sm tw-text-center tw-font-satoshiBold tw-py-1 tw-rounded-full"}>
                            {setupAccountList?.filter(item => item?.completed)?.length}/{setupAccountList.length}
                        </div>

                        <span className={"tw-text-sm md:tw-hidden xl:tw-block tw-text-gray-400 mt-2"}>Welcome to Javolin</span>

                        <span className={"tw-text-xl md:tw-text-sm md:tw-mt-1 xl:tw-mt-0 xl:tw-text-xl tw-font-firmaBold tw-leading-tight tw-text-gray-100"}>Let's secure your account</span>

                        <JavButton
                            title={"Continue"}
                            textColor={"tw-text-white"}
                            className={"tw-border-white tw-border mt-3"}
                        />

                    </div>

                    <div className={"tw-w-full md:tw-w-3/5 tw-flex tw-flex-col tw-p-5 tw-justify-center tw-bg-white"}>

                        {
                            setupAccountList.map(item => {
                                return (
                                    <div className={"tw-flex tw-py-3 md:tw-py-0 xl:tw-py-3 tw-items-center tw-justify-between"}>
                                        <div className={"tw-flex tw-items-center"}>

                                            <CheckIcon className={`tw-h-6 tw-text-center tw-rounded-full tw-w-6 tw-p-1.5 ${ item?.completed ? 'tw-bg-green-600' : 'tw-bg-gray-400'}`}/>


                                            <div className={"tw-flex tw-flex-col tw-px-2 tw-leading-tight"}>
                                                <span className={"tw-font-satoshiBold tw-text-sm tw-text-gray-600"}>{item?.title}</span>
                                                <span className={"tw-text-gray-500 tw-text-xs"}>{item?.subTitle}</span>
                                            </div>
                                        </div>

                                        <span className={`tw-font-firmaBold ${item?.completed ? 'tw-text-blue-300' : ''} tw-text-sm tw-text-gray-400`}>
                                          {item?.completed ? 'completed' : '1 min'}
                                        </span>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>


            </div>


            <div className={"tw-my-5 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-5"}>
                <div className={""}>
                    <h5 className={"tw-text-gray-800"}>Javolin Services</h5>

                    <div className={"tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-5"}>

                        <JavProductCard
                            subTitle={"Javolin Trading Hub"}
                            link={'/business/trading-hub'}
                            icon={<Loan width="5em" height="5em" color="#ffffff" />}
                            color="#16a34a"
                        />


                        <JavProductCard
                            subTitle={text.PAYMENT_SEND_MONEY}
                            link={'/business/payments'}
                            icon={<Money width="5em" height="5em" color="#ffffff" />}
                            color="#000B6B"
                        />

                        <JavProductCard
                            link="/business/transfer"
                            subTitle={text.TRANSFER_MONEY}
                            icon={<TransferDasIcon width="5em" height="5em" color="#ffffff" />}
                            color="#FFA621"
                        />

                        <JavProductCard
                            link="/business/fund-wallet"
                            subTitle={text.FUND_MY_WALLET}
                            icon={<Cash width="5em" height="5em" color="#ffffff" />}
                            color="#EE735D"
                        />


                    </div>
                </div>

                <div className={""}>
                    <h5 className={"tw-text-gray-800"}>Your Wallet</h5>

                    <div className={"tw-bg-white tw-rounded-lg tw-flex tw-p-1 md:tw-p-4"}>
                        <div className={"tw-flex-1"}>
                            <JavolinWalletCard
                                balance={default_wallet?.current_balance}
                                currency={currencies[default_wallet?.currency_id]?.ISO}
                                accountNumber={default_wallet?.account_number}
                            />
                        </div>

                        <div className={"tw-hidden md:tw-flex tw-flex-col tw-items-center tw-justify-center" +
                            " tw-bg-blue-50 tw-ml-5 tw-border-2 tw-border-blue-800 tw-border-dashed large-dashed-border tw-rounded-lg tw-w-20"}>

                            <PlusCircleOutlined style={{color:"#0032A0"}}/>

                            <span className={"tw-font-firmaBold tw-text-jav-primary-300"}>Add </span>

                        </div>
                    </div>


                </div>
            </div>

            <div className={"tw-flex tw-justify-between tw-items-center"}>
                <h5 className={"tw-text-gray-800"}>Latest Transaction</h5>
                <span className={"tw-text-blue-800 tw-font-proximaBold tw-cursor-pointer mx-1"}>View All </span>
            </div>

            <LoanTransaction title={""} pageNumbers={[5]}/>



        </div>
    )
}