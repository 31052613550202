import React, { useRef } from "react";
import { Tag } from "antd";
import DefaultTable from "../../Shared/Components/DefaultTable/defaultTable";
import JavButton from "../../Shared/Components/Forms/JavButton";
import { Edit, TrashIcon } from "../../Shared/Components/JavIcons";

function RolesList() {
  const tableRef = useRef();

  return (
    <DefaultTable
    ref={tableRef}
      columns={["Role Name", "Role Permissions", "Action"]}
      fields={[
        {
          render: (content) => {
            return (
              <td>
                <div className="tw-whitespace-nowrap tw-pl-3 tw-font-satoshiBold">{content.name}</div>
              </td>
            );
          },
        },
        {
          render: (content) => {
            return (
              <td  className="tw-overflow-hidden" >
                <div className="tw-flex" >
                <div className="tw-flex tw-flex-wrap tw-break-words">
                  {content?.permissions?.map((per) => {
                    if (per.name !== "TRANSACTION_LIMIT") {
                     return( <Tag
                        className=""
                        style={{
                          color: "#000C26",
                          marginTop: "5px",
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #3DA31F",
                        }}
                        color="#ECF7E8"
                        key={per.id}
                      >
                        {per.name}
                      </Tag>)
                    }
                  })}
                </div>
                </div>
               </td>
            );
          },
        },
        {
          render: (content) => {
            return (
              <td>
                <div className={"tw-flex tw-justify-center"}>
                  <JavButton
                    bgColor={"tw-bg-blue-600"}
                    textColor="tw-text-white"
                    padding="2"
                    className={"tw-text-white  tw-px-4 tw-m-2 tw-h-9 "}
                  >
                    {<Edit width={"1.5em"} color={"white"} height={"1.5em"} />}
                    Edit
                  </JavButton>

                  <JavButton
                    bgColor={"tw-bg-red-700"}
                    textColor="tw-text-white"
                    h
                    className={"tw-text-white tw-px-4 tw-m-2 tw-h-9"}
                  >
                    {
                      <TrashIcon
                        width={"1.5em"}
                        color={"white"}
                        height={"1.5em"}
                        className={"tw-pr-3"}
                      />
                    }
                    Delete
                  </JavButton>
                </div>
              </td>
            );
          },
        },
      ]}
      link={"/business/roles"}
      title={"Role List"}
      tag={"api.table.roles"}
    />
  );
}

export default RolesList;
