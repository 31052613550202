import JavInput from "../../../../Shared/Components/Forms/JavInput";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import {useContext, useEffect, useState} from "react";
import {getBeneficiaries} from "../../../TransferMoney/duck/action";
import {useDispatch, useSelector} from "react-redux";
import JavButton from "../../../../Shared/Components/Forms/JavButton";
import {LoanTabsContext} from "./LoanTabsContext";

export default function LoanBeneficiary(props) {

    const dispatch = useDispatch()
    const beneficiaries = useSelector((state) => state?.transfer?.beneficiaries);
    const [selectedBeneficiary,setSelectedBeneficiary] = useState(null)
    const {tabs,setTabs} = useContext(LoanTabsContext);

    useEffect(() => {
        dispatch(getBeneficiaries())
    }, []);

    useEffect(() => {
        console.log("beneficiaries",beneficiaries)
    },[beneficiaries])


    useEffect(() => {
        console.log("context Bene",tabs);
    },[tabs])


    return (
        <div className={"tw-flex tw-flex-col"}>

            <div>
                <span className={"tw-font-satoshiBold tw-text-2xl"}><span className={"tw-font-satoshi tw-text-gray-500"}>Step 3:</span> Add Beneficiary Info</span>
            </div>


            <div className={"tw-grid tw-grid-cols-1 tw-gap-4 tw-my-8"}>

                <JavFormSelect
                    title={"Beneficiary Bank"}
                    position={"bottom"}
                    onChange={(item) => {
                        setSelectedBeneficiary(item);
                        console.log("item", item)
                    }}
                    items={
                        Object.values(beneficiaries).map(item => {
                            return {
                                ...item,
                                title: `${item?.bank_name} [ ${item?.account_number} ]`,
                                render: () => {
                                    return (
                                        <div data-class={"allowed"}
                                             className={"tw-w-full hover:tw-bg-gray-100 tw-px-6 tw-py-3.5 tw-flex-col tw-font-satoshiMedium"}>
                                            <div data-class={"allowed"}
                                                 className={"tw-flex tw-justify-between tw-items-center"}>
                                                <span data-class={"allowed"}
                                                      className={"tw-text-black tw-font-satoshiBold tw-text-sm tw-uppercase"}>{item?.name}</span>
                                                <span data-class={"allowed"}
                                                      className={"tw-text-gray-600"}>{item?.account_number}</span>
                                            </div>

                                            <div data-class={"allowed"}
                                                 className={"tw-flex tw-justify-between tw-items-center"}>
                                                <span data-class={"allowed"}
                                                      className={"tw-text-black"}>currency: {item?.currency}</span>
                                                <span data-class={"allowed"}
                                                      className={"tw-text-gray-600"}>{item?.bank_name}</span>
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        })}
                />

                <JavInput
                    disabled={true}
                    value={selectedBeneficiary?.account_number}
                    title={"Beneficiary Account Number"}
                />

                <div className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4"}>

                    <JavInput
                        disabled={true}
                        value={selectedBeneficiary?.bank_name}
                        title={"Bank Name"}
                    />

                    <JavInput
                        disabled={true}
                        value={selectedBeneficiary?.name}
                        title={"Account Name"}
                    />

                </div>


                <div className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-8"}>
                    <JavButton
                        title={"Back"}
                        textColor={"tw-gray-500"}
                        bgColor={"tw-bg-transparent"}
                        className={"tw-border"}
                        onClick={() => {
                            const updateTabs = [...tabs.tabs];
                            updateTabs[props?.index].completed = false;
                            const updatedTabsHolder =
                                { ...tabs,
                                    tabs: updateTabs,
                                    selectedIndex: (props?.index ?? 0) - 1 ,
                                    beneficiary: null
                                };
                            setTabs(updatedTabsHolder);
                        }}
                    />
                    <JavButton
                        title={"Continue"}
                        textColor={"tw-text-white"}
                        onClick={() => {
                            const updateTabs = [...tabs.tabs];
                            updateTabs[props?.index].completed = true;
                            const updatedTabsHolder =
                                { ...tabs,
                                    tabs: updateTabs,
                                    selectedIndex: (props?.index ?? 0) + 1,
                                    beneficiary: selectedBeneficiary
                                };
                            setTabs(updatedTabsHolder);
                        }}
                    />
                </div>


            </div>

        </div>
    )
}