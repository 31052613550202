
import { Modal, Button } from "antd";
import React from "react"
import Styles from "./../../Shared/Components/ReviewPopUp/ReviewPopUp.module.css"
import {currencyFormat} from "../../helpers/utils";
import JavContentTitle from "../../Shared/Components/JavContentTitle";



const LoanReviewPopUp = ({ openWireInfo, setReview, showReview, details, setPasscode }) => {
    console.log('de: ', details)

    const handleCancel = () => {
        setReview(false);
    };
    const onContinue = () => {
        handleCancel()
        setPasscode(true)
    }

    const handleViewWireInfo = ()=>{
        console.log('here')
        openWireInfo(true)
    }

    console.log(details?.fee?.fee)
    return (
        <Modal
            visible={showReview}
            style={{ top: 100 }}
            footer={false}
            closable={false}
            onCancel={handleCancel}
            width={795}


            bodyStyle={
                {
                    padding: 0
                }
            }
        >


            <div className={Styles.reviewCard}>
                <div>
                    <div className={Styles.header}>
                        <div className={Styles.secTitle}>Review Transaction</div>
                        {/* <div className={Styles.secSubTitle}>Review Transaction</div> */}
                        <div onClick={handleCancel} className={Styles.cancel}>
                            cancel
                        </div>
                        {/* <div className={Styles.close} >x</div> */}
                    </div>
                    <div className={Styles.contentArea}>
                        <JavContentTitle title={details?.to?.msg} />
                        <div className={Styles.rowGrid} >
                            <div>
                                <div className={Styles.detailsTitle}>
                                    Account name
                                </div>
                                <div className={Styles.ans}>
                                    {details?.beneficiary?.name}
                                </div>
                            </div>
                            <div>
                                <div className={Styles.detailsTitle}>
                                    Account number
                                </div>
                                <div className={Styles.ans}>
                                    {details?.beneficiary?.account_number}
                                </div>
                            </div>
                            <div>
                                <div className={Styles.detailsTitle}>
                                    Bank name
                                </div>
                                <div className={Styles.ans}>
                                    {details?.beneficiary?.bank_name}
                                </div>
                            </div>
                        </div>
                        <JavContentTitle title={details?.from?.msg} />
                        <div >
                            <div className={Styles.detailsTitle}>{details?.from?.msg}</div>

                            <div className={Styles.receiverContent}>
                                <div className={Styles.contentRow}>
                                    <div className={Styles.recTitle}>
                                        {details?.from?.title} <span style={{marginLeft: "3em", color:""}}>{details?.from?.acc_num ? details?.from?.acc_num : ""}</span>
                                    </div>
                                    <div style={{marginTop: "0.2em"}} className={Styles.recSubTitle}>
                                        {details?.from?.title === "EXTERNAL WIRE" ? (
                                                <div onClick={handleViewWireInfo} className={Styles.wireSub}>
                                                    view wiring instruction
                                                </div>

                                            ):
                                            details?.from?.subTitle
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>
                                <div className={Styles.detailsTitle}>
                                    Loan Amount
                                </div>
                                <div className={Styles.ans}>
                                    {details?.currency}
                                    {
                                        details?.loan_type === "1" ? currencyFormat(details?.truck?.amount) : currencyFormat(details?.amount)
                                    }
                                </div>
                            </div>
                            <div>
                                <div className={Styles.detailsTitle}>
                                    Interest
                                </div>
                                <div className={Styles.ans}>
                                    {details?.currency}
                                    {details?.loan_type === "1" ? currencyFormat(Number(details?.truck?.interest).toFixed(2)) : " ---" }
                                </div>
                            </div>
                            <div>
                                <div className={Styles.detailsTitle}>
                                    Repayment Amount Amount
                                </div>
                                <div className={Styles.ans}>
                                    {details?.currency}
                                    {
                                        details?.loan_type === "1" &&
                                        currencyFormat(Number(details?.truck?.interest + details?.truck?.amount).toFixed(2))
                                    }
                                    {
                                        details?.loan_type === "2" && " ---"
                                    }
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
                <Button
                    block
                    size="large"
                    style={{ maxWidth: "400px", margin: "auto", textAlign: "center", paddingBottom: "2em" }}
                    type="primary"
                    shape="round"
                    onClick={onContinue}
                >
                    Proceed
                </Button>
            </div>

        </Modal>
    )
}

export default LoanReviewPopUp