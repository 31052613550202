import "../Dashboard/Dashboard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import LoanTransaction from "../Transaction/LoanTransaction";
import { Avatar } from "antd";
import { ArrowRightIcon, CheckIcon } from "../../Shared/Components/JavIcons";

import NotificationIcon from "../../Shared/Components/Icons/NotificationIcon";
// import { hideToast, showToast } from "../../Shared/Components/Toast/duck/action";
import { useAlert } from "../../Shared/Context/AlertContext";


const NotificationPage = () => {
//   const text = useSelector((state) => state?.language);

const dispatch = useDispatch();
const { showAlert } = useAlert();


const handleShowToast = () => {
  // dispatch(showToast('Success message', 'success'));
  showAlert("Hello","SUCCESS", (<CheckIcon />))
};

const handleHide = ()=>{
    // dispatch(hideToast())
}

  const messages = [
    {
      title: "Javolin is a financial services",
      message:
        "Javolin is a financial services technology company providing services and products through our digital platform, and mobile and web-based applications for Businesses, ",
    },
    {
      title: "We offer businesses a digital platform",
      message:
        "We offer businesses a digital platform to manage their revenue collections, pay bills and get visa cards and apply for import/export loans.        ",
    },
    {
      title: "In React, you can access",
      message:
        "Place your images in the public folder of your React project. For example, if you have an image",
    },
    {
      title: "In React, you can access",
      message:
        "Our customizable solutions empower broader participation across borders for our clients.        ",
    },
    
    {
      title: "In React, you can access",
      message:
        "Well, Bongo was in my DM yesterday, I will see him in person soon, maybe after my birthday.",
    },
  ];

  return (

    <>
      <div className="tw-mt-5">
        {messages.map((notification, index) => (
          <div
            key={index}
            className="tw-flex tw-border-b tw-via-jav-gray-100 tw-p-1 tw-cursor-pointer hover:tw-bg-gray-100 tw-justify-between"
            onClick={()=> {handleShowToast()}}
          >
            <div className="tw-flex tw-w-full">
              <div className="tw-flex tw-h-10 tw-w-10 tw-border tw-border-jav-primary-200 tw-rounded-full  tw-self-center tw-items-center tw-justify-center ">
                <NotificationIcon  />
              </div>
              <div className="tw-pl-2 tw-content-center tw-pt-3">
                <text className="tw-font-satoshiBold tw-text-l" onClick={()=>{}}>
                  {notification["title"]}
                </text>
                <p className="tw-overflow-ellipsis">{notification["message"]}</p>
              </div>
            </div>
            <div className="tw-content-center">
              <ArrowRightIcon color={"black"} height={24} width={24} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NotificationPage;
