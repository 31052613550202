import CloseIcon from "../Icons/CloseIcon";
import {useRef} from "react";
import {useAlert} from "../../Context/AlertContext";
import PDFIcon from "../Icons/PDFIcon";
import {downloadFile, isValidURL} from "../../../helpers/utils";

export default function JavFormFileInput (props) {

    const fileRef = useRef();
    // const [data,setData] = useState(null);
    // const [metaData,setMetaData] = useState(null);
    const { showAlert } = useAlert();


    const onChooseFileClicked = () => {
        fileRef.current.click();
    }

    function getBase64(file,onSuccess,onError) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>  onSuccess(reader.result);
        reader.onerror = (error) => onError(error);
    }

    const onFileChange = async (event) => {
        console.log("event",event);
        window.mEvent = event;
        getBase64(event.target.files[0],(mData) => {
            props.onChange({
                file: mData,
                meta: event.target.files[0]
            });
        },(error) => {
            alert("Error: "+error)
        })
    }




    return (
        <div className={`tw-border tw-font-satoshiMedium tw-py-1.5 tw-px-2 tw-rounded tw-flex tw-justify-between tw-items-center ${props.className}`}>

            <input onChange={onFileChange} ref={fileRef} type={"file"} className={"tw-hidden"}/>

            <div>
                {
                    props.data &&
                    (
                        <div>
                            {props?.data.meta?.name?.replace("vnd.openxmlformats-officedocument.spreadsheetml.sheet","xlsx")}
                        </div>
                    )
                }

                {
                    !props.data &&
                    (
                        <div onClick={onChooseFileClicked} className={"tw-border tw-px-1 tw-text-xs tw-bg-gray-500 tw-text-gray-100 tw-cursor-pointer tw-font-satoshiBold tw-rounded tw-py-1.5"}>
                            Choose File
                        </div>
                    )
                }
            </div>

            {
                props.data &&
                <div className={"tw-flex tw-items-center"}>

                    {
                        !props?.disallowEdit &&
                        <div onClick={() => {
                            props.onChange({
                                file: null,
                                meta: null
                            });
                        }} className={"tw-cursor-pointer"} bgColor={"tw-bg-gray-200"}>
                            <CloseIcon/>
                        </div>
                    }


                    {

                        true &&
                        <div onClick={() => {
                            downloadFile(props?.data).then(res => {
                                showAlert("file downloaded successfully","green")
                            })
                        }} className={"tw-cursor-pointer tw-mx-1"} bgColor={"tw-bg-gray-200"}>
                            <PDFIcon/>
                        </div>

                    }

                </div>

            }



        </div>
    )
}