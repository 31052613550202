import React, { useEffect, useRef, useState } from "react";
import DefaultTable from "../../Shared/Components/DefaultTable/defaultTable";
import { currencyFormat, makeJavolinRequest } from "../../helpers/utils";
import JavButton from "../../Shared/Components/Forms/JavButton";
import { CheckIcon, LoadingIcon } from "../../Shared/Components/JavIcons";
import ThumbsDownIcon from "../../Shared/Components/Icons/ThumsDown";
import {
  approveTransaction,
  // declineTransaction,
  getPendingTransactions,
} from "./duck/action";
import { useDispatch } from "react-redux";
import RequestSummaryReview from "./RequestSummary";
import ConfirmDialog from "../../Shared/Components/Dialog/ConfirmDialog";
import XIcon from "../../Shared/Components/Icons/XIcon";
import { useAlert } from "../../Shared/Context/AlertContext";
import { useHistory } from "react-router-dom";

const PendingRequests = () => {
  const [isLoadingApprove, setLoadingApproval] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const tableRef = useRef();
  const [requestDetails, setRequestDetails] = useState({
    hasOpen: false,
    data: null,
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const { showAlert } = useAlert();

  const [isOpen, setIsOpen] = useState({ hasOpen: false, id: null });

  const handleConfirm = (id) => {
    setIsOpen({ ...isOpen, hasOpen: true, id: id });
  };

  const handleCancel = () => {
    setIsOpen({ ...isOpen, hasOpen: false, id: null });
    tableRef?.current?.reloadTable();
  };

  const handleModal = (id) => {
    setRequestDetails({ ...requestDetails, hasOpen: true, data: id });
  };

  const closeModal = () => {
    setRequestDetails({ ...requestDetails, hasOpen: false, data: null });
    tableRef?.current?.reloadTable();
  };

  const declineRequest = (id) => {
    setRequestLoading(true);
    makeJavolinRequest(
      {
        path: `/business/pending-transactions/${id}/decline`,
        method: "POST",
        data: { id: id },
      },
      null,
      null,
      (data) => {
        handleCancel();
        closeModal();
        setRequestLoading(false);
        showAlert("Request Declined Successfully", "SUCCESS", <CheckIcon />);

        console.log("data", data);
      },
      (error) => {
        setRequestLoading(false);
        handleCancel();
        closeModal();
        showAlert("Error: " + error, "FAILED", <XIcon />);
        console.log("error", error);
      }
    );
  };

  const approveRequest = (id) => {
    setLoadingApproval(true);
    makeJavolinRequest(
      {
        path: `/business/pending-transactions/${id}/approve`,
        method: "POST",
        data: { id: id },
      },
      null,
      null,
      (data) => {
        handleCancel();
        closeModal();
        setLoadingApproval(false);
        tableRef?.current?.reloadTable();
        showAlert("Request Approved Successfully", "SUCCESS", <CheckIcon />);
        console.log("data", data);
      },
      (error) => {
        setLoadingApproval(false);
        handleCancel();
        closeModal();
        // dispatch(showToast("Error: " + error, "failed"));
        showAlert("Error: " + error, "FAILED", <XIcon />);
        console.log("error", error);
      }
    );
  };
  return (
    <>
      <DefaultTable
        ref={tableRef}
        columns={[
          "Module",
          "Transaction ID",
          "Amount",
          "Date",
          "User",
          "Action",
        ]}
        fields={[
          "module",
          {
            render: (content) => {
              return (
                <td
                  onClick={() => {
                    // history.push(
                    //   `/business/transaction/${content.id}`,
                    //   content
                    // );
                  }}
                  // className={
                  //   "tw-text-center tw-underline tw-text-blue-600 tw-cursor-pointer"
                  // }
                >
                  {content?.transaction_id}
                </td>
              );
            },
          },

          {
            render: (content) => {
              return (
                <td className={"tw-text-center"}>
                  GHC {currencyFormat(content?.loan?.amount ?? 0, 2)}
                </td>
              );
            },
          },
          "created_at",
          "business.full_name",
          {
            render: (content) => {
              return (
                <div className={"tw-flex tw-justify-center"}>
                  <JavButton
                    bgColor={"tw-bg-blue-600"}
                    textColor="tw-text-white"
                    padding="2"
                    className={"tw-text-white  tw-px-4 tw-m-2 tw-h-9"}
                    onClick={() => {
                      handleModal(content);
                    }}
                  >
                    {
                      <CheckIcon
                        width={"1.5em"}
                        color={"white"}
                        height={"1.5em"}
                        className="tw-pr-6"
                      />
                    }
                    Approve
                  </JavButton>

                  <JavButton
                    bgColor={"tw-bg-red-600"}
                    textColor="tw-text-white"
                    className={"tw-text-white tw-px-4 tw-m-2 tw-h-9 "}
                    onClick={() => {
                      handleConfirm(content.id);
                    }}
                  >
                    {
                      <ThumbsDownIcon
                        width={"22"}
                        color={"white"}
                        height={"22"}
                        className="tw-pr-2"
                      />
                    }
                    Decline
                  </JavButton>
                </div>
              );
            },
          },
        ]}
        link={"/business/pending-transactions"}
        title={`Pending Request`}
        tag={"api.table.pending"}
      />

      <ConfirmDialog
        isOpen={isOpen.hasOpen}
        handleCancel={handleCancel}
        message={
          <span className="">
            Are you sure you want to{" "}
            <span className="tw-font-satoshiBold tw-pl-1 tw-pr-1 tw-text-black">
              Decline
            </span>{" "}
            this request?
          </span>
        }
        handleConfirm={() => {
          declineRequest(isOpen.id);
        }}
        requestLoading={requestLoading}
      />

      <RequestSummaryReview
        details={requestDetails.data}
        open={requestDetails.hasOpen}
        onDeclinedClick={() => {
          declineRequest(requestDetails?.data?.id);
        }}
        isLoading={requestLoading}
        isLoadingApprove={isLoadingApprove}
        onApproveClicked={() => {
          approveRequest(requestDetails?.data?.id);
        }}
        onCloseClicked={() => {
          closeModal();
        }}
      />
    </>
  );
};
export default PendingRequests;
