import JavInput from "../Forms/JavInput";
import JavButton from "../Forms/JavButton";
import {useState} from "react";
import FilterIcon from "../Icons/FilterIcon";

export default function JavFilter(props) {

    const [show,setShow] = useState(false);

    const onFilterButtonClicked = () => {
        setShow(!show);
    }

    return (
        <div className={"tw-relative"}>
            <div onClick={onFilterButtonClicked} className={"tw-p-1.5 tw-cursor-pointer tw-rounded-full tw-bg-jav-primary-200 tw-mx-5"}>
                <FilterIcon className={"tw-stroke-current tw-text-white"} />
            </div>

            {
                show &&
                <div
                    className={"tw-absolute tw-grid tw-gap-4 tw-grid-cols-1 tw-right-5 tw-mt-0.5 tw-bg-white tw-rounded tw-border tw-w-92 tw-p-5"}>
                    <span>Advance Search</span>

                    <JavInput placeholder={"Loan Reference Number"}/>

                    <JavInput placeholder={"Supplier"}/>

                    <div className={"tw-flex tw-justify-end"}>
                        <JavButton
                            title={"Search"}
                            onClick={props?.onSearchClicked}
                            textColor={"tw-text-white"}
                        />
                    </div>

                </div>

            }


        </div>
    )
}