import {authHeader, getUserType, REACT_APP_BASE_API_URL} from "../../../helpers/contants";
import axios from "axios";
import {arrayToObjectByID} from "../../../helpers/utils";
import {showErrorNotification, showSuccessNotification} from "../../../Shared/actions/alert.actions";
import {
    ERROR_REQUEST_LOAN,
    FETCH_LOANS,
    LOANS_ERROR,
    LOANS_SUCCESS,
    REQUEST_REQUEST_LOAN,
    SUCCESS_REQUEST_LOAN
} from "./types";

export const getLoans = () => {
    const userType = getUserType()

    return async (dispatch) => {
        dispatch({
            type: FETCH_LOANS,
        });
        try {
            const { data } = await axios.get(
                `${REACT_APP_BASE_API_URL}/${userType}/loans`,
                authHeader
            );
            dispatch({
                type: LOANS_SUCCESS,
                payload : arrayToObjectByID(data)
            });
        } catch (error) {
            dispatch({
                type: LOANS_ERROR,
            });
            if (!error.response) {
                dispatch(
                    showErrorNotification("Action failed", "Something went wrong. Try again later.")
                );
            } else {
                dispatch(
                    showErrorNotification(error?.response?.data?.message)
                );
            }
        }
    };
}

export const requestLoan = (details, setPassCodeVisible, form, history) => {
    const userType = getUserType()
    return async (dispatch) => {
        dispatch({ type: REQUEST_REQUEST_LOAN });
        try {
            const {data} = await axios.post(
                `${REACT_APP_BASE_API_URL}/${userType}/loans`,
                details,
                authHeader
            );
            dispatch({
                type: SUCCESS_REQUEST_LOAN,
            });

            const resp = data?.message ? data?.message : 'Transaction Request sent!'
            dispatch(showSuccessNotification(resp))

            history.push('/business/transactions')

        } catch (error) {
            dispatch({
                type: ERROR_REQUEST_LOAN,
            });
            if (!error.response) {
                dispatch(
                    showErrorNotification("Action failed", "Something went wrong. Try again later.")
                );
            } else {
                dispatch(
                    showErrorNotification(error?.response?.data?.message)
                );
            }
        }
    };
}

