import {useContext} from "react";
import {LoanContractTabContext} from "../../Tabs/LoanContractTabContext";
import {LineIcon} from "../../../../../../Shared/Components/JavIcons";
import JavLabel from "../../../../../../Shared/Components/Forms/JavLabel";
import JavButton from "../../../../../../Shared/Components/Forms/JavButton";
import {currencyFormat} from "../../../../../../helpers/utils";

export default function LoanContractDisbursementSummaryReview(props) {

    const {tabs,setTabs} = useContext(LoanContractTabContext);


    return (
        <div className={"tw-flex tw-flex-col"}>

            <div>
                <span className={"tw-font-satoshiBold tw-text-xl tw-text-gray-800"}><span className={"tw-font-satoshi tw-text-gray-600"}>Step 4:</span> Review & Confirm</span>
            </div>


            <div className={"tw-grid tw-grid-cols-2 tw-gap-2 tw-my-3"}>

                <div className={"tw-grid tw-grid-cols-1 tw-col-span-2 tw-gap-4 tw-py-4 "}>
                    <div className={"tw-flex tw-items-center"}>

                        <span className={"tw-font-satoshiMedium tw-text-lg tw-text-blue-700"}>Bags & Truck Info</span>
                        <LineIcon className={"tw-flex-1"}/>
                    </div>

                </div>

                <JavLabel title={"Requested weight(Tons)"} value={tabs?.consignmentInfo?.selectedWeight}/>
                <JavLabel title={"Remaining weight(Tons)"} value={tabs?.consignmentInfo?.remainingWeight}/>


                <div className={"tw-col-span-2"}>
                    <JavLabel title={"Amount"} value={`${tabs?.contract?.currency} ${currencyFormat(tabs?.consignmentInfo?.amount,2)}`}/>
                </div>


                <div className={"tw-grid tw-grid-cols-1 tw-col-span-2 tw-gap-4 tw-py-4 "}>
                    <div className={"tw-flex tw-items-center"}>

                        <span className={"tw-font-satoshiMedium tw-text-lg tw-text-blue-700"}>Beneficiary</span>
                        <LineIcon className={"tw-flex-1"}/>
                    </div>

                </div>

                <JavLabel title={"Bank Name"} value={tabs?.beneficiary?.bank_name}/>
                <JavLabel title={"Account Number"} value={tabs?.beneficiary?.account_number}/>


                <div className={"tw-grid tw-grid-cols-2 tw-col-span-2 tw-gap-4 tw-mt-8"}>

                    <JavButton
                        title={"Back"}
                        textColor={"tw-gray-500"}
                        bgColor={"tw-bg-transparent"}
                        className={"tw-border"}
                        onClick={() => {
                            const updateTabs = [...tabs.tabs];
                            updateTabs[props?.index].completed = false;
                            const updatedTabsHolder =
                                { ...tabs,
                                    tabs: updateTabs,
                                    selectedIndex: (props?.index ?? 0) - 1
                                };
                            setTabs(updatedTabsHolder);
                        }}
                    />

                    <JavButton
                        title={"Request Loan"}
                        textColor={"tw-text-white"}
                        onClick={() => {
                            props?.onFinish({
                                ...tabs?.beneficiary,
                                ...tabs?.consignmentInfo,
                                total_weight: tabs?.consignmentInfo?.selectedWeight,
                                loan_type: 2,
                                contract_id: tabs?.contract?.id,
                                beneficiary_id: tabs?.beneficiary?.id,
                                suppliers: tabs?.suppliers
                            })
                        }}
                    />
                </div>

            </div>
        </div>
    )
}