import ApplicationNavBarItem from "./ApplicationNavBarItem";
import {
    UserManagementNavIcon,
} from "../JavIcons";
import {setCurrentRoute} from "../duck/action";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import ApplicationMenuNavBarItem from "./Components/ApplicationMenuNavBarItem";
import {useEffect, useState} from "react";
import applicationMenu from "./applicationMenu";

export default function ApplicationNavigationBar(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const current_route = useSelector((state) => state?.resources?.current_route);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const userDetails = useSelector((state) => state?.user);
    const [menuType, setMenuType] = useState("forex");


    useEffect(() => {
        setMenuType(userDetails?.user_type);
    }, [userDetails])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);


        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const handleMenuClick = (val) => {
        console.log(val);
        dispatch(setCurrentRoute(val));
        history.push(val);
        // props.setIsSidebarVisible(false);
    };

    return (
        <div className="tw-flex tw-flex-col tw-font-satoshi tw-text-white tw-w-full md:tw-w-72">
      <span className="tw-font-satoshiMedium tw-pb-4 tw-px-6 tw-text-gray-300">
        General
      </span>


            {
                applicationMenu()?.[menuType]?.map((item, index) => {
                    return (
                        item?.items ? (
                                <ApplicationMenuNavBarItem
                                    icon={UserManagementNavIcon}
                                    title={"User Management"}
                                    items={item?.items?.map((subItem, subIndex) => {
                                        return {
                                            id: subIndex + 1,
                                            menu: <ApplicationNavBarItem
                                                key={index}
                                                icon={subItem?.icon}
                                                title={subItem.title}
                                                isActive={current_route === subItem.route}
                                                onClick={(e) => handleMenuClick(subItem.route)}
                                            />
                                        }
                                    })}
                                />
                            ) :
                            <ApplicationNavBarItem
                                key={index}
                                icon={item?.icon}
                                title={item.title}
                                isActive={current_route === item.route}
                                onClick={(e) => handleMenuClick(item.route)}
                            />
                    );
                })
            }


        </div>
    );
}
